import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import dayjs from "dayjs";


const ParcelSummaryChart = ({ isDashboard = false, chartData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isMonday = value => dayjs(value).day() === 1;
    return (
        <ResponsiveLine
            theme={{
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
                tooltip: {
                    container: {
                        background: colors.primary[400],
                        color: colors.grey[100],
                    },
                },
            }}
            curve="catmullRom"
            data={chartData}
            colors={colors.blueAccent[600]}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            // axisBottom={{
            //     format: function (value) {
            //         return isMonday(value) ? dayjs(value).format('DD') : "";
            //     },
            //     tickSize: function (value) {
            //         return isMonday(value) ? 5 : 0;
            //     },
            // }}

            axisBottom={{
                tickRotation: 50,
                orient: "bottom",
                // tickSize: 5,
                // tickPadding: 5,
                // tickRotation: 0,
                legend: isDashboard ? undefined : "",
                legendOffset: 36,
                legendPosition: "middle",
            }}
            axisLeft={{
                orient: "left",
                tickSize: 5,
                tickValues: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: isDashboard ? undefined : "",
                legendOffset: -40,
                legendPosition: "middle",
            }}
            enableArea={true}
            areaBaselineValue={1}

            enableGridX={false}
            enableGridY={!isDashboard}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

export default ParcelSummaryChart