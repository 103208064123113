import { Box, useTheme, Typography, TextField, Divider, IconButton, useMediaQuery, Button, Alert } from "@mui/material";
import dayjs from "dayjs";
import { React, useState, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import Barcode from "react-barcode";

// import { tokens } from "../theme";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import { border, borderRadius, borderRight } from "@mui/system";
// import { Route, Link, Routes, useNavigate } from 'react-router-dom';
// import '../receipt.css';
// import '../print.css';

// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import { CheckCircleOutline, HighlightOff, ClearOutlined } from "@mui/icons-material"
import { formatCurrency, formatDate, formatDateTime } from "../helper/Helper";


const DeliveryReceipt = ({ transaction, branchList, categories, units }) => {
    const isNonMobile = useMediaQuery("(min-width:820px)");
    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);
    const [createDateTime, setCreateDateTime] = useState('');
    const [createDate, setCreateDate] = useState('');
    const [category, setCategory] = useState('');
    const [unit, setUnit] = useState('');
    const [senderBranchName, setSenderBranchName] = useState('');
    const [senderBranchContact, setSenderBrancContact] = useState('');
    const [receipientBranchName, setReceipientBranchName] = useState('');
    const [receipientBranchContact, setReceipientBranchContact] = useState('');
    // const [user, setUser] = useState('');

    const categoryName = () => {
        categories.map((e) => {
            if (e.id === transaction.category) {
                setCategory(e.name);
            }
        });
    }

    const unitName = () => {
        units.map((e) => {
            if (e.id === transaction.unit) {
                setUnit(e.name);
            }
        });
    }

    const getBranchInfo = () => {
        branchList.map((e) => {
            if (e.id === transaction.from_branch_id) {
                setSenderBranchName(e.name);
                setSenderBrancContact(e.contact1)
            }

            if (e.id === transaction.to_branch_id) {
                setReceipientBranchName(e.name);
                setReceipientBranchContact(e.contact1);
            }
        });
    }

    useEffect(() => {
        categoryName();
        unitName();
        getBranchInfo();
        const current = new Date();
        setCreateDateTime(formatDateTime(current));
        setCreateDate(formatDate(current));
    }, [createDate, createDateTime]);



    return (
        <Box className="delivery-receipt" sx={{}}>

            <Box
                display="grid"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                    // "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
            >
                <Box sx={{ gridColumn: "span 4", transform: "rotate(-4deg)" }}>
                    <Typography className="t-watermark" variant="h6" textAlign="left" color="orange" mr="10px" > SET EXPRESS</Typography>
                </Box>

                <Box sx={{ gridColumn: "span 1", "& > div": { gridColumn: isNonMobile ? undefined : "span 2" }, }} >
                    <Box display="flex" justifyContent="space-between" alignItems="center"> <img className="car-logo" id="car-logo"
                        alt="logo"
                        height="64px"
                        src={`../../assets/car-logo-black-1.png`}
                        style={{ cursor: "pointer" }}
                    />
                    </Box>
                </Box>

                <Box sx={{
                    gridColumn: "span 3",
                    pb: "0px",
                    ml: "12px",
                    // backgroundColor: "orange"
                    // "& > div": { gridColumn: isNonMobile ? undefined : "span 2" }, 
                }} >
                    <Typography className="delivery-rec-title" variant="h3" textAlign="left"  > សន្តិភាពអុិចប្រេស </Typography>
                    <Typography className="delivery-rec-subtitle" variant="h4" textAlign="left" lineHeight={2.0} >ដឹកអ្នកដំណើរ និងបញ្ញើអីវ៉ាន់</Typography>
                </Box>
                <Divider className="hide-on-print" sx={{ gridColumn: "span 4", pt: "0.2rem" }} />

                <Box className="info-box" display="grid" justifyContent="space-between" sx={{ gridColumn: "span 4", gridTemplateColumns: "repeat(6, minmax(0, 1fr))" }} >
                    <Box

                        sx={{
                            // backgroundColor: "red",
                            gridColumn: "span 4",
                            // gridColumn: isNonMobile ? "span 4" : "span 6",
                            display: "grid",
                            // "& > div": { gridColumn: isNonMobile ? "span 6" : "span 4" },
                        }}>

                        <Box className="info-box-sub" display="flex" sx={{ pt: "0.3rem" }} >
                            <Typography className="transaction-info" variant="h6" textAlign="left" pr="5px"> លេខកូដផ្ញើ:&nbsp; </Typography>
                            <Typography className="transaction-info" variant="h6" textAlign="left" > {transaction.reference_number} </Typography>
                        </Box>

                        <Box display="flex" sx={{ pt: "0.1rem" }}>
                            <Typography className="transaction-info" variant="h6" textAlign="left" pr="5px"> តម្លៃអីវ៉ាន់:&nbsp; </Typography>
                            <Typography className="transaction-info" variant="h6" textAlign="left" >  {formatCurrency(transaction.parcel_price)} {transaction.parcel_price_currency} </Typography>
                        </Box>

                        <Box display="flex" sx={{ pt: "0.1rem" }}>
                            <Typography className="transaction-info" variant="h6" textAlign="left" pr="5px"> ថ្ងៃខែផ្ញើ:&nbsp; </Typography>
                            <Typography className="transaction-info" variant="h6" textAlign="left" >  {createDate}  </Typography>
                        </Box>

                        <Box className="info-box-sub" display="flex" sx={{ pt: "0.1rem" }}>
                            <Typography className="transaction-info" variant="h6" textAlign="left" pr="5px"> {createDateTime}&nbsp; </Typography>
                            <Typography className="transaction-info" variant="h6" textAlign="left" > ({localStorage.getItem("user")})  </Typography>
                        </Box>

                        <Box sx={{
                            pb: "2px",
                            display: "flex",
                            // gridColumn: "span 4",
                            // justifySelf: "start",
                            // backgroundColor: "red",
                            // pt: "0.2rem",
                            ml: "-5px",
                            "& > svg": {
                                height: "fit-content",
                                // height: "20px",
                                width: "180px"
                            }
                        }}>
                            <Barcode className="barcode" height={30} format="CODE128" displayValue={false} value={transaction.reference_number} />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "grid",
                        // backgroundColor: "blue",
                        borderColor: "red",
                        borderLeft: "1px blue",
                        gridColumn: "span 2",
                        alignContent: "end",
                        justifySelf: "end",
                        // "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
                    }}>
                        <QRCode sx={{}} size="100" value="https://www.facebook.com/profile.php?id=100090295959988" mr="4px" />

                        <Typography className="t-description" sx={{ mt: "-2px", fontSize: "10px" }}>
                            ស្កែនទីនេះដើម្បីស្វែងរកអីវ៉ាន់
                        </Typography>
                    </Box>
                </Box>

                <Box className="tableBox" sx={{ gridColumn: "span 4", pt: "0.4rem", }}>
                    <TableContainer component={Paper} sx={{ borderRadius: "0px", boxShadow: "none" }}>
                        <Table size="small"
                            sx={{
                                maxWidth: "100%",
                                "& .MuiTableCell-root": {
                                    padding: "6px",
                                    borderRight: "1px",
                                    border: "1px solid black",
                                    // overflow: "hidden",
                                    borderRadius: "0px",
                                    boxShadow: "0px",
                                }
                            }}  >
                            <TableHead>
                                <TableRow className="header-row-cell" sx={{}}  >
                                    <TableCell className="d-cell-left" colSpan="1" sx={{ fontWeight: "bold", width: "20%" }}>ប្រភេទអីវ៉ាន់</TableCell>
                                    <TableCell className="d-cell-right" colSpan="3" sx={{}}> {category} {transaction.amount} {unit} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="del-tb-row" >
                                    <TableCell className="d-cell-left" width="20%" sx={{ fontWeight: "bold" }}>លេខអ្នកផ្ញើ </TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}}>{transaction.sender_contact} </TableCell>
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}>លេខអ្នកទទួល </TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}} >{transaction.recipient_contact} </TableCell>
                                </TableRow>
                                <TableRow className="del-tb-row">
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}>ផ្ញើពីសាខា </TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}}>{senderBranchName} </TableCell>
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}>ទៅសាខា </TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}} >{receipientBranchName} </TableCell>
                                </TableRow>
                                <TableRow className="del-tb-row">
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}>តម្លៃផ្ញើ </TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}}>{formatCurrency(transaction.delievery_fee)} ៛</TableCell>
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}> តម្លៃដឹកដល់ផ្ទះ</TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}} >{formatCurrency(transaction.home_delievery_fee)} ៛</TableCell>
                                </TableRow>
                                <TableRow className="del-tb-row">
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}>តម្លៃធានារ៉ាប់រង </TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}}>{formatCurrency(transaction.insurance_fee)} ៛</TableCell>
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}> ថ្លៃអីវ៉ាន់COD</TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}} > {transaction.cash_on_delievery} </TableCell>
                                </TableRow>

                                <TableRow className="del-tb-row">
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}>តម្លៃកេស </TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}}>{formatCurrency(transaction.box_fee)} ៛</TableCell>
                                    <TableCell className="d-cell-left" width="25%" sx={{ fontWeight: "bold" }}>សរបប្រាក់បង់ </TableCell>
                                    <TableCell className="d-cell-right" width="24%" sx={{}} >{formatCurrency(transaction.total_fee)} ៛</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className="branchContactBox" display="flex" justifyContent="space-between" sx={{ gridColumn: "span 4", pt: "0.2rem" }}>
                    <Box sx={{ gridColumn: "span 2" }}>
                        <Typography className="branchInfoSubtitle" >
                            សាខា {senderBranchName}
                        </Typography>
                        {/* <br /> */}
                        <Typography className="branchInfoSubtitle" >
                            {senderBranchContact}
                        </Typography>
                    </Box>
                    <Box sx={{ gridColumn: "span 2" }}>
                        <Typography className="branchInfoSubtitle"  >
                            សាខា {receipientBranchName}
                        </Typography>
                        {/* <br /> */}
                        <Typography className="branchInfoSubtitle" >
                            {receipientBranchContact}
                        </Typography>
                    </Box >
                </Box >

                <Box sx={{ gridColumn: "span 4", pt: "0.05rem" }}>
                    <Typography className="d-cell-left" variant="h6" fontSize="14px" fontWeight="bold">
                        លក្ខខណ្ឌ ៖
                    </Typography>

                    <Typography className="branchInfoDescription">
                        - ក្រុមហ៊ុនមិនទទួលបញ្ញើខុសច្បាប់ដូចជាគ្រឿងញៀន អាវុធ សត្វព្រៃ ។ ល ។
                    </Typography>
                    {/* <br /> */}
                    <Typography className="branchInfoDescription">
                        - អីវ៉ាន់ដែលងាយខូចគុណភាព ក្រុមហ៊ុននឹងមិនទទួលខុសត្រូវ
                    </Typography>
                    {/* <br /> */}
                    <Typography className="branchInfoDescription">
                        - ក្រុមហ៊ុននឹងមិនទទួលខុសត្រូវលើអីវ៉ាន់ដែលភ្ញៀវទុកលើស ០១ សប្ដាហ៍
                    </Typography>
                    {/* <br /> */}
                    <Typography className="branchInfoDescription">
                        - ករណីបាត់ឬខូចខាតក្រុមហ៊ុននឹងផ្ដល់សំណងជូន 10 ដងនៃតំលៃផ្ញើ តែមិនលើសតំលៃអីវ៉ាន់ ។
                    </Typography>
                    {/* <br /> */}
                </Box >
                <Box sx={{ gridColumn: "span 4", pt: "0.2rem" }}>
                    <Typography className="branchInfoDescription" sx={{}}>
                        Facebook: សន្តិភាព ដឹកអ្នកដំណើរ និង បញ្ញើអីវ៉ាន់
                    </Typography>
                    <Typography className="branchInfoDescription"  >
                        Hotline: 087579494 / 090579494 / 095579494
                    </Typography>
                </Box >
                {/* <Box display="flex" gap="10px" justifySelf="end" sx={{ gridColumn: "span 4", pt: "0.2rem", pb: "0.1rem" }} >
                    <Button className="hide-on-print" onClick={handleGoBack} sx={{ mt: "15px", border: "solid 1px", height: "26px", width: "100px" }} >CANCEL</Button>
                    <Button className="hide-on-print" onClick={handlePrint} sx={{ mt: "15px", border: "solid 1px", height: "26px", width: "100px" }} > Print NOW </Button>
                </Box> */}
            </Box >

        </Box >

    );
};

export default DeliveryReceipt;
