import { React, useState, useEffect } from "react";
import { Box, useTheme, Button, Collapse, Alert, CircularProgress, Typography } from "@mui/material";
import { tokens } from "../../theme";
// import { parcelData } from "../../data/parcelData";
// import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
// import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import IconButton from "@mui/material/IconButton";
import { ClearOutlined, EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import dayjs from "dayjs";

import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Modal from "@mui/material/Modal";
// import zIndex from "@mui/material/styles/zIndex";
// import ModalDetailParcel from "./ViewParcelDetail";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { formatCurrency, formatPhoneNumber, zeroPad } from "../../helper/Helper";
import TimeFormatKh from "../../components/TimeFormatKh";



const TransitTicketList = ({ filter }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const Navigate = useNavigate();

    //// Detail View
    const [modalShow, setModalShow] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState('');
    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    //// Alert Dialog confirm before delete
    const [showConfirm, setShowConfirm] = useState(false);

    //// Alert delete succeed
    // const [alertInfo, setAlertInfo] = useState(false);

    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success')

    // const handleClickOpen = () => {
    //     setShowConfirm(true);
    // };

    const handleConfirmClose = () => {
        setShowConfirm(false);
    };

    const showConfirmDelete = row => {
        setSelectedTicket(row);
        setShowConfirm(true);
    }

    useEffect(() => {
        getTicketList();
        if (alert) {
            setTimeout(() => {
                setAlert(false);
            }, 3000)
        }
    }, [filter, alert]);

    const getTicketList = async () => {
        setIsLoading(true);
        try {
            const result = await fetch(window.domain + "/transits", {
                method: "POST",
                body: JSON.stringify({
                    status: filter,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            result.json().then(body => {
                setTickets(body);
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        };

    }


    const handleDeleteTicket = async () => {

        setIsLoading(true);
        try {
            const result = await fetch(window.domain + "/transit/delete", {
                method: "POST",
                body: JSON.stringify({
                    invoice_id: selectedTicket.invoice_id,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
            });

            result.json().then(body => {
                if (body.status) {
                    setTickets(prevTickets => prevTickets.filter(ticket => ticket.id !== selectedTicket.id));
                    setAlertSeverity("success")
                    setAlertContent(body.message)
                    setAlert(true)
                } else {
                    setAlertSeverity("error")
                    setAlertContent(body.message)
                    setAlert(true)
                    // console.log("Failed to delete " + body.message);
                }
            });
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        };

        handleConfirmClose();

    };

    const handleEdit = row => {
        Navigate('/transit/edit-ticket', { state: { item: row } });
    }



    const handleClose = () => {
        setModalShow(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        height: "99%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',

    };


    const ViewDetailTicket = () => {
        return (
            <Box>
                <Modal
                    sx={{ zIndex: 10000 }}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalShow}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modalShow}>
                        <Box sx={style}>
                            {/* <ModalDetailParcel parcel={selectedParcel} /> */}
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        );
    }
    // {dayjs(transaction.departure_date).format("DD/MM/YYYY")}
    // <TimeFormatKh time={dayjs(transaction.departure_time).format("HH:mm")} />
    const myColumns = [
        { field: "id", headerName: "Id", width: 60 },
        { field: "invoice_id", headerName: "លេខវិក័យប័ត្រ", width: 160, cellClassName: "name-column--cell", },
        {
            field: "departure_date", headerName: "ថ្ងៃចេញដំណើរ", width: 120,
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {dayjs(row.departure_date).format("DD/MM/YYYY")}
                    </Typography>
                )
            }
        },
        {
            field: "departure_time", headerName: "ម៉ោងចេញដំណើរ", width: 120,
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {row.departure_time}
                    </Typography>
                )
            }
        },
        { field: "from_branch_name", headerName: "ចេញដំណើរពី", width: 120 },
        { field: "to_branch_name", headerName: "ទៅកាន់", width: 120 },
        {
            field: "seat_no", headerName: "លេខកៅអី", width: 80,
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {zeroPad(row.seat_no, 2)}
                    </Typography>
                )
            }

        },
        {
            field: "customer_contact", headerName: "លេខទូរស័ព្ទអតិថិជន", width: 120,
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {formatPhoneNumber(row.customer_contact)}
                    </Typography>
                )
            }
        },
        {
            field: "agency_fee", headerName: "តម្លៃសេវាភ្នាក់ងារ", width: 100, type: "number",
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {row.agency_currency} {formatCurrency(parseFloat(row.agency_fee))}
                    </Typography>
                )
            }
        },
        {
            field: "ticket_price", headerName: "ថ្លៃសំបុត្រ", width: 100, type: "number",
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {row.ticket_currency} {formatCurrency(parseFloat(row.ticket_price))}
                    </Typography>
                )
            }
        },
        {
            field: "discount", headerName: "បញ្ចុះតម្លៃ", width: 100, type: "number",
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {row.discount_currency} {formatCurrency(parseFloat(row.discount))}
                    </Typography>
                )
            }
        },
        {
            field: "total", headerName: "ថ្លៃសរុប", width: 100, type: "number",
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {row.ticket_currency}  {formatCurrency(parseFloat(row.total))}
                    </Typography>
                )
            }
        },
        {
            field: "date_created", headerName: "កាលបរិច្ឆេទផ្ញើ", width: 160,
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="12px">
                        {dayjs(row.date_created).format("DD/MM/YYYY HH:MM")}
                    </Typography>
                )
            }
        },
        {
            field: "created_by", headerName: "លក់ដោយ", width: 80
        },
        {
            field: "action", headerName: "សកម្មភាព", width: 140,
            renderCell: ({ row }) => {
                return (
                    <Box display="Flex" justifyContent="space-between" alignItems="center" key={row.id}>
                        {/* <IconButton color="primary" aria-label="View" component="label" onClick={() => { handleView(row) }}
                            sx={{
                                backgroundColor: colors.blueAccent[800],
                                color: colors.buttonText1[100],
                                m: "0px 4px 0px 4px"
                            }}
                        >
                            <VisibilityOutlined />
                        </IconButton> */}

                        <IconButton color="primary" aria-label="Edit" component="label" onClick={() => handleEdit(row)}
                            sx={{
                                backgroundColor: colors.buttonBG1[200],
                                color: colors.buttonText1[100],
                                m: "0px 4px 0px 4px"
                            }}
                        >
                            <EditOutlined />
                        </IconButton>

                        <IconButton color="primary" onClick={() => showConfirmDelete(row)} aria-label="Delete" component="label"
                            sx={{
                                backgroundColor: colors.buttonBG1[300],
                                color: colors.buttonText1[100], m: "0px 4px 0px 4px"
                            }}
                        >
                            <ClearOutlined />
                        </IconButton>
                    </Box >);
            },
        }
    ];

    const TicketGrid = () => {

        return (
            <DataGrid rows={tickets} columns={myColumns} components={{ Toolbar: GridToolbar }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                    },
                    isLoading: { isLoading }
                }}
            />
        )
    }

    return (
        <Box m="20px">
            <Dialog
                open={showConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    zIndex: 10000
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"ផ្ទៀងផ្ទាត់"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        តើអ្នកពិតជាចង់លុបបញ្ញើលេខ ' {selectedTicket.invoice_id} ' នេះមែនទេ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose}>No</Button>
                    <Button onClick={handleDeleteTicket} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="សំបុត្រឡានទាំងអស់" subtitle="ពិនិត្យនិងគ្រប់គ្រងសំបុត្រឡានទាំងអស់" />
            </Box>
            <Collapse in={alert}>
                <Alert severity={alertSeverity} action={alertSeverity === "success" ? <CircularProgress sx={{ p: "4px", color: colors.greenAccent[800] }} /> : undefined} > {alertContent} </Alert>
            </Collapse>
            <Box
                m="8px 0 0 0"
                height="80vh"
                sx={{

                    "& .MuiDataGrid-cell:focus": {
                        outline: "solid #256970 0px;",
                    },

                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "solid #256970 0px;",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[800],
                        color: "white"
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <TicketGrid />
            </Box>
            {/* <ViewDetailParcel /> */}

        </Box>

    );
};

export default TransitTicketList;
