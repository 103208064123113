import { React, useState, useEffect, useRef } from "react";
import { Box, Button, Divider, TextField, Typography, Input, InputAdornment, OutlinedInput, Checkbox, FormControlLabel, FormGroup, FilledInput, IconButton } from '@mui/material';
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { CheckCircleOutline, ClearOutlined, HighlightOff } from "@mui/icons-material";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeliveryReceipt from "../../components/DelieveryReceipt";

import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Modal from "@mui/material/Modal";

import '../../receipt.css';
import { formatCurrency } from "../../helper/Helper";
import DeviceDetector from "../../helper/DeviceDetector";


const AddParcel = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isNonMobile = useMediaQuery("(min-width:880px)");
    const summarySize = useMediaQuery("(min-width:1120px)");
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const isNumberRex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
    const [branchList, setBranchList] = useState([]);
    const [category, setCategory] = useState([]);
    const [unit, setUnit] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [CreateParcelStatus, setParcelCreateStatus] = useState(false);

    // const [parcelWeight, setParcelWeight] = useState(0);
    // const [parcelHeight, setParcelHeight] = useState(0);
    // const [parcelLength, setParcelLength] = useState(0);
    // const [parcelWidth, setParcelWidth] = useState(0);
    // const [parcelPrice, setParcelPrice] = useState(0);

    const [isHomeDelievery, setIsHomeDelievery] = useState(false);
    const [isAddedInsurance, setIsAddedInsurance] = useState(false);
    const [isAddedParcelBox, setIsAddedParcelBox] = useState(false);
    const [isCashOnDelievery, setIsCashOnDelievery] = useState(false);
    const [totalFeeRiel, setTotalFeeRiel] = useState(0);
    const [totalFeeDollar, setTotalFeeDollar] = useState(0);
    const [homeDelieveryFee, setHomeDelieveryFee] = useState(0);
    const [insuranceFee, setInsuranceFee] = useState(0);
    const [parcelBoxFee, setParcelBoxFee] = useState(0);
    const [parcelFee, setParcelFee] = useState(0);
    const [exchageRate, setExchangeRate] = useState(4000);
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState("");
    const [parcelFeeFocused, setParcelFeeFocused] = useState(false);
    const [transactionInfo, setTransactionInfo] = useState({});
    const [deviceType, setDeviceType] = useState(false);

    // Submit and print the receipt
    const handlePrint = async () => {

        try {
            const result = await fetch(window.domain + "/parcels/create", {
                method: "POST",
                body: JSON.stringify(transactionInfo),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            result.json().then(body => {
                if (body.status) {
                    window.print();
                    handleModalClose();
                    setParcelCreateStatus(true);
                    setOpenDialog(true);
                } else {
                    setParcelCreateStatus(false);
                    setOpenDialog(true);
                }
            });

        } catch (error) {
            console.error('Error while creating parcel', error);
            setParcelCreateStatus(false);
            setOpenDialog(true);
        }
    }


    // const style = {
    //     '@media print': {
    //         'page': {
    //             // size: "portrait",
    //             // width: "80mm !important",
    //             // height: "110mm !important",
    //             // padding: "0px",
    //             // margin: "0px",
    //             // '.delivery-receipt': {
    //             //     padding: "0px 0px 0px 0px",
    //             //     fontSize: "8px",
    //             // }
    //         },
    //         // width: "80mm !important",
    //         // height: "110mm !important",
    //         bgcolor: "none",
    //         boxShadow: "none",
    //         padding: "0px",
    //         margin: "0px",
    //         border: "0px",
    //         left: '0%',
    //         '& .hide-on-print': {
    //             display: "none"
    //         }
    //     },
    //     position: 'absolute',
    //     top: '0%',
    //     left: `${deviceType === "Mobile" ? "0%" : "35%"}`,  //`Bearer ${localStorage.getItem("token")}`
    //     // transform: 'translate(-50%, -50%)',
    //     width: `${deviceType === "Mobile" ? "100%" : "400px"}`,
    //     height: `${deviceType === "Mobile" ? "100%" : undefined}`,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     // boxShadow: 24,
    //     // overflowY: 'scroll'

    // };
    const style = {
        '@media print': {
            'page': {
                size: "portrait",
                width: "80mm !important",
                height: "100mm !important",
                padding: "0px",
                margin: "0px",
                '.delivery-receipt': {
                    padding: "0px 0px 0px 0px",
                    fontSize: "8px",
                }
            },
            width: "80mm !important",
            height: "100mm !important",
            bgcolor: "none",
            boxShadow: "none",
            padding: "0px",
            margin: "0px",
            border: "0px",
            left: '0%',
            '& .hide-on-print': {
                display: "none"
            }
        },
        position: 'absolute',
        top: '0%',
        left: `${deviceType === "Mobile" ? "0%" : "35%"}`,  //`Bearer ${localStorage.getItem("token")}`
        // transform: 'translate(-50%, -50%)',
        width: "400px",
        height: "100%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        overflowY: 'scroll'

    };

    const handleModalClose = () => {
        setModalShow(false);
    }

    const handleModalShow = () => {
        setModalShow(true);
    }


    const ViewDetailReceipt = () => {

        return (
            <Box>
                <DeviceDetector />
                <Modal
                    sx={{ zIndex: 10000 }}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalShow}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modalShow}>
                        <Box sx={style}>
                            <DeliveryReceipt
                                transaction={transactionInfo}
                                branchList={branchList}
                                categories={category}
                                units={unit}
                            />
                            <Box className="hide-on-print" display="flex" gap="10px" sx={{ gridColumn: "span 4", pt: "4px", mb: "10px", mr: "10px", pb: "10px", justifyContent: "end", }} >
                                <Button className="hide-on-print" onClick={handleModalClose} sx={{ mt: "15px", border: "solid 1px", height: "26px", width: "100px" }} >ផ្អាក</Button>
                                <Button className="hide-on-print" onClick={handlePrint} sx={{ mt: "15px", border: "solid 1px", height: "26px", width: "100px" }} > ចេញវិក័យប័ត្រ </Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Box >
        );
    }


    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setParcelCreateStatus(false);
    };

    const AlertDialog = () => {
        return (
            <Box backgroundColor="red">
                <Dialog fullWidth height="100px"
                    sx={{
                        zIndex: 10000,
                    }}
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ paddingBottom: "0px" }}>
                        <Box display="flex" justifyContent="space-between" >
                            <Typography variant="h4" fontWeight="bold" paddingBottom={2}>{"ជូនដំណឹង"} </Typography>
                            <Button
                                onClick={handleClose}
                                disableElevation={true}
                                disableRipple={true}
                                disableFocusRipple={true}
                                sx={{ minWidth: "0px", marginTop: "-20px", marginRight: "-15px", ":hover": { backgroundColor: "transparent" } }}
                            > <ClearOutlined /> </Button>
                        </Box>
                    </DialogTitle>
                    <Divider />
                    <DialogContent >
                        {CreateParcelStatus === true ?
                            <DialogContentText id="alert-dialog-description"
                                sx={{
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    fontSize: "18px"
                                }}>
                                <CheckCircleOutline sx={{ color: colors.greenAccent[400] }} /> ការបញ្ចូលបញ្ញើថ្មីបានជោគជ័យ!
                            </DialogContentText>
                            : <DialogContentText id="alert-dialog-description"
                                sx={{
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    fontSize: "18px"
                                }}>
                                <HighlightOff sx={{ color: "red" }} /> ការបញ្ចូលបញ្ញើថ្មីបរាជ័យ!
                            </DialogContentText>}
                        {/* <DialogContentText id="alert-dialog-description"
                            sx={{
                                fontWeight: "bold",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                fontSize: "18px"
                            }}
                        >
                            {CreateParcelStatus === true ? <Box display="flex" gap="10px"><CheckCircleOutline sx={{ color: colors.greenAccent[400] }} /><Typography>ការបញ្ចូលបញ្ញើថ្មីបានជោគជ័យ!</Typography> </Box>
                                : <Box display="flex" gap="10px"><HighlightOff sx={{ color: "red" }} /> <Typography>ការបញ្ចូលបញ្ញើថ្មីបរាជ័យ!</Typography></Box>}
                        </DialogContentText> */}
                    </DialogContent>
                    <Divider />


                    <DialogActions>
                        <Button onClick={handleClose} autoFocus sx={{
                            fontSize: "18px",
                            backgroundColor: `${colors.greenAccent[400]}`,
                            color: "white",
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginRight: "10px",
                            minWidth: "120px",

                            ":hover": {
                                backgroundColor: `${colors.greenAccent[300]}`,
                            }
                        }}>
                            បិទ
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }



    const branches = branchList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );

    const categories = category.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );

    const units = unit.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );


    const getBranchList = async () => {
        try {
            const result = await fetch(window.domain + "/branches", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
            });
            result.json().then(body => {
                setBranchList(body);
            });
        } catch (error) {

        };
    }

    const getCategory = async () => {
        try {
            const result = await fetch(window.domain + "/parcels/category", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
            });
            result.json().then(body => {
                setCategory(body);
            });
        } catch (error) {

        };
    }

    const getUnit = async () => {
        try {
            const result = await fetch(window.domain + "/parcels/unit", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
            });
            result.json().then(body => {
                setUnit(body.unit);
                setExchangeRate(body.rate[0].price);
            });
        } catch (error) {

        };
    }


    useEffect(() => {
        if (branches.length <= 0) { getBranchList() };
        if (categories.length <= 0) { getCategory() };
        if (units.length <= 0) { getUnit() };
        calculateTotalFee()
    }, [homeDelieveryFee, insuranceFee, parcelBoxFee, parcelFee, isAddedInsurance, isAddedParcelBox, isHomeDelievery])

    // function formatCurrency(value) {
    //     return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // }

    const calculateTotalFee = () => {
        var totalRiel = 0;
        if (isHomeDelievery) totalRiel = totalRiel + parseFloat(homeDelieveryFee);
        if (isAddedInsurance) totalRiel = totalRiel + parseFloat(insuranceFee);
        if (isAddedParcelBox) totalRiel = totalRiel + parseFloat(parcelBoxFee);
        totalRiel = totalRiel + parseFloat(parcelFee);
        const totalDollar = totalRiel / exchageRate;

        setTotalFeeRiel(totalRiel > 0 ? formatCurrency(totalRiel) : 0);
        setTotalFeeDollar(totalDollar > 0 ? formatCurrency(totalDollar) : 0);
    }

    const handleCheckBoxChange = (e) => {
        const name = e.target.name;
        if (name === "chkDelieveryFee") {
            setIsHomeDelievery(e.target.checked)
            e.target.backgroundColor = "transparent";
            if (e.target.checked === false) {
                setHomeDelieveryFee(0)
            }
        } else if (name === "chkInsuranceFee") {
            setIsAddedInsurance(e.target.checked)
            if (e.target.checked === false) {
                setInsuranceFee(0)
            }
        } else if (name === "chkAddBoxFee") {
            setIsAddedParcelBox(e.target.checked)
            if (e.target.checked === false) {
                setParcelBoxFee(0)
            }
        } else if (name === "chkCashOnDelievery") {
            setIsCashOnDelievery(e.target.checked)

        }

    }

    const handleFeeCharge = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        if (name === "inputHomeDelieveryFee") {
            setHomeDelieveryFee(val);

        } else if (name === "inputInsuranceFee") {
            setInsuranceFee(val);

        } else if (name === "inputParcelBoxFee") {
            setParcelBoxFee(val);

        } else if (name === "inputParcelFee") {
            setParcelFee(val);

        }
    }

    const handleParcelFeeFocus = () => {
        setParcelFeeFocused(true);
    }

    const handleCustomSubmit = (e) => {
        if (parcelFee <= 0 && parcelFeeFocused) {
            setError("ពិនិត្យមើលតំលៃឡើងវិញ");
        } else {
            setError("");
        }
    }

    const handleNumberKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[0-9\b.,]+$/;
        const value = event.target.value;

        if (event.target.name === "inputParcelFee") {
            if (value > 0) setError("");
        }

        if (parseFloat(value) < 0) {
            event.preventDefault();
            return
        }

        // if (value.charAt(0) === '0' && keyCode !== 8 && keyCode !== 37 && keyCode !== 39) {
        //     event.preventDefault();
        //     return
        // }

        // Allow only 1 dot in value
        if (keyCode === 190 && value.includes(".") === false) {
            return
        }

        // Allow select all
        if ((event.ctrlKey || event.metaKey) && keyCode == 65) {
            return
        }

        // Allow left & right and tab
        if (keyCode === 37 || keyCode === 39 || keyCode === 9) {
            return
        }

        // Prevent any input beside number
        if (!regex.test(keyValue)) {
            event.preventDefault();
        }

    };




    const initialValues = {
        // Delivery Information 
        senderName: "",
        senderAddress: "",
        senderContact: "",
        senderBranch: "",
        recipientName: "",
        recipientContact: "",
        recipientAddress: "",
        recipientBranch: "",
        // Parcel Information
        parcelWeight: 0,
        parcelHeight: 0,
        parcelLength: 0,
        parcelWidth: 0,
        parcelPrice: 0, // the cost of that parcel
        parcelType: "", // food, fruit, drink, furniture, etc...
        parcelAmount: 0, // amount of parce to be ship
        parcelUnit: "",
        delieveryFee: 0, // the total fee of delievery service

        // Extra order service
        insuranceFee: 0,
        parcelBoxFee: 0,
        homeDelieveryFee: 0,
        // Other request
        cashOnDelievery: false,
        parcelPriceCurrency: "$"
        // parcelFee: 0,
        // totalFeeDollar: 0,
        // totalFeeRiel: 0,
    };

    const checkoutSchema = yup.object().shape({
        senderName: yup.string(),
        // senderAddress: yup.string(),
        parcelType: yup.string().required("សូមបំពេញប្រភេទទំនិញ"),
        senderContact: yup.string().matches(phoneRegExp, "លេខទូរស័ព្ទមិនត្រឹមត្រូវ").required("Required"),
        senderBranch: yup.string().required("Required"),
        recipientName: yup.string(),
        recipientContact: yup.string().matches(phoneRegExp, "លេខទូរស័ព្ទមិនត្រឹមត្រូវ!").required("Required"),
        // recipientAddress: yup.string(),
        recipientBranch: yup.string().required("Required"),
        parcelWeight: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(0),
        parcelHeight: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(0),
        parcelLength: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(0),
        parcelWidth: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(0),
        parcelAmount: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(1, "តម្លៃទំនិញត្រូវតែធំជាងសូន្យ"),
        parcelPrice: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(1, "តម្លៃទំនិញត្រូវតែធំជាងសូន្យ"),
        delieveryFee: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(0),
        parcelUnit: yup.number().required("សូមជ្រើសរើសឯកតា").positive().min(0),
        insuranceFee: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(0),
        parcelBoxFee: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(0),
        homeDelieveryFee: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(0),
        cashOnDelievery: yup.boolean(),
        parcelPriceCurrency: yup.string().required("Required"),
        // parcelFee: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(1, "តម្លៃសេវាបញ្ញើត្រូវតែធំជាងសូន្យ"),
    })


    const handleFormSubmit = async (values) => {
        // console.log("device:" + localStorage.getItem("DeviceType"))
        setDeviceType(localStorage.getItem("DeviceType"))
        // console.log("device:" + deviceType)
        if (error != "") {
            return;
        }

        const ref_number = values.senderBranch + "-" + values.recipientBranch + "-" + Date.now().toString();
        const transaction_info = {
            reference_number: ref_number,
            sender_name: values.senderName,
            sender_address: values.senderAddress,
            sender_contact: values.senderContact,
            recipient_name: values.recipientName,
            recipient_address: values.recipientAddress,
            recipient_contact: values.recipientContact,
            category: values.parcelType,
            amount: values.parcelAmount,
            unit: values.parcelUnit,
            from_branch_id: values.senderBranch,
            to_branch_id: values.recipientBranch,
            weight: values.parcelWeight,
            height: values.parcelHeight,
            width: values.parcelWidth,
            length: values.parcelLength,
            parcel_price: values.parcelPrice,
            parcel_price_currency: values.parcelPriceCurrency,
            delievery_fee: parcelFee,
            box_fee: parcelBoxFee,
            insurance_fee: insuranceFee,
            home_delievery_fee: homeDelieveryFee,
            total_fee: parseFloat(totalFeeRiel.replace(/,/g, '')),
            cash_on_delievery: isCashOnDelievery,
            created_by: localStorage.getItem("user")
        }
        setTransactionInfo(transaction_info);
        handleModalShow();

        // console.log("tran:" + transaction_info);



        // try {
        //     const result = await fetch(window.domain + "/parcels/create", {
        //         method: "POST",
        //         body: JSON.stringify(transaction_info),
        //         // body: JSON.stringify({
        //         //     reference_number: ref_number,
        //         //     sender_name: values.senderName,
        //         //     sender_address: values.senderAddress,
        //         //     sender_contact: values.senderContact,
        //         //     recipient_name: values.recipientName,
        //         //     recipient_address: values.recipientAddress,
        //         //     recipient_contact: values.recipientContact,
        //         //     category: values.parcelType,
        //         //     unit: values.parcelUnit,
        //         //     from_branch_id: values.senderBranch,
        //         //     to_branch_id: values.recipientBranch,
        //         //     weight: values.parcelWeight,
        //         //     height: values.parcelHeight,
        //         //     width: values.parcelWidth,
        //         //     length: values.parcelLength,
        //         //     parcel_price: values.parcelPrice,
        //         //     delievery_fee: parcelFee,
        //         //     box_fee: parcelBoxFee,
        //         //     insurance_fee: insuranceFee,
        //         //     home_delievery_fee: homeDelieveryFee,
        //         //     total_fee: parseFloat(totalFeeRiel.replace(",", "")),
        //         //     cash_on_delievery: isCashOnDelievery,
        //         //     created_by: 1,
        //         // }),
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //     });

        //     // if (result.ok === false) {
        //     //     setParcelCreateStatus(false);
        //     //     setOpenDialog(true);
        //     // }

        //     result.json().then(body => {
        //         if (body.status) {
        //             handleModalShow();
        //             // console.log("parcel added!");
        //             // setParcelCreateStatus(true);
        //             // setOpenDialog(true);
        //         } else {
        //             setParcelCreateStatus(false);
        //             setOpenDialog(true);
        //         }
        //     });



        // } catch (error) {
        //     console.error('Error while creating parcel', error);
        //     setParcelCreateStatus(false);
        //     setOpenDialog(true);
        // }
    };

    return (
        <Box m="20px" sx={{
            "& .MuiInput-input": {
                textAlign: "center"
            },
        }}>
            <AlertDialog />
            <Header title="បញ្ចូលបញ្ញើថ្មី" subtitle="បំពេញពត៌មានបញ្ញើខាងក្រោម" />

            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema} validate={handleCustomSubmit}  >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid"
                            gap="10px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            borderRadius={2}
                            backgroundColor={colors.primary[400]}
                            border={4}
                            borderLeft={0}
                            borderRight={0}
                            borderBottom={0}
                            borderColor={colors.blueAccent[700]}
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                mt: 4,
                                mb: 3,
                                p: "0px 20px 30px 20px",
                                boxShadow: "2px 4px 10px 2px rgba(0,0,0,0.1)",
                                "& .MuiInputLabel-root": {
                                    fontSize: "16px"
                                },

                                "&  .MuiFilledInput-input": {
                                    fontSize: "18px",
                                    textAlign: "center"
                                },
                            }} >

                            <Typography variant='h4' fontWeight="bold" sx={{ m: "20px 20px 0px 0px", gridColumn: "span 4" }} color={colors.grey[100]}>
                                ពត៌មានកញ្ចប់បញ្ញើ
                            </Typography>
                            <Divider sx={{ m: "5px 0px 10px 0px ", gridColumn: "span 4" }} />

                            <FormControl variant="filled" fullWidth >
                                <InputLabel shrink={true} id="labelParcelType">ប្រភេទអីវ៉ាន់</InputLabel>
                                <Select
                                    labelId="parcelTypeID"
                                    name="parcelType"
                                    defaultValue=''
                                    value={values.parcelType}
                                    label="ប្រភេទអីវ៉ាន់"

                                    // error={!!touched.parcelType && !!errors.parcelType}
                                    // helperText={touched.parcelType && errors.parcelType}
                                    onChange={handleChange}
                                >
                                    {categories}

                                </Select>
                                {!!touched.parcelType && !!errors.parcelType &&
                                    <div className="input-feedback" style={{
                                        fontSize: 9,
                                        color: "#d32f2f",
                                        margin: "3px 14px 0px 14px"
                                    }}>
                                        {errors.parcelType}
                                    </div>}
                            </FormControl>

                            <TextField
                                fullWidth
                                sx={{ gridColumn: "span 1" }}
                                variant="filled"
                                type="number"
                                label="ចំនួន"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onKeyDown={handleNumberKeyPress}
                                value={values.parcelAmount}
                                name="parcelAmount"
                                error={!!touched.parcelAmount && !!errors.parcelAmount}
                                helperText={touched.parcelAmount && errors.parcelAmount}

                            />

                            <FormControl variant="filled" fullWidth >
                                <InputLabel shrink={true} id="labelParcelUnit">ឯកតា</InputLabel>
                                <Select
                                    labelId="recipient-branch-select-label"
                                    name="parcelUnit"
                                    value={values.parcelUnit}
                                    label="ឯកតា"

                                    error={!!touched.parcelUnit && !!errors.parcelUnit}
                                    // helperText={touched.parcelUnit && errors.parcelUnit}
                                    onChange={handleChange}
                                >
                                    {units}

                                </Select>
                                {!!touched.parcelUnit && !!errors.parcelUnit &&
                                    <div className="input-feedback" style={{
                                        fontSize: 9,
                                        color: "#d32f2f",
                                        margin: "3px 14px 0px 14px"
                                    }}>
                                        {errors.parcelUnit}
                                    </div>}
                            </FormControl>
                            <Box display="flex" sx={{ gridColumn: "span 1", "& .MuiFilledInput-root": { borderTopRightRadius: "0px" } }}>
                                <TextField className="testing"
                                    fullWidth
                                    sx={{ gridColumn: "span 1" }}
                                    variant="filled"
                                    type="number"
                                    label="តម្លៃអីវ៉ាន់"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onKeyDown={handleNumberKeyPress}
                                    value={values.parcelPrice}
                                    name="parcelPrice"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    error={!!touched.parcelPrice && !!errors.parcelPrice}
                                    helperText={touched.parcelPrice && errors.parcelPrice}
                                    sx={{

                                        "input[type = number]": {
                                            MozAppearance: "textfield",

                                        },
                                        "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                                            WebkitAppearance: "none",
                                            margin: 0
                                        }
                                    }}
                                />
                                <FormControl variant="filled" sx={{ width: "60%", pr: "0px", "& .MuiFilledInput-root": { borderTopLeftRadius: "0px !important" } }} >
                                    {/* <InputLabel id="currency-label" >ប្រភេទ</InputLabel> */}
                                    <Select
                                        labelId="CurrencyLabel"
                                        name="parcelPriceCurrency"
                                        value={values.parcelPriceCurrency}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={0} value="$">$</MenuItem>
                                        <MenuItem key={1} value="៛">៛</MenuItem>

                                    </Select>
                                </FormControl>
                            </Box>
                            {/* <TextField
                                fullWidth
                                sx={{ gridColumn: "span 1" }}
                                variant="filled"
                                type="number"
                                label="តម្លៃអីវ៉ាន់"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onKeyDown={handleNumberKeyPress}
                                value={values.parcelPrice}
                                name="parcelPrice"
                                error={!!touched.parcelPrice && !!errors.parcelPrice}
                                helperText={touched.parcelPrice && errors.parcelPrice}
                            /> */}


                            <TextField
                                fullWidth
                                sx={{ gridColumn: "span 1" }}
                                variant="filled"
                                type="number"
                                label="ទំងន់ (kg)"
                                onKeyDown={handleNumberKeyPress}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.parcelWeight}
                                name="parcelWeight"
                                error={!!touched.parcelWeight && !!errors.parcelWeight}
                                helperText={touched.parcelWeight && errors.parcelWeight}
                            />

                            <TextField
                                fullWidth
                                sx={{ gridColumn: "span 1" }}
                                variant="filled"
                                type="number"
                                label="កំពស់ (cm)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onKeyDown={handleNumberKeyPress}
                                value={values.parcelHeight}
                                name="parcelHeight"
                                error={!!touched.parcelHeight && !!errors.parcelHeight}
                                helperText={touched.parcelHeight && errors.parcelHeight}
                            />

                            <TextField
                                fullWidth
                                sx={{ gridColumn: "span 1" }}
                                variant="filled"
                                type="number"
                                label="បណ្ដោយ (cm)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onKeyDown={handleNumberKeyPress}
                                value={values.parcelLength}
                                name="parcelLength"
                                error={!!touched.parcelLength && !!errors.parcelLength}
                                helperText={touched.parcelLength && errors.parcelLength}
                            />

                            <TextField
                                fullWidth
                                sx={{ gridColumn: "span 1" }}
                                variant="filled"
                                type="number"
                                label="ទទឹង (cm)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onKeyDown={handleNumberKeyPress}
                                value={values.parcelWidth}
                                name="parcelWidth"
                                error={!!touched.parcelWidth && !!errors.parcelWidth}
                                helperText={touched.parcelWidth && errors.parcelWidth}
                            />
                        </Box>


                        {/*****************************************************************************/
                        /************************* Delivery Info Section ******************************/
                        /******************************************************************************/}
                        <Box
                            display="grid"
                            gap="20px"
                            gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
                            }}
                        >
                            {/* Sender & Reciepient Info  */}
                            <Box sx={{ gridColumn: "span 1" }} >
                                <Box
                                    borderRadius={2}
                                    backgroundColor={colors.primary[400]}
                                    border={4}
                                    borderLeft={0}
                                    borderRight={0}
                                    borderBottom={0}
                                    borderColor={colors.greenAccent[600]}

                                    sx={{
                                        boxShadow: "2px 4px 10px 2px rgba(0,0,0,0.1)",
                                        p: "0px 0px 20px 0px",
                                        gridColumn: "span 2",
                                    }} >
                                    <Typography variant='h4' fontWeight="bold" sx={{ m: "20px 20px 10px 20px" }} color={colors.grey[100]}>
                                        ពត៌មានអ្នកផ្ញើ
                                    </Typography>
                                    <Divider sx={{ m: "10px 20px 10px 20px " }} />
                                    <Box sx={{ m: "20px 20px 20px 20px " }}>
                                        <TextField
                                            sx={{ pb: "10px" }}
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="ឈ្មោះអ្នកផ្ញើ"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.senderName}
                                            name="senderName"
                                            error={!!touched.senderName && !!errors.senderName}
                                            helperText={touched.senderName && errors.senderName}
                                        />

                                        <TextField
                                            sx={{ pb: "10px" }}
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="លេខទំនាក់ទំនង"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.senderContact}
                                            name="senderContact"
                                            error={!!touched.senderContact && !!errors.senderContact}
                                            helperText={touched.senderContact && errors.senderContact}
                                        />

                                        <FormControl variant="filled" fullWidth >
                                            <InputLabel id="sender-branch-select-label" >ផ្ញើពីសាខា</InputLabel>
                                            <Select
                                                labelId="sender-branch-select-label"
                                                name="senderBranch"
                                                value={values.senderBranch}
                                                onChange={handleChange}
                                                error={!!touched.senderBranch && !!errors.senderBranch}
                                            // helperText={touched.senderBranch && errors.senderBranch}
                                            >
                                                {branches}
                                            </Select>
                                            {!!touched.senderBranch && !!errors.senderBranch &&
                                                <div className="input-feedback" style={{
                                                    fontSize: 9,
                                                    color: "#d32f2f",
                                                    margin: "3px 14px 0px 14px"
                                                }}>
                                                    {errors.senderBranch}
                                                </div>}
                                        </FormControl>
                                    </Box>

                                </Box>

                                <Box
                                    mt="20px"
                                    backgroundColor={colors.primary[400]}
                                    border={4}
                                    borderLeft={0}
                                    borderRight={0}
                                    borderBottom={0}
                                    borderColor={colors.redAccent[700]}
                                    borderRadius={2}
                                    sx={{
                                        p: "0px 0px 20px 0px",
                                        gridColumn: "span 2",
                                        boxShadow: "2px 4px 10px 2px rgba(0,0,0,0.1)",
                                    }}>
                                    <Typography color={colors.grey[100]} variant='h4' fontWeight="bold" sx={{ m: "20px 20px 10px 20px" }} >
                                        ពត៌មានអ្នកទទួល
                                    </Typography>
                                    <Divider sx={{ m: "10px 20px 10px 20px " }} />
                                    <Box sx={{ m: "20px 20px 20px 20px " }}>
                                        <TextField

                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="ឈ្មោះ"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.recipientName}
                                            name="recipientName"
                                            error={!!touched.recipientName && !!errors.recipientName}
                                            helperText={touched.recipientName && errors.recipientName}
                                            sx={{ pb: "10px" }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="លេខទំនាក់ទំនង"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.recipientContact}
                                            name="recipientContact"
                                            error={!!touched.recipientContact && !!errors.recipientContact}
                                            helperText={touched.recipientContact && errors.recipientContact}
                                            sx={{ pb: "10px" }}
                                        />

                                        <FormControl variant="filled" fullWidth >
                                            <InputLabel id="recipient-branch-select-label">ទទួលនៅសាខា</InputLabel>
                                            <Select
                                                labelId="recipient-branch-select-label"
                                                name="recipientBranch"
                                                value={values.recipientBranch}
                                                label="ទទួលនៅសាខា"
                                                error={!!touched.recipientBranch && !!errors.recipientBranch}
                                                // helperText={touched.recipientBranch && errors.recipientBranch}
                                                onChange={handleChange}
                                            >
                                                {branches}

                                            </Select>
                                            {!!touched.recipientBranch && !!errors.recipientBranch &&
                                                <div className="input-feedback" style={{
                                                    fontSize: 9,
                                                    color: "#d32f2f",
                                                    margin: "3px 14px 0px 14px"
                                                }}>
                                                    {errors.recipientBranch}
                                                </div>}
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>

                            {/* *************************************/
                            /*        Summary & Calulate Price      */
                            /* **************************************/}
                            <Box sx={{ gridColumn: "span 1", boxShadow: "2px 4px 10px 2px rgba(0,0,0,0.1)", }}
                                borderRadius={2}
                                backgroundColor={colors.primary[400]}
                                border={4}
                                borderLeft={0}
                                borderRight={0}
                                borderBottom={0}
                                borderColor={colors.blueAccent[200]}

                            >

                                <Box sx={{ p: "0px 0px 40px 0px" }} >
                                    <Typography variant='h4' fontWeight="bold" sx={{ m: "20px 0px 10px 20px" }} color={colors.grey[100]}>
                                        សង្ខេប និង ទូទាត់ថ្លៃសេវា
                                    </Typography>
                                    <Divider sx={{ m: "10px 20px 10px 20px " }} />
                                    <Box m="0px 0px 0px 0px"
                                        p="0px 30px 0px 30px"
                                        display="grid"
                                        gap="10px"
                                        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: summarySize ? undefined : "span 2" },
                                        }} >

                                        <FormControl fullWidth variant="standard" sx={{ gridColumn: "span 1" }}>
                                            <FormGroup sx={{ textAlign: "end" }}>
                                                <FormControlLabel control={<Checkbox name="chkDelieveryFee" onChange={handleCheckBoxChange} />} label="បន្ថែមសេវាដឹកដល់ផ្ទះ" />
                                            </FormGroup>

                                            <FilledInput
                                                type="number"
                                                value={homeDelieveryFee}
                                                onChange={handleFeeCharge}
                                                onKeyDown={handleNumberKeyPress}
                                                name="inputHomeDelieveryFee"
                                                id="inputHomeDelieveryFee" disabled={!isHomeDelievery}
                                                startAdornment={<InputAdornment position="start">៛</InputAdornment>}
                                                sx={{
                                                    "&  .MuiFilledInput-input": {
                                                        fontSize: "22px",
                                                        textAlign: "center",
                                                        maxHeight: "9px",
                                                        pt: "16px",
                                                        pb: "15px"
                                                    },
                                                    "input[type = number]": {
                                                        MozAppearance: "textfield"
                                                    },
                                                    "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                                                        WebkitAppearance: "none",
                                                        margin: 0
                                                    }
                                                }}

                                            />
                                        </FormControl>

                                        <FormControl fullWidth variant="standard" sx={{ gridColumn: "span 1" }}>
                                            <FormGroup sx={{ textAlign: "end" }}>
                                                <FormControlLabel control={<Checkbox name="chkInsuranceFee" onChange={handleCheckBoxChange} />} label="បន្ថែមសេវាធានារ៉ាប់រង" />

                                            </FormGroup>

                                            <FilledInput
                                                type="number"
                                                onChange={handleFeeCharge}
                                                onKeyDown={handleNumberKeyPress}
                                                name="inputInsuranceFee"
                                                value={insuranceFee}
                                                id="inputInsuranceFee" disabled={!isAddedInsurance}
                                                startAdornment={<InputAdornment position="start">៛</InputAdornment>}
                                                sx={{
                                                    "&  .MuiFilledInput-input": {
                                                        fontSize: "22px",
                                                        textAlign: "center",
                                                        maxHeight: "9px",
                                                        pt: "16px",
                                                        pb: "15px"
                                                    },

                                                    "input[type = number]": {
                                                        MozAppearance: "textfield"
                                                    },
                                                    "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                                                        WebkitAppearance: "none",
                                                        margin: 0
                                                    }
                                                }}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth variant="standard" sx={{ gridColumn: "span 1" }}>
                                            <FormGroup sx={{ textAlign: "end" }}>
                                                <FormControlLabel control={<Checkbox name="chkAddBoxFee" onChange={handleCheckBoxChange} />} label="បន្ថែមថ្លៃកេសដាក់អីវ៉ាន់" />

                                            </FormGroup>
                                            <FilledInput
                                                onChange={handleFeeCharge}
                                                onKeyDown={handleNumberKeyPress}
                                                value={parcelBoxFee}
                                                type="number"
                                                name="inputParcelBoxFee"
                                                id="inputParcelBoxFee" disabled={!isAddedParcelBox}
                                                startAdornment={<InputAdornment position="start">៛</InputAdornment>}
                                                sx={{
                                                    "&  .MuiFilledInput-input": {
                                                        fontSize: "22px",
                                                        textAlign: "center",
                                                        maxHeight: "9px",
                                                        pt: "16px",
                                                        pb: "15px",
                                                    },
                                                    "input[type = number]": {
                                                        MozAppearance: "textfield"
                                                    },
                                                    "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                                                        WebkitAppearance: "none",
                                                        margin: 0
                                                    }
                                                }}
                                            />
                                        </FormControl>

                                        <FormGroup sx={{ mt: "10px", gridColumn: "span 2" }}>
                                            <FormControlLabel control={<Checkbox name="chkCashOnDelievery" onChange={handleCheckBoxChange} value={isCashOnDelievery} />} label="គិតប្រាក់ពេលទទួលអីវ៉ាន់" />
                                        </FormGroup>

                                        {/* <TextField
                                            fullWidth
                                            sx={{ gridColumn: "span 2" }}
                                            variant="filled"
                                            type="number"
                                            label="តម្លៃផ្ញើ"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onKeyDown={handleNumberKeyPress}
                                            value={values.totalFeeRiel}
                                            name="parcelAmount"
                                            error={!!touched.totalFeeRiel && !!errors.totalFeeRiel}
                                            helperText={touched.totalFeeRiel && errors.totalFeeRiel}

                                        /> */}

                                        <FormControl fullWidth variant="standard" sx={{ m: "10px 0px 0px 0px", gridColumn: "span 2" }}>
                                            <InputLabel htmlFor="labelParcelFee" sx={{ fontSize: "18px" }}>តម្លៃផ្ញើ</InputLabel>
                                            <Input
                                                readOnly={false}
                                                onKeyDown={handleNumberKeyPress}
                                                onChange={handleFeeCharge}
                                                onFocus={handleParcelFeeFocus}
                                                value={parcelFee}
                                                type="number"
                                                name="inputParcelFee"
                                                id="parcelFee"

                                                sx={{
                                                    "& .MuiInput-underline": {
                                                        color: "red"
                                                    },

                                                    "& 	.Mui-focused": {
                                                        color: "red"
                                                    },
                                                    "input[type = number]": {
                                                        MozAppearance: "textfield",
                                                        color: colors.greenAccent[400],
                                                        fontSize: "32px",
                                                        fontWeight: "bold",
                                                    },
                                                    "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                                                        WebkitAppearance: "none",
                                                        margin: 0
                                                    }
                                                }}
                                                startAdornment={<InputAdornment position="start"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            fontSize: "22px"
                                                        }
                                                    }}>៛</InputAdornment>}
                                            />
                                            {error && (
                                                <Typography mt={1} variant="caption" sx={{ color: "#d32f2f" }}>
                                                    {error}
                                                </Typography>
                                            )}
                                        </FormControl>

                                        <FormControl fullWidth variant="standard" sx={{ mt: "10px", gridColumn: "span 2" }}>
                                            <InputLabel htmlFor="labelTotalFee" sx={{ fontSize: "18px" }} >សរុបប្រាក់ត្រូវបង់</InputLabel>
                                            <Input readOnly={true} disabled={true}
                                                sx={{
                                                    fontSize: "32px",
                                                    fontWeight: "bold",
                                                    color: colors.greenAccent[400],
                                                    "& .MuiInput-input.Mui-disabled": {
                                                        WebkitTextFillColor: `${colors.redAccent[500]} !important`,
                                                    }
                                                }}
                                                value={totalFeeRiel}
                                                name="totalFeeRiel"
                                                id="inputTotalFee"
                                                startAdornment={<InputAdornment position="start"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            fontSize: "22px"
                                                        },
                                                    }}>៛</InputAdornment>}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth variant="standard" sx={{ mt: "10px", gridColumn: "span 2" }}>
                                            <InputLabel htmlFor="labelTotalFee" sx={{ fontSize: "18px" }} >សរុបប្រាក់ត្រូវបង់</InputLabel>
                                            <Input readOnly={true} disabled={true}
                                                sx={{
                                                    fontSize: "32px",
                                                    fontWeight: "bold",
                                                    color: colors.greenAccent[400],
                                                    // backgroundColor: "#e4e2e2 !important",
                                                    "& .MuiInput-input.Mui-disabled": {
                                                        // color: `${colors.greenAccent[400]} !important`,
                                                        WebkitTextFillColor: `${colors.redAccent[500]} !important`,
                                                    }
                                                }}
                                                name="totalFeeDollar"
                                                value={totalFeeDollar}
                                                id="inputTotalFee"
                                                startAdornment={<InputAdornment position="start"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            fontSize: "22px"
                                                        },
                                                    }}>$</InputAdornment>}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>




                        <Box display="grid" mt="24px" pb="20px" >
                            <Button type="submit" variant="contained" fullWidth
                                sx={{
                                    backgroundColor: `${colors.greenAccent[400]}`,
                                    p: "14px 10px 14px 10px",
                                }} >
                                <CheckCircleOutline sx={{ mr: "10px" }} />
                                <Typography variant="h5" fontWeight="bold"> រក្សាទុក និង ចេញវិក័យប័ត្រ </Typography>
                            </Button>
                        </Box>


                    </form >

                )}
            </Formik >
            <Box display="grid" mt="24px" pb="20px" >

                {/* Render the receipt button */}
                {/* <button onClick={handleOpenReceipt}>Generate Receipt</button> */}
                {/* handleModalShow */}
                {/* <Button onClick={handleModalShow} variant="contained" fullWidth
                    sx={{
                        backgroundColor: `${colors.greenAccent[400]}`,
                        p: "14px 10px 14px 10px",
                    }} >
                    <CheckCircleOutline sx={{ mr: "10px" }} />
                    <Typography variant="h5" fontWeight="bold">  ចេញវិក័យប័ត្រ </Typography>
                </Button> */}
            </Box>
            <ViewDetailReceipt />
        </Box >
    );
}

export default AddParcel

{/* <MyButton variant="contained" type="submit"
                                color={colors.greenAccent[400]}
                                title="រក្សាទុក និង ចេញវិក័ប័ត្រ"
                                icon={<CheckCircleOutline sx={{ mr: "10px" }} />}
                            >
                            </MyButton> */}