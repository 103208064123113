import React from 'react';

function TimeFormatKh(props) {
    const timeStr = props.time;
    let displayTime;
    let period;

    const hours = parseInt(timeStr.substring(0, 2));
    const minutes = parseInt(timeStr.substring(3, 5));

    if (hours >= 0 && hours < 12) {
        period = 'ព្រឹក';
    } else if (hours >= 12 && hours < 16) {
        period = 'ថ្ងៃ';
    } else if (hours >= 16 && hours < 19) {
        period = 'ល្ងាច';
    } else {
        period = 'យប់';
    }

    let h = hours > 12 ? hours - 12 : hours;
    displayTime = `${h}:${minutes} ${period}`;

    return <div>{displayTime}</div>;
}

export default TimeFormatKh;
