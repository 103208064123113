import { React, useEffect, useState } from "react";
import { Box, useTheme, Modal, Button, Typography, Alert, Collapse, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataBranch, mockDataContacts } from "../../data/mockData";
import IconButton from "@mui/material/IconButton";
import { ClearOutlined, DeleteOutline, EditAttributesOutlined, EditOffOutlined, EditOutlined } from "@mui/icons-material";

import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const Branches = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const Navigate = useNavigate();
    const [branches, setBranch] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});


    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success')

    const handleClickOpen = () => {
        setShowConfirm(true);
    };

    const handleClose = () => {
        setShowConfirm(false);
    };

    const showConfirmDelete = row => {
        setSelectedRow(row);
        setShowConfirm(true);
    }

    // Handle delete branch
    const handleDeleteBranch = async () => {
        const result = await fetch(window.domain + "/branches/delete", {
            method: "POST",
            body: JSON.stringify({
                id: selectedRow.id,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        });
        result.json().then(body => {
            if (body.status) {
                setAlertSeverity("success")
                setAlertContent(body.message)
                setAlert(true)
                setBranch(prevStaffs => prevStaffs.filter(branches => branches.id !== selectedRow.id));
            } else {
                setAlertSeverity("error")
                setAlertContent(body.message)
                setAlert(true)
                // console.log(body.message)
            }
            setShowConfirm(false)
        });
    };

    const handleEdit = row => {
        Navigate('/branches/edit-branch', { state: { item: row } });
    }

    useEffect(() => {
        getBranchList()
        if (alert) {
            setTimeout(() => {
                setAlert(false);
            }, 3000)
        }
    }, [alert])

    const getBranchList = async () => {
        try {
            const result = await fetch(window.domain + "/branches", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            result.json().then(body => {
                setBranch(body);
            });
        } catch (error) {

        };

    }


    const columns = [
        { field: "id", headerName: "ល.រ", width: 60 },
        {
            field: "name",
            headerName: "ឈ្មោះសាខា",
            cellClassName: "name-column--cell",
            width: 200,

        },
        { field: "home", headerName: "ផ្ទះ", width: 80 },
        { field: "street", headerName: "វិថី/ផ្លូវ", width: 120 },
        { field: "city", headerName: "ខេត្ត/ក្រុង", width: 120 },
        { field: "contact1", headerName: "លេខទំនាក់ទំនង ទី១", width: 120 },
        { field: "contact2", headerName: "លេខទំនាក់ទំនង ទី២", width: 120 },
        {
            field: "action", headerName: "លុប/កែសម្រួល", width: 140,
            renderCell: ({ row }) => {
                return (
                    <Box display="Flex" justifyContent="space-between" alignItems="center" key={row.id}>
                        <IconButton color="primary" aria-label="Edit" component="label" onClick={() => handleEdit(row)}
                            sx={{
                                backgroundColor: colors.buttonBG1[200],
                                color: colors.buttonText1[100],
                                m: "0px 4px 0px 4px"
                            }}
                        >
                            <EditOutlined />
                        </IconButton>

                        <IconButton color="primary" onClick={() => showConfirmDelete(row)} aria-label="Delete" component="label"
                            sx={{
                                backgroundColor: colors.buttonBG1[300],
                                color: colors.buttonText1[100], m: "0px 4px 0px 4px"
                            }}
                        >
                            <ClearOutlined />
                        </IconButton>
                    </Box >);
            },
        }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        height: "99%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',

    };


    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="សាខាក្រុមហ៊ុន" subtitle="ពិនិត្យនិងកែសម្រួលពត៌មានសាខា" />

            </Box>
            <Collapse in={alert}>
                <Alert severity={alertSeverity} action={alertSeverity === "success" ? <CircularProgress sx={{ p: "4px", color: colors.greenAccent[800] }} /> : undefined} > {alertContent} </Alert>
            </Collapse>
            <Box
                m="8px 0 0 0"
                width="100%"
                height="80vh"
                sx={{

                    "& .MuiDataGrid-cell:focus": {
                        outline: "solid #256970 0px;",
                    },

                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "solid #256970 0px;",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[800],
                        color: "white"
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={branches}
                    columns={columns}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                />
            </Box>
            <Box>
                <Dialog
                    open={showConfirm}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        zIndex: 10000
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"ផ្ទៀងផ្ទាត់"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            តើអ្នកពិតជាចង់លប់សាខាក្រុមហ៊ុនឈ្មោះ ' {selectedRow.name} ' នេះមែនទេ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={handleDeleteBranch} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default Branches;
