import { React, useEffect, useState } from "react";
import { Box, useTheme, Typography, TextField, Divider, IconButton, useMediaQuery, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import dayjs from 'dayjs';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { formatCurrency } from "../../helper/Helper";
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from '@fortawesome/free-solid-svg-icons';

const SearchAll = () => {
    const theme = useTheme();
    const location = useLocation();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:820px)");
    const [parcels, setParcels] = useState([]);
    const navigate = useNavigate()
    // const [trackListTemplate, setTrackListTemplate] = useState([]);

    const searchParcelByPhone = async () => {
        try {
            const result = await fetch(window.domain + "/search/all", {
                method: "POST",
                body: JSON.stringify({
                    reference_number: location.state.item,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            result.json().then(body => {
                setParcels(body)
                // if (body.status) {
                //     setTrackList(body.track_list);
                // } else {
                //     setActiveStep(0);
                // }
            });
        } catch (error) {

        };
    }

    // const handleChange = (e) => {
    //     setSearchText(e.target.value);
    // }


    useEffect(() => {
        searchParcelByPhone();
    }, [location.state.item]);

    // const myColumns = [
    //     { field: "id", headerName: "Id", width: 60, valueFormatter: ({ value }) => value.toLocaleString('en-US', { minimumIntegerDigits: 2 }) },
    //     { field: "parcel_cateogry", headerName: "ប្រភេទបញ្ញើ", width: 140 },
    //     { field: "unit", headerName: "ចំនួន", width: 80, type: 'number' },
    //     { field: "parcel_unit", headerName: "ឯកតា", width: 80 },
    //     // { field: "total_fee", headerName: "តំលៃសេវា", width: 160, ...khPrice },
    //     {
    //         field: "total_fee", headerName: "តំលៃសេវា", width: 100, type: "number", cellClassName: 'name-column--cell',
    //         renderCell: ({ row }) => {
    //             return (
    //                 <Typography fontSize="16px">
    //                     ៛ {formatCurrency(parseFloat(row.total_fee))}
    //                 </Typography>
    //             )
    //         }
    //     },
    //     { field: "from_branch_name", headerName: "សាខាផ្ញើ", width: 180 },
    //     { field: "to_branch_name", headerName: "សាខាទទួល", width: 180 },
    //     { field: "parcel_status", headerName: "ស្ថានភាពបញ្ញើ", width: 180 },
    //     {
    //         field: "date_created", headerName: "កាលបរិច្ឆេទផ្ញើ", width: 180,
    //         renderCell: ({ row }) => {
    //             return (
    //                 <Typography variant="title">
    //                     {dayjs(row.date_created).format("DD/MM/YYYY HH:MM")}
    //                 </Typography>
    //             )
    //         }
    //     },
    // ];

    // const ParcelGrid = () => {
    //     // console.log("parcel:" + parcels[0].reference_number)
    //     if (parcels.length <= 0) return (<Box />)


    //     return (
    //         <DataGrid rows={parcels} columns={myColumns} sx={{ fontSize: "16px", height: "80vh" }}
    //             initialState={{
    //                 sorting: {
    //                     sortModel: [{ field: 'id', sort: 'asc' }],
    //                 },
    //             }}
    //         />
    //     );
    // }

    const handleItemClick = (e) => {
        var searchRef = ""
        console.log(e.target.tagName + "  " + e.target.innerHTML)
        const regex = /^([0-9]{2}-){2}.*$/;
        if (e.target.tagName === "LI") {
            for (let i = 0; i < e.target.attributes.length; i++) {
                let ref = e.target.attributes[i].value
                if (ref.length > 0) {
                    if (regex.test(ref)) {
                        navigate('/search', { state: { item: ref } });
                    }
                }
            }
            searchRef = e.target.attributes[2]
            if (searchRef.length > 0) {
                navigate('/search', { state: { item: searchRef } });
            }
        } else if (e.target.tagName === "svg" || e.target.tagName === "DIV") {
            searchRef = e.target.id
            if (searchRef.length > 0) {
                navigate('/search', { state: { item: searchRef } });
            }
        } else if (e.target.tagName === "SPAN") {
            if (regex.test(e.target.innerHTML)) {
                navigate('/search', { state: { item: e.target.innerHTML } });
            }
        }
    }


    const ListResult = () => {
        return (

            // <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
            //     <Box
            //         backgroundColor={colors.primary[400]}
            //         maxHeight="740px"
            //         overflow="auto"
            //         // m="25px 0 0 0"
            //         borderRadius={2}
            //     // // backgroundColor={colors.primary[400]}
            //     // sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}
            //     >
            //         <Box
            //             backgroundColor={colors.primary[400]}
            //             position="sticky"
            //             top="0px"
            //             alignSelf="flex-start"
            //             display="flex"
            //             justifyContent="space-between"
            //             alignItems="center"
            //             color={colors.grey[100]}

            //             p="15px"
            //         >

            //         </Box>

            //         {parcels.map((item, i) => {
            //             return (
            //                 <Box
            //                     key={`${item}-${i}`}
            //                     display="flex"
            //                     justifyContent="space-between"
            //                     alignItems="center"
            //                     borderBottom={`1px solid #8080801a`}
            //                     p="15px"
            //                 >
            //                     <Box>
            //                         <Box>
            //                             លេខតាមដាន:
            //                             <Typography
            //                                 display="inline"
            //                                 variant="h6"
            //                                 fontWeight="600"
            //                                 color={colors.greenAccent[100]}
            //                             >
            //                                 {/* secondary={`ទទួលនៅសាខា: ${value.to_branch_name}`} */}
            //                                 {` ${item.reference_number}`}
            //                             </Typography>
            //                         </Box>
            //                         <Box>
            //                             កាលបរិច្ឆេទ:
            //                             <Typography display="inline"
            //                                 variant="h6"
            //                                 fontWeight="600"
            //                                 color={colors.greenAccent[100]}
            //                             >
            //                                 {` ${dayjs(item.date_created).format("DD/MM/YYYY HH:mm")}`}
            //                             </Typography>
            //                         </Box>
            //                         <Box>
            //                             ស្ថានភាពបញ្ញើ:
            //                             <Typography display="inline"
            //                                 variant="h6"
            //                                 fontWeight="600"
            //                                 color={colors.greenAccent[100]}
            //                             >
            //                                 {` ${item.parcel_status}`}
            //                             </Typography>
            //                         </Box>

            //                     </Box>
            //                     <Box
            //                         p="5px 10px"
            //                         borderRadius="4px"
            //                     >
            //                         <Box>
            //                             លេខអ្នកផ្ញើ:
            //                             <Typography display="inline"
            //                                 variant="h6"
            //                                 fontWeight="600"
            //                                 color={colors.greenAccent[100]}
            //                             >
            //                                 {` ${item.sender_contact}`}
            //                             </Typography>
            //                         </Box>
            //                         <Box>
            //                             ផ្ញើពីសាខា:
            //                             <Typography display="inline"
            //                                 variant="h6"
            //                                 fontWeight="600"
            //                                 color={colors.greenAccent[100]}
            //                             >
            //                                 {` ${item.from_branch_name}`}
            //                             </Typography>
            //                         </Box>
            //                     </Box>
            //                     <Box color={colors.grey[100]}>
            //                         <Box>
            //                             លេខអ្នកទទួល:
            //                             <Typography display="inline"
            //                                 variant="h6"
            //                                 fontWeight="600"
            //                                 color={colors.greenAccent[100]}
            //                             >
            //                                 {` ${item.recipient_contact}`}
            //                             </Typography>
            //                         </Box>
            //                         <Box>
            //                             ទទួលនៅសាខា:
            //                             <Typography display="inline"
            //                                 variant="h6"
            //                                 fontWeight="600"
            //                                 color={colors.greenAccent[100]}
            //                             >
            //                                 {` ${item.to_branch_name}`}
            //                             </Typography>
            //                         </Box>
            //                         {/* <Typography color={colors.grey[100]}>
            //                         លេខអ្នកទទួល:  {item.recipient_contact}
            //                     </Typography>
            //                         ទទួលនៅសាខា: {item.to_branch_name} */}
            //                     </Box>
            //                 </Box>

            //             );
            //         })}
            //     </Box>
            // </Grid>

            <List sx={{
                width: '100%', pl: isNonMobile ? "20px" : "0px",

                // selected and (selected + hover) states
                '& .Mui-selected, && .Mui-selected:hover': {
                    color: `${colors.greenAccent[400]} !important`,
                    backgroundColor: `${colors.hoverBG[100]} !important`

                },
                // hover states
                '& .MuiListItem-root:hover': {
                    color: `${colors.greenAccent[400]} !important`,
                    backgroundColor: `${colors.hoverBG[100]} !important`
                },
            }}>
                {parcels.map((value) => (
                    <ListItem value={value.reference_number} onClick={handleItemClick} key={value.id} style={{
                        borderBottom: "solid 1px #e6e0e0", padding: isNonMobile ? "24px" : "6px",

                    }}>

                        <ListItemAvatar id={value.reference_number}
                            sx={{
                                display: isNonMobile ? "block" : "none",
                            }}>
                            <Avatar id={value.reference_number}>
                                <FontAwesomeIcon icon={faBoxes} id={value.reference_number} />

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ alignSelf: "baseline" }} primary={
                            <Box > លេខតាមដាន: <Typography
                                sx={{ display: 'inline', textDecoration: "underline", cursor: "pointer" }}
                                component="span"
                                variant="body"
                                color={colors.greenAccent[400]}
                                fontWeight="bold"

                                onClick={handleItemClick}
                            >
                                {value.reference_number}
                            </Typography></Box>}
                            secondary={<Box> <Typography>កាលបរិច្ឆេទ: {dayjs(value.date_created).format("DD/MM/YYYY HH:mm")} </Typography><Typography variant="title"> ស្ថានភាព: {value.parcel_status}</Typography></Box>}
                        />
                        <ListItemText style={{ alignSelf: "baseline" }} primary={
                            <Box> លេខអ្នកផ្ញើ: <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body"
                                color={colors.primary}
                                fontWeight="bold"
                            >
                                {value.sender_contact}
                            </Typography></Box>}
                            secondary={`ផ្ញើពីសាខា: ${value.from_branch_name}`}
                        />

                        <ListItemText style={{ alignSelf: "baseline" }} primary={
                            <Box> លេខអ្នកទទួល: <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body"
                                color={colors.primary}
                                fontWeight="bold"
                            >
                                {value.recipient_contact}
                            </Typography></Box>}
                            secondary={`ទទួលនៅសាខា: ${value.to_branch_name}`}
                        />

                    </ListItem>

                ))}
            </List>
        )
    }


    return (
        <Box m="20px" >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="លទ្ធផលស្វែងរកទាំងអស់" subtitle="តាមដានស្ថានភាព និង ទីតាំងបញ្ញើ" />
            </Box>
            <Divider />
            <Box m={isNonMobile ? "24px 24px 24px 24px" : "18px 0px 12px 0px"} p={isNonMobile ? "24px 20px 24px 40px" : "0px 0px 0px 0px"} borderRadius={2}
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                alignContent="baseline"
                sx={{
                    display: "grid",
                    backgroundColor: colors.primary[400],
                    boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                    height: "65vh",
                    overflowY: "auto",
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                }}><Typography variant="h4" style={{ padding: "24px 20px 24px 4px", fontWeight: "bold" }}>
                    បញ្ញើបានរកឃើញចំនួន {parcels.length}
                </Typography>
                <Divider />
                <ListResult />
            </Box>


            {/* <Box m={3} p="24px 20px 24px 0px" borderRadius={2}
                sx={{
                    display: activeStep < 0 ? "none" : "block",
                    backgroundColor: colors.primary[400],
                    boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)"
                }}>
                {activeStep > 0 ?
                    <Box display="flex" alignItems="center" justifyContent="center" >
                        <Stepper activeStep={activeStep} orientation="vertical"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                "& .MuiStepLabel-label": {
                                    fontSize: "16px"
                                }
                            }}>
                            {trackList.map((step, index) => (
                                <Step key={step.id}>
                                    <Box width="100%" bgcolor="red" height="20%"> </Box>
                                    <StepLabel
                                        optional={
                                            <Box>
                                                <Typography variant="h6"> {step.status_description}</Typography>
                                                <Typography sx={{ fontSize: "12px", mt: "4px" }}> <AccessTime fontSize="12px" sx={{ mb: "-2px", mr: "4px" }} /> {dayjs(step.date_created).format('DD/MM/YYYY HH:mm')}</Typography>
                                            </Box>
                                        }
                                    >
                                        {step.status_name}
                                    </StepLabel>
                                    <StepContent>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    : <Box textAlign="center">  <CustomNoRowsOverlay /></Box>}
            </Box> */}


        </Box >
    );

}

export default SearchAll

