import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, Divider, ThemeProvider, Box } from "@mui/material";
import { Routes, Route, useNavigate } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Topbar from "./pages/global/Topbar";
import Dashboard from "./pages/dashboard/Dashboard";
import StaffList from "./pages/staff/StaffList";
import Invoices from "./pages/invoices/invoice";
import Pie from "./pages/pie/piechart";
import AddBranch from "./pages/branch/AddBranch";
import Branches from "./pages/branch/BranchList";
import AddStaff from "./pages/staff/AddStaff";
import EditStaff from "./pages/staff/EditStaff";
import EditBranch from "./pages/branch/EditBranch";
import AddParcel from "./pages/parcel/AddParcel";
import ParcelList from "./pages/parcel/ParcelList";
import EditParcel from "./pages/parcel/EditParcel";
import ModalForm from "./components/Modal";
import SearchParcel from "./pages/search/SearchParcel";
import ReportDelievery from "./pages/report/ReportDelievery";
import ReportTicket from "./pages/report/ReportTicket";
import DeliveryReceipt from "./components/DelieveryReceipt";
import ProtectedRoute from "./components/ProtectRoute";
import { React, useState, useEffect } from "react";
import LogIn from "./pages/authentication/Login";
import { Login } from "@mui/icons-material";
import SearchAll from "./pages/search/SearchAll";
import SellTicket from "./pages/transit/SellTicket";
import TransitTicketList from "./pages/transit/TransitTicketList";
import EditTicket from "./pages/transit/EditTicket";

// function ProtectedRoutes({ user }) {
//   return (
//     <Route element={<ProtectedRoute user={user} />}>
//       <Route path="/branches" element={<Branches />} />
//       <Route path="/report" element={<ReportPage />} />
//       <Route path="/" element={<Dashboard />} />
//       <Route path="/staffs" element={<StaffList />} />
//       <Route path="/staffs/add-staff" element={<AddStaff />} />
//       <Route path="/staffs/edit-staff" element={<EditStaff />} />
//       <Route path="/branches" element={<Branches />} />
//       <Route path="/branches/add-branch" element={<AddBranch />} />
//       <Route path="/branches/edit-branch" element={<EditBranch />} />
//       <Route path="/parcels" element={<ParcelList />} />
//       <Route path="/parcels/add-parcel" element={<AddParcel />} />
//       <Route path="/parcels/parcel-list" element={<ParcelList filter={0} />} />
//       <Route path="/parcels/accepted" element={<ParcelList filter={1} />} />
//       <Route path="/parcels/transit" element={<ParcelList filter={2} />} />
//       <Route path="/parcels/arrived" element={<ParcelList filter={3} />} />
//       <Route path="/parcels/delivered" element={<ParcelList filter={4} />} />
//       <Route path="/parcels/failed" element={<ParcelList filter={5} />} />
//       <Route path="/parcels/edit-parcel" element={<EditParcel />} />
//       <Route path="/search" element={<SearchParcel />} />
//       <Route path="/report" element={<ReportPage />} />
//       <Route path="/receipt" element={<DeliveryReceipt />} />
//     </Route>
//   );
// }

const App = () => {
  const [theme, colorMode] = useMode();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [user, setUser] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setUser(loggedInUser)
      // setUser(JSON.parse(loggedInUser));
    } else {
      // <Navigate to="/login" replace />
    }
    // setUser(null);
    // console.log("user:" + user)
  }, []);

  if (!user) {
    return (<Box width="100%" height="100%">
      <LogIn />
    </Box>
    );
  }


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MyProSidebarProvider>
          <div style={{ height: "100%", width: "100%" }}>
            <main>
              <Topbar />
              <Routes>
                {/* <Route element={<ProtectedRoute user={user} />}> */}
                <Route path="/transits/" element={<TransitTicketList />} />
                <Route path="/branches" element={<Branches />} />
                <Route path="/report" element={<ReportDelievery />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/staffs" element={<StaffList />} />
                <Route path="/staffs/add-staff" element={<AddStaff />} />
                <Route path="/staffs/edit-staff" element={<EditStaff />} />
                <Route path="/branches" element={<Branches />} />
                <Route path="/branches/add-branch" element={<AddBranch />} />
                <Route path="/branches/edit-branch" element={<EditBranch />} />
                <Route path="/parcels" element={<ParcelList />} />
                <Route path="/parcels/add-parcel" element={<AddParcel />} />
                <Route path="/parcels/parcel-list" element={<ParcelList filter={0} />} />
                <Route path="/parcels/accepted" element={<ParcelList filter={1} />} />
                <Route path="/parcels/transit" element={<ParcelList filter={2} />} />
                <Route path="/parcels/arrived" element={<ParcelList filter={3} />} />
                <Route path="/parcels/delivered" element={<ParcelList filter={4} />} />
                <Route path="/parcels/failed" element={<ParcelList filter={5} />} />
                <Route path="/parcels/edit-parcel" element={<EditParcel />} />
                <Route path="/search" element={<SearchParcel />} />
                <Route path="/search/all" element={<SearchAll />} />
                <Route path="/report/delievery" element={<ReportDelievery />} />
                <Route path="/report/ticket" element={<ReportTicket />} />
                <Route path="/receipt" element={<DeliveryReceipt />} />
                <Route path="/pie" element={<DeliveryReceipt />} />
                <Route path="/transit/sell-ticket" element={<SellTicket />} />
                <Route path="/transit/ticket-list" element={<TransitTicketList />} />
                <Route path="/transit/edit-ticket" element={<EditTicket />} />
                <Route path="/*" element={<ProtectedRoute />} />
                {/* </Route> */}


                {/* <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/staffs" element={<StaffList />} />
                <Route path="/staffs/add-staff" element={<AddStaff />} />
                <Route path="/staffs/edit-staff" element={<EditStaff />} />
                <Route path="/branches" element={<Branches />} />
                <Route path="/branches/add-branch" element={<AddBranch />} />
                <Route path="/branches/edit-branch" element={<EditBranch />} />
                <Route path="/parcels" element={<ParcelList />} />
                <Route path="/parcels/add-parcel" element={<AddParcel />} />
                <Route path="/parcels/parcel-list" element={<ParcelList filter={0} />} />
                <Route path="/parcels/accepted" element={<ParcelList filter={1} />} />
                <Route path="/parcels/transit" element={<ParcelList filter={2} />} />
                <Route path="/parcels/arrived" element={<ParcelList filter={3} />} />
                <Route path="/parcels/delivered" element={<ParcelList filter={4} />} />
                <Route path="/parcels/failed" element={<ParcelList filter={5} />} />
                <Route path="/parcels/edit-parcel" element={<EditParcel />} />
                <Route path="/search" element={<SearchParcel />} />
                <Route path="/report" element={<ReportPage />} />
                <Route path="/receipt" element={<DeliveryReceipt />} /> */}
                {/* <Route path="/invoice" element={<Invoices />} />
                <Route path="/calendar" element={<Calendar />} /> */}
                {/* <Route path="/login" element={<LogIn />} /> */}
                {/* <PrivateRoute path='/' component={Dashboard} isLoggedIn={isLoggedIn} /> */}
              </Routes>
            </main>
          </div>
        </MyProSidebarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider >
  );
};

export default App;
