import { Box, useTheme, Typography, TextField, Divider, IconButton, useMediaQuery, Button, Alert } from "@mui/material";
import dayjs from "dayjs";
import { React, useState, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import Barcode from "react-barcode";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import '../print.css';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'; import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { formatCurrency, formatDate, formatPhoneNumber, zeroPad } from "../helper/Helper";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

const TransitReceipt = ({ transaction, branchList }) => {
    const isNonMobile = useMediaQuery("(min-width:820px)");
    const [createDateTime, setCreateDateTime] = useState('');
    const [createDate, setCreateDate] = useState('');
    const [senderBranchName, setSenderBranchName] = useState('');
    const [senderBranchContact, setSenderBranchContact] = useState('');
    const [senderBranchAddress, setSenderBranchAddress] = useState('');
    const [receipientBranchName, setReceipientBranchName] = useState('');
    const [receipientBranchContact, setReceipientBranchContact] = useState('');
    const [receipientBranchAddress, setReceipientBranchAddress] = useState('');


    const getBranchInfo = () => {
        branchList.map((e) => {
            if (e.id === transaction.from_branch_id) {
                setSenderBranchName(e.name);
                setSenderBranchContact(e.contact1)
                setSenderBranchAddress("ផ្ទះលេខ " + e.home + " ផ្លូវ" + e.street + " " + e.address1);
            }

            if (e.id === transaction.to_branch_id) {
                setReceipientBranchName(e.name);
                setReceipientBranchContact(e.contact1);
                setReceipientBranchAddress(e.address1);
            }
        });
    }

    useEffect(() => {
        // categoryName();
        // unitName();
        getBranchInfo();
        const current = new Date();

        setCreateDateTime(dayjs(current).format("DD/MM/YYYY HH:mm"));
        setCreateDate(formatDate(current));
    }, [createDate, createDateTime]);


    // const waterMarkStyle = {
    //     transform: [{ rotate: '-2deg' }]
    // }

    function TimeFormatKh(props) {
        const timeStr = props.time;

        let displayTime;
        let period;

        // Parse hours and minutes
        const hours = parseInt(timeStr.substring(0, 2));
        const minutes = parseInt(timeStr.substring(3, 5));

        if (hours >= 0 && hours < 12) {
            period = 'ព្រឹក';
        } else if (hours >= 12 && hours < 16) {
            period = 'ថ្ងៃ';
        } else if (hours >= 16 && hours < 19) {
            period = 'ល្ងាច';
        } else {
            period = 'យប់';
        }
        let h = hours > 12 ? (hours - 12) : hours

        displayTime = `${h}:${minutes} ${period}`;

        return <div>{displayTime}</div>;
    }

    return (
        <Box className="transit-receipt" sx={{
            "@media screen": {
                // position: "absolute",
                // left: "40%",
                // width: "100mm",
                // height: "120mm",
                margin: `${isNonMobile ? "0px 35% 0px 35%" : "0px"}`,
                backgroundColor: 'background.paper',
            }
        }}>

            <Box
                display="grid"
                // backgroundColor="red"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                    // "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
            >

                <Box sx={{ gridColumn: "span 4", transform: "rotate(-4deg)" }}>
                    <Typography className="trans-t-watermark" variant="h6" textAlign="left" color="orange" mr="10px" > SET EXPRESS</Typography>
                </Box>

                <Box display="flex" sx={{
                    gridColumn: "span 4",
                    // "& > div": { gridColumn: isNonMobile ? undefined : "span 2" }, 
                }} >
                    <Box sx={{ gridColumn: "span 1", pt: "0.1rem" }}>
                        <img className="logo"
                            id="transit-car-logo"
                            alt="logo"
                            height="50px"
                            src={`../../assets/car-logo-black-1.png`}
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                    <Typography className="trans-main-title" variant="h4" textAlign="center" fontWeight='bold' mr="10px"  > SONTEPHEAB EXPRESS AND LOGISTIC</Typography>


                </Box>

                <Divider className="divder" sx={{ gridColumn: "span 4", pt: "0.0rem", pb: "0.0rem", borderStyle: "dashed", borderColor: "black" }} />

                <Box sx={{
                    gridColumn: "span 4",
                    mt: "0.3rem",
                    mb: "0.1rem"
                }} >
                    <Typography className="trans-subtitle" textAlign="center" > <HomeOutlinedIcon className="trans-icon" sx={{ position: 'relative', top: '4px', width: "18px", height: "18px" }} /> {senderBranchAddress}  </Typography>
                    <Typography className="trans-subtitle" textAlign="center" ><CallOutlinedIcon className="trans-icon" sx={{ position: 'relative', top: '2px', width: "14px", height: "14px" }} /> {formatPhoneNumber(senderBranchContact)}  <MailOutlineOutlinedIcon className="trans-icon" sx={{ position: 'relative', top: '3px', width: "14px", height: "14px" }} /> info@sontepheabexpress.com</Typography>

                    {/* <Typography className="trans-subtitle" textAlign="center" > {senderBranchAddress}  </Typography>
                    <Typography className="trans-subtitle" textAlign="center" > <CallOutlinedIcon className="trasn-icon" sx={{ position: 'relative', top: '4px', width: "16px", height: "16px" }} /> {formatPhoneNumber(senderBranchContact)} <MailOutlineOutlinedIcon className="trasn-icon" sx={{ position: 'relative', top: '4px', width: "16px", height: "16px" }} />  info@sontepheabexpress.com</Typography> */}

                </Box>

                <Divider className="divder" sx={{ gridColumn: "span 4", pt: "0.2rem", pb: "0.2rem", borderStyle: "dashed", borderColor: "black" }} />

                <Box sx={{
                    gridColumn: "span 4",
                    mt: "0.2rem",
                }} >
                    <Typography className="trans-main-title" variant="h4" textAlign="center" fontWeight="bold">  INVOICE </Typography>
                </Box>

                <Box className="tableBox" sx={{ gridColumn: "span 4", pt: "0.4rem" }}>
                    <TableContainer component={Paper} sx={{ borderRadius: "0px", boxShadow: "none" }}>
                        <Table size="small"
                            sx={{
                                maxWidth: "100%",
                                "& .MuiTableCell-root": {
                                    padding: "6px",
                                    borderRight: "1px",
                                    border: "1px solid black",
                                    overflow: "hidden",
                                    borderRadius: "0px",
                                    boxShadow: "0px",
                                }

                            }}  >
                            <TableHead>
                                <TableRow className="trans-table-row"  >
                                    <TableCell className="trans-table-row-title" align="center" colSpan={2} sx={{ fontWeight: "bold" }}>Customer Information</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="20%" sx={{ fontWeight: "bold" }}>Seat No. </TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}}>{zeroPad(transaction.seat_no, 2)} </TableCell>
                                    {/* <TableCell className="trans-cell-right" width="24%" align="center" sx={{}}>{transaction.seat_no.zeroPad(2, '0')} </TableCell> */}
                                </TableRow>

                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Phone Number </TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} >{formatPhoneNumber(transaction.customer_contact)}</TableCell>
                                </TableRow>
                            </TableBody>

                            <TableHead>
                                <TableRow className="trans-table-row"  >
                                    <TableCell className="trans-table-row-title" align="center" colSpan={2} sx={{ fontWeight: "bold" }}>Departure Information</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="20%" sx={{ fontWeight: "bold" }}>Invoice No </TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}}>{transaction.invoice_id} </TableCell>
                                </TableRow>

                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Issue Date/Time</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} >{createDateTime}</TableCell>
                                </TableRow>

                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Issue By:</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} >{localStorage.getItem("user")}</TableCell>
                                </TableRow >

                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Departure Date</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} >{dayjs(transaction.departure_date).format("DD/MM/YYYY")}</TableCell>
                                </TableRow>
                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Departure Time</TableCell>
                                    {/* <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} >{dayjs(transaction.departure_time).format("HH:mm")}</TableCell> */}
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} ><TimeFormatKh time={dayjs(transaction.departure_time).format("HH:mm")} /></TableCell>

                                </TableRow>

                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Destination</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} > {senderBranchName} - {receipientBranchName}</TableCell>
                                </TableRow>

                            </TableBody>
                            <TableHead>
                                <TableRow className="trans-table-row" >
                                    <TableCell className="trans-table-row-title" align="center" colSpan={2} sx={{ fontWeight: "bold" }}>Payment Information</TableCell>
                                </TableRow>

                            </TableHead>
                            <TableBody>
                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Ticket Price</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} > {transaction.ticket_currency} {formatCurrency(transaction.ticket_price)} </TableCell>
                                </TableRow>
                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Discount</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} >  {transaction.discount_currency} {formatCurrency(transaction.totalDiscount)} </TableCell>
                                </TableRow >
                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Grand Total Riel</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} > ៛ {formatCurrency(transaction.total_fee)}</TableCell>
                                </TableRow>
                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Grand Total Dollar</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} > $ {formatCurrency(transaction.total_fee_dollar)}</TableCell>
                                </TableRow>
                                <TableRow className="trans-table-row">
                                    <TableCell className="trans-cell-left" width="25%" sx={{ fontWeight: "bold" }}>Exchange Rate</TableCell>
                                    <TableCell className="trans-cell-right" width="24%" align="center" sx={{}} >  ៛ {formatCurrency(transaction.exchange_rate)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>


            </Box >

        </Box >

    );
};

export default TransitReceipt;
