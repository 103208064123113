/* eslint-disable react/jsx-no-duplicate-props */
import { React, useState, useEffect } from "react";
import { Box, Button, Divider, TextField, Typography, Input, InputAdornment, OutlinedInput, Checkbox, FormControlLabel, FormGroup, FilledInput, IconButton, CircularProgress } from '@mui/material';
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { CheckCircleOutline, ClearOutlined, HighlightOff, Timer } from "@mui/icons-material";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TransitReceipt from "../../components/TransitReceipt";

import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Modal from "@mui/material/Modal";

import '../../ticket.css';
import { formatCurrency } from "../../helper/Helper";
import DeviceDetector from "../../helper/DeviceDetector";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';


// import MobileTimePicker from '@mui/x-date-pickers/MobileTimePicker;
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

const SellTicket = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isNonMobile = useMediaQuery("(min-width:880px)");
    const summarySize = useMediaQuery("(min-width:1120px)");
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const isNumberRex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
    const [branchList, setBranchList] = useState([]);
    const [unit, setUnit] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [CreateTicketStatus, setTicketCreateStatus] = useState(false);


    const [totalFeeRiel, setTotalFeeRiel] = useState(0.0);
    const [totalFeeDollar, setTotalFeeDollar] = useState(0.0);
    const [ticketFee, setTicketFee] = useState(0.0);
    const [totalDiscount, setTotalDiscount] = useState(0.0);
    const [exchageRate, setExchangeRate] = useState(4000);
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState("");
    const [ticketFeeFocused, setTicketFeeFocused] = useState(false);
    const [ticketCurrency, setTicketFeeCurrency] = useState("៛");
    const [discountCurrency, setDiscountCurrency] = useState("៛");
    const [agencyCurrency, setAgencyCurrency] = useState("៛");
    const [agencyFee, setAgencyFee] = useState(0.0);
    const [transactionInfo, setTransactionInfo] = useState({});
    const [deviceType, setDeviceType] = useState(false);

    const [departureDate, setDepartureDate] = useState(dayjs().format('YYYY/MM/DD'));
    const [departureTime, setDepartureTime] = useState(dayjs());

    const [customerType, setCustomerType] = useState(0);
    const [customerTypeList, setCustomerTypeList] = useState([]);
    const [isDisableAgency, setDisableAgency] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    // const [departureLocation, setDeparture] = useState();
    // const [destinationLocation, setDestination] = useState();

    // const [endDate, setEndDate] = useState(dayjs().format('YYYY/MM/DD'));

    // const handleCustomerTypeChange = event => {
    //     console.log(event.target.value)
    //     setCustomerType(prevValues => ({

    //         ...prevValues,

    //         // we use the name to tell Formik which key of `values` to update

    //         [event.target.name]: event.target.value

    //     })
    //     );

    // }


    // Submit and print the receipt
    const handlePrintTicket = async () => {
        if (isLoading === true) return null;
        var totalFee = transactionInfo.ticket_currency === "$" ? transactionInfo.total_fee_dollar : transactionInfo.total_fee
        try {
            const info = {
                invoice_id: transactionInfo.invoice_id,
                customer_type: transactionInfo.customer_type,
                customer_contact: transactionInfo.customer_contact,
                origin: transactionInfo.from_branch_id,
                destination: transactionInfo.to_branch_id,
                departure_date: dayjs(transactionInfo.departure_date).format("YY/MM/DD"),
                departure_time: dayjs(transactionInfo.departure_time).format("HH:mm"),
                seat_no: transactionInfo.seat_no,
                ticket_currency: transactionInfo.ticket_currency,
                ticket_price: transactionInfo.ticket_price,
                discount_currency: transactionInfo.discount_currency,
                agency_currency: transactionInfo.agency_currency,
                agency_fee: transactionInfo.agency_fee,
                discount: transactionInfo.totalDiscount,
                total: totalFee,
                created_by: transactionInfo.created_by
            }
            const api = window.domain + "/transit/create"

            const result = await fetch(api, {
                method: "POST",
                body: JSON.stringify(info),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            setIsLoading(true);
            await new Promise(resolve => setTimeout(resolve, 1000));

            result.json().then(body => {
                setIsLoading(false);
                if (body.status) {
                    printReceipt();
                    if (isNonMobile) {
                        handleModalClose();
                        setTicketCreateStatus(true);
                        setOpenDialog(true);
                    } else {

                        // handleModalClose();
                        // alert("is mobile")
                        // console.log("is mobile")
                    }

                } else {
                    setTicketCreateStatus(false);
                    setOpenDialog(true);
                }
            });

        } catch (error) {
            // console.error('Error while creating ticket', error);
            setTicketCreateStatus(false);
            setOpenDialog(true);
        }
    }

    function printReceipt() {

        window.print();

    }



    const printStyle = {
        '@media print': {
            'page': {
                size: "portrait",
                width: "80mm !important",
                height: "120mm !important",
                padding: "0px",
                margin: "0px",


                // },
                // width: "80mm !important",
                // height: "100mm !important",
                // bgcolor: "none",
                // boxShadow: "none",
                // padding: "0px",
                // margin: "0px",
                // border: "0px",
                // left: '0%',
                '& .hide-on-print': {
                    display: "none"
                },
                // '& .info-box-sub': {
                //     fontWeight: "bold"
            }
        },
        // top: '0px',
        // left: `${deviceType === "Mobile" ? "0%" : "40%"}`,
        // transform: 'translate(-50%, -50%)',
        // width: `${deviceType === "Mobile" ? "100%" : "88mm"}`,
        // padding: `${deviceType === "Mobile" ? "0px" : "0px 40% 0px 40%"}`,
        // bgcolor: 'background.paper',
        // border: '2px solid #000',
        width: "100%",
        height: "100%",
        position: 'absolute',
        boxShadow: 24,
        // top: '2%',
        overflowY: 'scroll'

    };

    const handleModalClose = () => {
        setModalShow(false);
    }

    const handleModalShow = () => {
        setModalShow(true);
    }


    const ViewDetailReceipt = () => {

        return (
            <Box>
                <DeviceDetector />
                <Modal
                    sx={{ zIndex: 10000 }}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalShow}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modalShow}>
                        <Box sx={printStyle}>
                            <TransitReceipt
                                transaction={transactionInfo}
                                branchList={branchList}
                            // categories={category}
                            // units={unit}
                            />
                            <Box className="hide-on-print" display="flex" backgroundColor="background.paper" gap="10px" sx={{
                                width: `${isNonMobile ? "400px" : "100%"}`,
                                gridColumn: "span 4", pt: "4px", mb: "10px", pb: "10px", pr: "10px", margin: `${isNonMobile ? "0px 35% 0px 35%" : "0px"}`, justifyContent: "end",
                            }} >
                                <Button className="hide-on-print" onClick={handleModalClose} sx={{ mt: "15px", border: "solid 1px", height: "26px", width: "100px" }} >ផ្អាក</Button>
                                <SubmitButton text={"ចេញវិក័យប័ត្រ"} loading={isLoading} disabled={isLoading} />
                                {/* <Button className="hide-on-print" onClick={handlePrintTicket} sx={{ mt: "15px", border: "solid 1px", height: "26px", width: "100px" }} > ចេញវិក័យប័ត្រ </Button> */}
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Box >
        );
    }


    const SubmitButton = ({ onSubmit, text, loading, disabled }) => {
        return (
            <Box>
                <Button className="submit-btn hide-on-print" disabled={disabled} onClick={handlePrintTicket}
                    sx={{
                        mt: "15px", border: "solid 1px", height: "26px", width: "100px",
                        '& .submit-btn:disabled': {
                            background: "#e7e8e9",
                            color: "#9fa3a9",
                            cursor: "not-allowed"
                        },
                    }}

                >

                    {text}
                </Button>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    }



    const handleReload = () => {
        if (window.debug === true) {
            return null
        }

        window.location.reload()

    };

    const handleClose = () => {
        if (CreateTicketStatus) {
            handleReload()
        }

        setOpenDialog(false);
        setTicketCreateStatus(false);
    };

    const AlertDialog = () => {
        return (
            <Box backgroundColor="red">
                <Dialog fullWidth height="100px"
                    sx={{
                        zIndex: 10000,
                    }}
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ paddingBottom: "0px" }}>
                        <Box display="flex" justifyContent="space-between" >
                            <Typography variant="h4" fontWeight="bold" paddingBottom={2}>{"ជូនដំណឹង"} </Typography>
                            <Button
                                onClick={handleClose}
                                disableElevation={true}
                                disableRipple={true}
                                disableFocusRipple={true}
                                sx={{ minWidth: "0px", marginTop: "-20px", marginRight: "-15px", ":hover": { backgroundColor: "transparent" } }}
                            > <ClearOutlined /> </Button>
                        </Box>
                    </DialogTitle>
                    <Divider />
                    <DialogContent >
                        {CreateTicketStatus === true ?
                            <DialogContentText id="alert-dialog-description"
                                sx={{
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    fontSize: "18px"
                                }}>
                                <CheckCircleOutline sx={{ color: colors.greenAccent[400] }} /> ការបញ្ចូលសំបុត្រថ្មីបានជោគជ័យ!
                            </DialogContentText>
                            : <DialogContentText id="alert-dialog-description"
                                sx={{
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    fontSize: "18px"
                                }}>
                                <HighlightOff sx={{ color: "red" }} /> មិនអាចបង្កើតសំបុត្រថ្មីបានទេ!
                            </DialogContentText>}
                    </DialogContent>
                    <Divider />


                    <DialogActions>
                        <Button onClick={handleClose} autoFocus sx={{
                            fontSize: "18px",
                            backgroundColor: `${colors.greenAccent[400]} `,
                            color: "white",
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginRight: "10px",
                            minWidth: "120px",

                            ":hover": {
                                backgroundColor: `${colors.greenAccent[300]} `,
                            }
                        }}>
                            បិទ
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }



    const branches = branchList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );

    const customerTypes = customerTypeList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );




    const getBranchList = async () => {
        try {
            const result = await fetch(window.domain + "/branches", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")} `
                },
            });
            result.json().then(body => {
                setBranchList(body);
            });
        } catch (error) {

        };
    }

    const getUnit = async () => {
        try {
            const result = await fetch(window.domain + "/parcels/unit", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")} `
                },
            });
            result.json().then(body => {
                setUnit(body.unit);
                setExchangeRate(body.rate[0].price);
            });
        } catch (error) {

        };
    }

    const getCustomerType = async () => {
        try {
            const result = await fetch(window.domain + "/customer/type", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")} `
                },
            });
            result.json().then(body => {
                setCustomerTypeList(body);
            });
        } catch (error) {

        };
    }

    // const units = unit.map((b) =>
    // <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    // );




    useEffect(() => {
        if (branches.length <= 0) { getBranchList() };
        if (customerTypeList.length <= 0) { getCustomerType() };
        // if (categories.length <= 0) { getCategory() };
        if (unit.length <= 0) { getUnit() };
        calculateTotalFee()


        // const handleAfterPrint = () => {
        //     // This function will be called after the window.print() is completed
        //     // You can close the form or perform any other action here
        //     alert('Printing completed');
        //     // Example: Close the form
        // };

        // // Add the 'afterprint' event listener
        // window.addEventListener('afterprint', handleAfterPrint);
        // // Cleanup the event listener when the component unmounts
        // return () => {
        //     window.removeEventListener('afterprint', handleAfterPrint);
        // };


    }, [branches.length, ticketFee, totalDiscount, unit.length, ticketCurrency, discountCurrency])

    // function formatCurrency(value) {
    //     return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // }

    const calculateTotalFee = () => {
        var totalDollar = 0.0
        var totalRiel = 0.0

        // totalRiel = parseFloat(ticketFee) - totalDiscount
        // const totalDollar = totalRiel / exchageRate;

        if (ticketCurrency === "$") {
            if (discountCurrency === "$") {
                totalDollar = ticketFee - totalDiscount
                totalRiel = totalDollar * exchageRate
            } else if (discountCurrency === "៛") {
                totalDollar = ticketFee - (totalDiscount / exchageRate)
                totalRiel = totalDollar * exchageRate
            }
        } else if (ticketCurrency === "៛") {
            if (discountCurrency === "$") {
                totalRiel = ticketFee - (totalDiscount * exchageRate)
                totalDollar = totalRiel * exchageRate
            } else if (discountCurrency === "៛") {
                totalRiel = ticketFee - totalDiscount
                totalDollar = totalRiel / exchageRate
            }
        }

        // setTotalFeeRiel(totalRiel > 0 ? formatCurrency(totalRiel) : 0);
        // setTotalFeeDollar(totalDollar > 0 ? formatCurrency(totalDollar) : 0);

        setTotalFeeRiel(formatCurrency(totalRiel))
        setTotalFeeDollar(formatCurrency(totalDollar))
    }

    const handleCustomerTypeChange = (e) => {
        const val = e.target.value;
        setCustomerType(val)
        if (val === 0) {
            setAgencyFee(0)
            setDisableAgency(true)
        } else if (val === 1) {
            setAgencyFee(8000)
            setDisableAgency(false)
        }

    }

    const handleFeeCharge = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        if (name === "inputTicketFee") {
            setTicketFee(val);
        } else if (name === "inputDiscountFee") {
            setTotalDiscount(val);
        } else if (name === "inputAgencyFee") {
            setAgencyFee(val)
        }
    }

    // const handleDiscount = (e) => {
    //     const name = e.target.name;
    //     const val = e.target.value;
    //     if (name === "inputDiscountFee") {
    //         setTicketFee(val);
    //     } else if (name === "inputDiscountFee") {
    //         setTotalDiscount(val);
    //     }
    // }

    const handleTicketFeeFocus = () => {
        setTicketFeeFocused(true);
    }

    const validateUserSubmit = (e) => {
        // const errors = {};
        if (ticketFee <= 0 && ticketFeeFocused) {
            // errors.name = "ពិនិត្យមើលតំលៃឡើងវិញ"
            setError("ពិនិត្យមើលតំលៃឡើងវិញ");
        } else {
            setError("");
        }
        // return errors;
    }

    function validateSeatNumber(value) {

        let error;

        if (value > 0 && value <= 24) {

            error = '';

        } else {
            error = "Invalid Seat Number!"
        }

        return error;

    }

    const handleNumberKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[0-9\b.,]+$/;
        const value = event.target.value;

        if (event.target.name === "inputTicketFee") {
            if (value > 0) setError("");
        }


        // if (event.target.name === "inputTicketSeat") {
        //     if (value > 0) setError("");
        // }

        if (parseFloat(value) < 0) {
            event.preventDefault();
            return
        }

        // if (value.charAt(0) === '0' && keyCode !== 8 && keyCode !== 37 && keyCode !== 39) {
        //     event.preventDefault();
        //     return
        // }

        // Allow only 1 dot in value
        if (keyCode === 190 && value.includes(".") === false) {
            return
        }

        // Allow select all
        if ((event.ctrlKey || event.metaKey) && keyCode == 65) {
            return
        }

        // Allow left & right and tab
        if (keyCode === 37 || keyCode === 39 || keyCode === 9) {
            return
        }

        // Prevent any input beside number
        if (!regex.test(keyValue)) {
            event.preventDefault();
        }

    };


    const initialValues = {
        // Delivery Information 
        departureDate: "", //dayjs().format('YYYY/MM/DD'),
        departureTime: "",
        senderBranch: "",
        recipientBranch: "",
        customerContact: "",
        customerType: 0,
        ticketPriceCurrency: "៛",
        ticketFee: 0,
        ticketSeat: "",
        discountCurrency: "៛",
        discount: 0,
        totalFeeDollar: 0,
        totalFeeRiel: 0
    };

    const checkoutSchema = yup.object().shape({
        customerContact: yup.string().min(9, "លេខទូរស័ព្ទត្រូវចាប់ពី 09 ខ្ទង់ឡើង").matches(phoneRegExp, "លេខទូរស័ព្ទមិនត្រឹមត្រូវ").required("Required"),
        ticketSeat: yup.string().min(1).max(2, "លេខកៅអីធំបំផុតត្រឹម 2 តួលេខ").matches(isNumberRex, "សូមបញ្ចូលជាតួលេខ").required("Required"),

        // departureDate: yup.date().required("Required"),
        // departureTime: yup.date().required("Required"),
        senderBranch: yup.string().required("Required"),
        recipientBranch: yup.string().required("Required"),
        // ticketPriceCurrency: yup.string().required("Required"),
        // ticketFee: yup.number().required("សូមបញ្ចូលជាតួលេខ").positive().min(1, "តម្លៃទំនិញត្រូវតែធំជាងសូន្យ")

    })



    const handleFormSubmit = async (values) => {

        setDeviceType(localStorage.getItem("DeviceType"))

        if (error !== "") {
            return null
        }

        const ref_number = values.senderBranch + "-" + values.recipientBranch + "-" + Date.now().toString();
        const total = totalFeeRiel.toString().includes(',') ? totalFeeRiel.replace(/,/g, '') : totalFeeRiel;

        const transaction_info = {
            invoice_id: ref_number,
            from_branch_id: values.senderBranch,
            to_branch_id: values.recipientBranch,
            departure_date: departureDate,
            departure_time: departureTime,
            ticket_currency: ticketCurrency,
            ticket_price: ticketFee,
            seat_no: values.ticketSeat,
            discount_currency: discountCurrency,
            agency_currency: agencyCurrency,
            agency_fee: agencyFee,
            totalDiscount: totalDiscount, //parseFloat(totalDiscount.replace(/,/g, '')),
            total_fee: parseFloat(total),
            total_fee_dollar: parseFloat(totalFeeDollar),
            exchange_rate: exchageRate,
            customer_type: customerType,
            customer_contact: values.customerContact,
            created_by: localStorage.getItem("user")
        }
        console.log(transaction_info)

        setTransactionInfo(transaction_info);
        handleModalShow();
    };



    return (
        <Box m="20px" sx={{
            // "& .MuiInput-input": {
            // textAlign: "center"
            // },
        }}>
            <AlertDialog />
            <Header title="លក់សំបុត្រធ្វើដំណើរ" subtitle="បំពេញពត៌មានការធ្វើដំណើរខាងក្រោម" />
            {/* //validate={validateUserSubmit} */}
            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema} validate={validateUserSubmit} >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>

                        <Box
                            display="grid"
                            gap="20px"
                            gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
                            }}
                        >
                            {/* Sender & Reciepient Info  */}
                            <Box sx={{ gridColumn: "span 2", pb: "0px" }} >
                                <Box
                                    borderRadius={2}
                                    backgroundColor={colors.primary[400]}
                                    border={4}
                                    borderLeft={0}
                                    borderRight={0}
                                    borderBottom={0}
                                    borderColor={colors.greenAccent[600]}

                                    sx={{
                                        boxShadow: "2px 4px 10px 2px rgba(0,0,0,0.1)",
                                        p: "0px 0px 20px 0px",
                                        gridColumn: "span 2",
                                    }} >
                                    <Typography variant='h4' fontWeight="bold" sx={{ m: "20px 20px 10px 20px" }} color={colors.grey[100]}>
                                        ពត៌មានការធ្វើដំណើរ
                                    </Typography>
                                    <Divider sx={{ m: "10px 20px 10px 20px " }} />
                                    <Box sx={{ m: "24px 20px 20px 20px ", pb: "32px" }}>
                                        {/* <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center"> */}
                                        <Box display="flex" gap="10px" alignItems="center">

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    // label="ថ្ងៃចេញដំណើរ"
                                                    // slotProps={{ textField: { fullWidth: true } }}
                                                    // maxHeight="36px"
                                                    value={departureDate}
                                                    inputFormat="DD/MM/YYYY"
                                                    // onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onChange={(newVal) => {
                                                        setDepartureDate(dayjs(newVal).format('YYYY/MM/DD'));
                                                    }}

                                                    PopperProps={{
                                                        placement: "bottom-start",
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                        fullWidth
                                                        variant="filled"
                                                        label="ថ្ងៃចេញដំណើរ"
                                                        multiline={true}
                                                        rows={1.5}
                                                        sx={{ p: "2px 0px 0px 0px " }}
                                                    />
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </Box>

                                        <Box sx={{ m: "10px 0px 0px 0px " }} gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center">

                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DesktopTimePicker views={['hours', 'minutes']}
                                                    value={departureTime}
                                                    name="departureTime"
                                                    onChange={(newVal) => {
                                                        setDepartureTime(dayjs(newVal));
                                                        // console.log("time: " + departureTime)
                                                    }}
                                                    PopperProps={{
                                                        placement: "bottom-start",
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                        fullWidth
                                                        variant="filled"
                                                        label="ម៉ោងចេញដំណើរ"
                                                        multiline={true}
                                                        rows={1.5}
                                                        sx={{ p: "2px 0px 0px 0px " }}
                                                    />
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </Box>


                                        <FormControl variant="filled" fullWidth sx={{ m: "10px 0px 0px 0px" }} >
                                            <InputLabel sx={{ p: "2px 0px 2px 0px " }} id="sender-branch-select-label" >ចេញដំណើរពី</InputLabel>
                                            <Select

                                                labelId="sender-branch-select-label"
                                                name="senderBranch"
                                                value={values.senderBranch}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!touched.senderBranch && !!errors.senderBranch}
                                            // helperText={touched.senderBranch && errors.senderBranch}
                                            >
                                                {branches}
                                            </Select>
                                            {!!touched.senderBranch && !!errors.senderBranch &&
                                                <div className="input-feedback" style={{
                                                    fontSize: 9,
                                                    color: "#d32f2f",
                                                    margin: "3px 14px 0px 14px"
                                                }}>
                                                    {errors.senderBranch}
                                                </div>}
                                        </FormControl>

                                        <FormControl variant="filled" fullWidth sx={{ m: "10px 0px 0px 0px" }}>
                                            <InputLabel sx={{ p: "2px 0px 2px 0px " }} id="sender-branch-select-label" >ទៅកាន់គោលដៅ</InputLabel>
                                            <Select

                                                labelId="sender-branch-select-label"
                                                name="recipientBranch"
                                                value={values.recipientBranch}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!touched.recipientBranch && !!errors.recipientBranch}
                                            // helperText={touched.senderBranch && errors.senderBranch}
                                            >
                                                {branches}
                                            </Select>
                                            {!!touched.recipientBranch && !!errors.recipientBranch &&
                                                <div className="input-feedback" style={{
                                                    fontSize: 9,
                                                    color: "#d32f2f",
                                                    margin: "3px 14px 0px 14px"
                                                }}>
                                                    {errors.recipientBranch}
                                                </div>}
                                        </FormControl>

                                        <TextField
                                            sx={{ m: "10px 0px 0px 0px" }}
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="លេខកៅអី"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.ticketSeat}
                                            name="ticketSeat"
                                            error={!!touched.ticketSeat && !!errors.ticketSeat}
                                            helperText={touched.ticketSeat && errors.ticketSeat}
                                        />

                                        {/* <Box display="grid" gridColumn={isNonMobile ? "span 1" : "span 7"}> */}
                                        <FormControl variant="filled" fullWidth sx={{ m: "10px 0px 0px 0px" }}>
                                            <InputLabel sx={{ p: "2px 0px 2px 0px " }} id="input-customer-type-select-label" >ប្រភេទអតិថិជន</InputLabel>
                                            <Select
                                                labelId="customer-type-select-label"
                                                name="customerType"
                                                value={customerType}
                                                // value={values.customerType}
                                                // onBlur={e => {
                                                //     // call the built-in handleBur
                                                //     // handleBlur(e)
                                                //     // and do something about e
                                                //     let someValue = e.currentTarget.value
                                                //     console.log(someValue)
                                                // }}
                                                // onChange={handleChange}
                                                // onBlur={handleBlur}
                                                onChange={handleCustomerTypeChange}

                                            // onBlur={handleBlur}
                                            // error={!!customerType && !!customerType}
                                            // helperText={touched.senderBranch && errors.senderBranch}
                                            >
                                                {/* <MenuItem key={0} value={0}>{"ធម្មតា"}</MenuItem>
                                                <MenuItem key={1} value={1}>{"ភ្នាក់ងារ"}</MenuItem> */}
                                                {customerTypes}
                                            </Select>
                                            {/* {!!customerType && !!customerType &&
                                                <div className="input-feedback" style={{
                                                    fontSize: 9,
                                                    color: "#d32f2f",
                                                    margin: "3px 14px 0px 14px"
                                                }}>
                                                    {customerType}
                                                </div>} */}
                                        </FormControl>

                                        <TextField
                                            sx={{ m: "10px 0px 0px 0px" }}
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="លេខទំនាក់ទំនង"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.customerContact}
                                            name="customerContact"
                                            error={!!touched.customerContact && !!errors.customerContact}
                                            helperText={touched.customerContact && errors.customerContact}
                                        />

                                        {/* </Box> */}

                                        {/* <Box display="grid" pt="26px" pb="0px" >
                                            <Button type="submit" variant="contained" fullWidth
                                                sx={{
                                                    backgroundColor: `${colors.greenAccent[400]} `,
                                                    p: "14px 10px 14px 10px",
                                                }} >
                                                <CheckCircleOutline sx={{ mr: "10px" }} />
                                                <Typography variant="h5" fontWeight="bold"> រក្សាទុក និង ចេញវិក័យប័ត្រ </Typography>
                                            </Button>
                                        </Box> */}

                                    </Box>



                                </Box>



                            </Box>

                            {/* *************************************/
                            /*        Summary & Calulate Price      */
                            /* **************************************/}
                            <Box sx={{ gridColumn: "span 1", boxShadow: "2px 4px 10px 2px rgba(0,0,0,0.1)", }}
                                borderRadius={2}
                                backgroundColor={colors.primary[400]}
                                border={4}
                                borderLeft={0}
                                borderRight={0}
                                borderBottom={0}
                                borderColor={colors.blueAccent[200]}

                            >

                                <Box sx={{ p: "0px 0px 40px 0px" }} >
                                    <Typography variant='h4' fontWeight="bold" sx={{ m: "20px 0px 10px 20px" }} color={colors.grey[100]}>
                                        សង្ខេប និង ទូទាត់ថ្លៃសំបុត្រ
                                    </Typography>
                                    <Divider sx={{ m: "10px 20px 10px 20px " }} />
                                    <Box m="0px 0px 0px 0px"
                                        p="0px 30px 0px 30px"
                                        display="grid"
                                        gap="10px"
                                        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: summarySize ? undefined : "span 3" },
                                        }} >

                                        <Box display="flex" sx={{ gridColumn: "span 2", mt: "14px", "& .MuiFilledInput-root": { borderTopRightRadius: "0px" } }}>
                                            <FormControl variant="standard" sx={{ width: "40%", pr: "0px", "& .MuiFilledInput-root": { borderTopLeftRadius: "0px !important" } }} >
                                                <InputLabel sx={{ fontSize: "18px" }} id="currency-label" >តម្លៃសំបុត្រ</InputLabel>
                                                <Select
                                                    sx={{
                                                        fontSize: "26px", pt: "2.5px", textAlign: "left", "& .MuiInput-input": {
                                                            // textAlign: "left"
                                                        }
                                                    }}
                                                    labelId="CurrencyLabel"
                                                    name="ticketCurrency"
                                                    value={ticketCurrency || '៛'}
                                                    // onBlur={handleBlur}
                                                    onChange={(newVal) => {
                                                        // console.log(newVal.target.value)
                                                        setTicketFeeCurrency(newVal.target.value)
                                                    }}
                                                    // onChange={handleChange}
                                                    defaultValue={ticketCurrency}
                                                // textAlign="left"

                                                >
                                                    <MenuItem key={0} value="$">$</MenuItem>
                                                    <MenuItem key={1} value="៛">៛</MenuItem>

                                                </Select>
                                            </FormControl>

                                            <FormControl fullWidth variant="standard" sx={{ m: "10px 0px 0px 0px", gridColumn: "span 2" }}>

                                                <Input
                                                    readOnly={false}
                                                    onKeyDown={handleNumberKeyPress}
                                                    onChange={handleFeeCharge}
                                                    onFocus={handleTicketFeeFocus}
                                                    value={(ticketFee)}
                                                    type="number"
                                                    name="inputTicketFee"
                                                    id="ticketFee"
                                                    sx={{
                                                        "& .MuiInput-underline": {
                                                            color: "red"
                                                        },

                                                        "& 	.Mui-focused": {
                                                            color: "red"
                                                        },
                                                        "input[type = number]": {
                                                            MozAppearance: "textfield",
                                                            color: colors.greenAccent[400],
                                                            fontSize: "32px",
                                                            fontWeight: "bold",
                                                            textAlign: "right"
                                                        },
                                                        "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                                                            WebkitAppearance: "none",
                                                            margin: 0
                                                        }
                                                    }}
                                                />
                                                {error && (
                                                    <Typography mt={1} variant="caption" sx={{ color: "#d32f2f" }}>
                                                        {error}
                                                    </Typography>
                                                )}
                                            </FormControl>

                                        </Box>

                                        <Box display="flex" sx={{ gridColumn: "span 2", "& .MuiFilledInput-root": { borderTopRightRadius: "0px" } }}>
                                            <FormControl variant="standard" sx={{ width: "40%", pr: "0px", "& .MuiFilledInput-root": { borderTopLeftRadius: "0px !important" } }} >
                                                <InputLabel sx={{ fontSize: "18px" }} id="currency-label" >បញ្ចុះតម្លៃ</InputLabel>
                                                <Select
                                                    sx={{
                                                        fontSize: "26px", pt: "2.5px", "& .MuiInput-input": {
                                                            // textAlign: "left"
                                                        }
                                                    }}
                                                    labelId="CurrencyLabel"
                                                    name="discountCurrency"
                                                    value={discountCurrency || '៛'}
                                                    onBlur={handleBlur}
                                                    // onChange={handleChange}
                                                    onChange={(newVal) => {

                                                        setDiscountCurrency(newVal.target.value)
                                                    }}
                                                    defaultValue={discountCurrency}
                                                // textAlign="left"

                                                >
                                                    <MenuItem key={0} value="$">$</MenuItem>
                                                    <MenuItem key={1} value="៛">៛</MenuItem>

                                                </Select>
                                            </FormControl>



                                            <FormControl fullWidth variant="standard" sx={{ m: "10px 0px 0px 0px", gridColumn: "span 2" }}>

                                                <Input
                                                    readOnly={false}
                                                    onKeyDown={handleNumberKeyPress}
                                                    onChange={handleFeeCharge}

                                                    // onFocus={handleTicketFeeFocus}
                                                    value={(totalDiscount)}
                                                    type="number"
                                                    name="inputDiscountFee"
                                                    id="discountFee"

                                                    sx={{
                                                        "& .MuiInput-underline": {
                                                            color: "red"
                                                        },

                                                        "& 	.Mui-focused": {
                                                            color: "red"
                                                        },
                                                        "input[type = number]": {
                                                            MozAppearance: "textfield",
                                                            color: colors.greenAccent[400],
                                                            fontSize: "32px",
                                                            fontWeight: "bold",
                                                            textAlign: "right"
                                                        },
                                                        "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                                                            WebkitAppearance: "none",
                                                            margin: 0
                                                        }
                                                    }}
                                                />
                                                {error && (
                                                    <Typography mt={1} variant="caption" sx={{ color: "#d32f2f" }}>
                                                        {error}
                                                    </Typography>
                                                )}
                                            </FormControl>

                                        </Box>

                                        <Box display="flex" sx={{ gridColumn: "span 2", "& .MuiFilledInput-root": { borderTopRightRadius: "0px" } }}>
                                            <FormControl variant="standard" sx={{ width: "40%", pr: "0px", "& .MuiFilledInput-root": { borderTopLeftRadius: "0px !important" } }} >
                                                <InputLabel sx={{ fontSize: "18px" }} id="currency-label" >សេវាភ្នាក់ងារ</InputLabel>
                                                <Select
                                                    sx={{
                                                        fontSize: "26px", pt: "2.5px", "& .MuiInput-input": {
                                                            // textAlign: "left"
                                                        }
                                                    }}
                                                    labelId="CurrencyLabel"
                                                    name="agencyCurrency"
                                                    value={agencyCurrency || '៛'}
                                                    // onBlur={handleBlur}
                                                    // onChange={handleChange}
                                                    onChange={(newVal) => {
                                                        // console.log(newVal.target.value)
                                                        setAgencyCurrency(newVal.target.value)
                                                    }}
                                                    defaultValue={agencyCurrency}
                                                // textAlign="left"

                                                >
                                                    <MenuItem key={0} value="$">$</MenuItem>
                                                    <MenuItem key={1} value="៛">៛</MenuItem>

                                                </Select>
                                            </FormControl>



                                            <FormControl fullWidth variant="standard" sx={{ m: "10px 0px 0px 0px", gridColumn: "span 2" }}>

                                                <Input
                                                    readOnly={false}
                                                    onKeyDown={handleNumberKeyPress}
                                                    onChange={handleFeeCharge}
                                                    disabled={isDisableAgency ? true : false}

                                                    // onFocus={handleTicketFeeFocus}
                                                    value={(agencyFee)}
                                                    type="number"
                                                    name="inputAgencyFee"
                                                    id="agencyFee"

                                                    sx={{
                                                        "& .MuiInput-underline": {
                                                            color: "red"
                                                        },

                                                        "& 	.Mui-focused": {
                                                            color: "red"
                                                        },
                                                        "input[type = number]": {
                                                            MozAppearance: "textfield",
                                                            color: colors.greenAccent[400],
                                                            fontSize: "32px",
                                                            fontWeight: "bold",
                                                            textAlign: "right"
                                                        },
                                                        "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                                                            WebkitAppearance: "none",
                                                            margin: 0
                                                        }
                                                    }}
                                                />
                                                {error && (
                                                    <Typography mt={1} variant="caption" sx={{ color: "#d32f2f" }}>
                                                        {error}
                                                    </Typography>
                                                )}
                                            </FormControl>

                                        </Box>


                                        <FormControl fullWidth variant="standard" sx={{ mt: "10px", gridColumn: "span 2" }}>
                                            <InputLabel htmlFor="labelTotalFee" sx={{ fontSize: "18px" }} >សរុបប្រាក់ត្រូវបង់</InputLabel>
                                            <Input readOnly={true} disabled={true}
                                                sx={{
                                                    fontSize: "32px",
                                                    fontWeight: "bold",
                                                    color: colors.greenAccent[400],
                                                    "& .MuiInput-input.Mui-disabled": {
                                                        WebkitTextFillColor: `${colors.redAccent[500]} !important`,
                                                        textAlign: "right"
                                                    }
                                                }}
                                                value={(totalFeeRiel)}
                                                name="totalFeeRiel"
                                                id="inputTotalFee"
                                                startAdornment={<InputAdornment position="start"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            fontSize: "22px"
                                                        },
                                                    }}>៛</InputAdornment>}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth variant="standard" sx={{ mt: "10px", gridColumn: "span 2" }}>
                                            <InputLabel htmlFor="labelTotalFee" sx={{ fontSize: "18px" }} >សរុបប្រាក់ត្រូវបង់</InputLabel>
                                            <Input readOnly={true} disabled={true}
                                                sx={{
                                                    fontSize: "32px",
                                                    fontWeight: "bold",
                                                    color: colors.greenAccent[400],
                                                    // backgroundColor: "#e4e2e2 !important",
                                                    "& .MuiInput-input.Mui-disabled": {
                                                        // color: `${ colors.greenAccent[400] } !important`,
                                                        WebkitTextFillColor: `${colors.redAccent[500]} !important`,
                                                        textAlign: "right"
                                                    }
                                                }}
                                                name="totalFeeDollar"
                                                value={totalFeeDollar}
                                                id="inputTotalFee"
                                                startAdornment={<InputAdornment position="start"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            fontSize: "22px"
                                                        },
                                                    }}>$</InputAdornment>}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                        <Box display="grid" mt="24px" pb="20px" >
                            <Button type="submit" variant="contained" fullWidth
                                sx={{
                                    backgroundColor: `${colors.greenAccent[400]} `,
                                    p: "14px 10px 14px 10px",
                                }} >
                                <CheckCircleOutline sx={{ mr: "10px" }} />
                                <Typography variant="h5" fontWeight="bold"> រក្សាទុក និង ចេញវិក័យប័ត្រ </Typography>
                            </Button>
                        </Box>
                    </form>


                )}
            </Formik >
            <ViewDetailReceipt />
        </Box >
    );
}

export default SellTicket