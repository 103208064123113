import React, { useState, useRef } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useTheme, Box, IconButton, InputBase, Typography, Divider } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useProSidebar } from "react-pro-sidebar";
import AccountMenu from "../../components/ProfilePopUpMenu";
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState("");





  const handleSearch = () => {

    if (searchText.length > 0) {

      navigate('/search/all', { state: { item: searchText } });
    }

  }

  const handleSearchChanged = (e) => {
    setSearchText(e.target.value)
  }

  return (
    <Box className="TopBar" display="flex" justifyContent="space-between" p={2} style={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }} >
      <Box display="flex">
        {broken && !rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}

          p={0.2}
          borderRadius={1}
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" value={searchText} onChange={handleSearchChanged} />
          <IconButton type="button" onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex">
        <Box alignSelf="center" pr="10px">
          <Typography>
            សួស្ដី  {localStorage.getItem("user")}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />

        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (

            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        {/* <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
        {/* <IconButton> */}
        <AccountMenu />
        {/* <PersonOutlinedIcon /> */}
        {/* </IconButton> */}
        {broken && rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box >
  );
};

export default Topbar;
