import { React, useState, useEffect } from "react";
import { Box, Button, TextField, Typography, AlertTitle, Alert, Collapse, CircularProgress } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { mockDataBranch } from "../../data/mockData";
import { CheckCircleOutline } from "@mui/icons-material";
import { LoadingButton } from '@mui/lab';

const AddStaff = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const complexPasswordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    const [branchList, setBranchList] = useState([]);
    const [privilegeList, setPrivilegeList] = useState([]);

    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success')
    const [isSubmitting, setSubmitting] = useState(false);

    const branches = branchList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );

    const privileges = privilegeList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.description}</MenuItem>
    );

    const handleFormSubmit = async (values) => {
        setSubmitting(true);
        try {
            const result = await fetch(window.domain + "/users/create", {
                method: "POST",
                body: JSON.stringify({
                    firstname: values.firstName,
                    lastname: values.lastName,
                    username: values.userName,
                    contact: values.contact,
                    privilege: values.role,
                    branch_id: values.branch,
                    password: values.password
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            result.json().then(body => {
                if (body.status) {
                    setAlertSeverity("success")
                    setAlertContent(body.message)
                    setAlert(true)
                } else {
                    setAlertSeverity("error")
                    setAlertContent(body.message)
                    setAlert(true)
                }

            });
            setSubmitting(false);
        } catch (error) {
            console.error('Error while creating user', error);
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (branchList.length <= 0) getBranchList();
        if (privilegeList.length <= 0) getPrivilegeList();
        if (alert) {
            setTimeout(() => {
                setAlert(false);
                if (alertSeverity === "success") {
                    window.location.replace('/staffs');
                }
            }, 3000)
        }

    }, [alert, privilegeList])

    const getBranchList = async () => {
        try {
            const result = await fetch(window.domain + "/branches", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            result.json().then(body => {
                setBranchList(body);
            });
        } catch (error) {

        };
    }

    const getPrivilegeList = async () => {
        try {
            const result = await fetch(window.domain + "/access", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            console.log(result);
            result.json().then(body => {
                setPrivilegeList(body);
            });
        } catch (error) {

        };
    }


    const initialValues = {
        userName: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        contact: "",
        role: "",
        branch: "",

    };
    const checkoutSchema = yup.object().shape({
        userName: yup.string().min(6).required("Required"),
        firstName: yup.string().required("Required"),
        lastName: yup.string().required("Required"),
        password: yup.string().matches(complexPasswordRegExp, "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character").required("Required"),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'ពាក្យសំងាត់ត្រូវដូចគ្នា'),
        contact: yup.string().matches(phoneRegExp, "លេខទូរស័ព្ទមិនត្រឹមត្រូវ!").required("Required"),
        branch: yup.string().required("Required"),
        role: yup.string().required("Required"),
    })


    return (
        <Box m="20px">
            <Header title="គ្រប់គ្រងបុគ្គលិក" subtitle="បំពេញពត៌មានបុគ្គលិកខាងក្រោម" />
            <Collapse in={alert}>
                <Alert severity={alertSeverity} action={alertSeverity === "success" ? <CircularProgress sx={{ p: "4px", color: colors.greenAccent[800] }} /> : undefined} > {alertContent} </Alert>
            </Collapse>


            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="នាមត្រកូល"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.firstName}
                                name="firstName"
                                error={!!touched.firstName && !!errors.firstName}
                                helperText={touched.firstName && errors.firstName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="នាមខ្លួន"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lastName}
                                name="lastName"
                                error={!!touched.lastName && !!errors.lastName}
                                helperText={touched.lastName && errors.lastName}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="ឈ្មោះគណនី"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.userName}
                                name="userName"
                                error={!!touched.userName && !!errors.userName}
                                helperText={touched.userName && errors.userName}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="ពាក្យសំងាត់"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="ផ្ទៀងផ្ទាត់ពាក្យសំងាត់"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.confirmPassword}
                                name="confirmPassword"
                                error={!!touched.confirmPassword && !!errors.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="លេខទូរស័ព្ទ"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.contact}
                                name="contact"
                                error={!!touched.contact && !!errors.contact}
                                helperText={touched.contact && errors.contact}
                                sx={{ gridColumn: "span 4" }}
                            />

                            <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                                <InputLabel id="role-select-label">តួនាទី</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={values.role}
                                    label="Role"
                                    name="role"
                                    onChange={handleChange}
                                    error={!!touched.role && !!errors.role}
                                // helperText={touched.role && errors.role}
                                >
                                    {privileges}

                                </Select>
                                {!!touched.role && !!errors.role &&
                                    <div className="input-feedback" style={{
                                        fontSize: 9,
                                        color: "#d32f2f",
                                        margin: "3px 14px 0px 14px"
                                    }}>
                                        {errors.role}
                                    </div>}
                            </FormControl>

                            <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                                <InputLabel id="branch-select-label">ធ្វើនៅសាខា</InputLabel>
                                <Select
                                    labelId="branch-select-label"
                                    id="branch-select"
                                    value={values.branch}
                                    label="Branch"
                                    name="branch"
                                    onChange={handleChange}
                                    error={!!touched.branch && !!errors.branch}
                                // helperText={touched.branch && errors.branch}
                                >
                                    {branches}
                                </Select>
                                {!!touched.branch && !!errors.branch &&
                                    <div className="input-feedback" style={{
                                        fontSize: 9,
                                        color: "#d32f2f",
                                        margin: "3px 14px 0px 14px"
                                    }}>
                                        {errors.branch}
                                    </div>}
                            </FormControl>

                        </Box>
                        <Box display="grid" mt="24px" pb="20px" >
                            <LoadingButton type="submit" variant="contained" fullWidth loading={isSubmitting}
                                sx={{
                                    backgroundColor: `${colors.greenAccent[400]}`,
                                    p: "14px 10px 14px 10px",
                                }} >
                                <CheckCircleOutline sx={{ mr: "10px" }} />
                                <Typography variant="h5" fontWeight="bold"> បង្កើតបុគ្គលិកថ្មី </Typography>
                            </LoadingButton>
                        </Box>

                        {/* <Box display="grid" mt="24px" pb="20px"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                            }}>
                            <Button type="submit" variant="contained" fullWidth gridColumn="span 1"
                                sx={{
                                    backgroundColor: `${colors.greenAccent[400]}`,
                                    p: "10px 10px 10px 10px",

                                }} >
                                <CheckCircleOutline sx={{ mr: "10px" }} />
                                <Typography variant="h5" fontWeight="bold"> បង្កើតបុគ្គលិកថ្មី </Typography>
                            </Button>
                        </Box> */}
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default AddStaff