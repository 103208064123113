import { React, useState, useEffect } from "react";
import { Box, Button, Typography, useTheme, Collapse, Alert, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import IconButton from "@mui/material/IconButton";
import { ClearOutlined, DeleteOutline, EditAttributesOutlined, EditOffOutlined, EditOutlined } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const StaffList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Navigate = useNavigate();

  const [staffs, setStaffs] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});


  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success')


  const handleClickOpen = () => {
    setShowConfirm(true);
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const showConfirmDelete = row => {
    setSelectedRow(row);
    setShowConfirm(true);
  }

  // Handle delete staff
  const handleDeleteStaff = async () => {
    const result = await fetch(window.domain + "/users/delete", {
      method: "POST",
      body: JSON.stringify({
        id: selectedRow.id,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    result.json().then(body => {
      if (body.status) {
        setStaffs(prevStaffs => prevStaffs.filter(staffs => staffs.id !== selectedRow.id));
        setAlertSeverity("success")
        setAlertContent(body.message)
        setAlert(true)
      } else {
        setAlertSeverity("error")
        setAlertContent(body.message)
        setAlert(true)
      }
      setShowConfirm(false)
    });
  };

  useEffect(() => {
    getStaffList()
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 35000)
    }
  }, [alert])

  const getStaffList = async () => {
    try {
      const result = await fetch(window.domain + "/users", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });

      result.json().then(body => {
        setStaffs(body);
      });
    } catch (error) {

    };

  }

  // const handleDelete = row => {
  //   setStaffs(prevStaffs => prevStaffs.filter(staff => staff.id !== row.id));
  // };

  const handleEdit = row => {
    Navigate('/staffs/edit-staff', { state: { item: row } });
  }


  const columns = [
    { field: "id", headerName: "ល.រ", width: 80 },
    { field: "username", headerName: "ឈ្មោះគណនី", width: 120, cellClassName: "name-column--cell", },
    {
      field: "firstname",
      headerName: "នាមត្រកូល",
      width: 140,
    },
    {
      field: "lastname",
      headerName: "នាមខ្លួន",
      type: "number",
      headerAlign: "left",
      align: "left",
    },

    { field: "branch_name", headerName: "ធ្វើនៅសាខា", width: 140 },
    { field: "contact", headerName: "លេខទំនាក់ទំនង", width: 140 },
    {
      field: "type",
      headerName: "កំរិតប្រើប្រាស់",
      width: 140,
      renderCell: ({ row: { privilege_id, privilege_name } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              privilege_id === 1
                ? colors.greenAccent[600]
                : colors.greenAccent[800]
            }
            borderRadius="4px"
          >
            {privilege_id === 1 && <AdminPanelSettingsOutlinedIcon />}
            {privilege_id === 2 && <SecurityOutlinedIcon />}
            {privilege_id === 3 && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {privilege_name}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "action", headerName: "កែប្រែ/លុប", width: 140,
      renderCell: ({ row }) => {
        return (
          <Box display="Flex" justifyContent="space-between" alignItems="center" key={row.id}>
            <IconButton color="primary" aria-label="Edit" component="label" onClick={() => handleEdit(row)}
              sx={{
                backgroundColor: colors.buttonBG1[200],
                color: colors.buttonText1[100],
                m: "0px 4px 0px 4px"
              }}
            >
              <EditOutlined />
            </IconButton>

            <IconButton color="primary" onClick={() => showConfirmDelete(row)} aria-label="Delete" component="label"
              sx={{
                backgroundColor: colors.buttonBG1[300],
                color: colors.buttonText1[100], m: "0px 4px 0px 4px"
              }}
            >
              <ClearOutlined />
            </IconButton>
          </Box >);
      },
    }
  ];
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="គ្រប់គ្រងបុគ្គលិក" subtitle="ពិនិត្យនិងកែសម្រួលពត៌មានបុគ្គលិក" />
      </Box>
      <Collapse in={alert}>
        <Alert severity={alertSeverity} action={alertSeverity === "success" ? <CircularProgress sx={{ p: "4px", color: colors.greenAccent[800] }} /> : undefined} > {alertContent} </Alert>
      </Collapse>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{

          "& .MuiDataGrid-cell:focus": {
            outline: "solid #256970 0px;",
          },

          "& .MuiDataGrid-cell:focus-within": {
            outline: "solid #256970 0px;",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[800],
            color: "white"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={staffs} columns={columns} components={{ Toolbar: GridToolbar }} />
      </Box>

      <Box>
        <Dialog
          open={showConfirm}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            zIndex: 10000
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"ផ្ទៀងផ្ទាត់"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              តើអ្នកពិតជាចង់លុបបុគ្គលិក ' {selectedRow.username} ' នេះមែនទេ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleDeleteStaff} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default StaffList;
