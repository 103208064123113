import { createContext, useState, useMemo } from "react";

import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
      logoColor: {
        100: "#6ed9bd;"
      },

      pageBG: {
        100: "#242e41"
      },
      componentBG: {
        100: "#2f3a50"
      },
      hoverBG: {
        100: 'rgba(244, 246, 245, 0.2)'
      },
      hoverColor: {
        100: "#3da58a"
      },
      buttonBG: {
        100: "#256970"
      },

      buttonText: {
        100: "#fff"

      },
      buttonBG1: {
        100: "#d3e1e1",
        200: 'rgba(207, 247, 229, 0.76)',
        300: 'rgba(249, 186, 181, 0.79)'
      },

      buttonText1: {
        100: "#256970"
      },

      buttonHover: {
        100: "#30868e",
        200: "#328872"
      },

      sidebarHover: {
        100: "#d5d8dc",
        200: "#acb0ba",
        300: "#828997",
        400: "#596175",
        500: "#242e41",
        600: "#262e42",
        700: "#1c2331",
        800: "#131721",
        900: "#090c10"
      },

      sidebarBg: {
        100: "#d5d8dc",
        200: "#acb0ba",
        300: "#828997",
        400: "#596175",
        500: "#242e41",
        600: "#262e42",
        700: "#1c2331",
        800: "#131721",
        900: "#090c10"
      },
      grey: {
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#6ed9bd",
        // 500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414"
      },
      primary: {
        100: "#256970",//"#d0d1d5",
        200: "#a1a4ab",
        300: "#727681",
        400: "#1F2A40",
        500: "#141b2d",
        600: "#101624",
        700: "#0c101b",
        800: "#080b12",
        900: "#040509"
      },
      greenAccent: {
        100: "#dbf5ee",
        200: "#b7ebde",
        300: "#94e2cd",
        400: "#70d8bd",
        500: "#4cceac",
        600: "#3da58a",
        700: "#2e7c67",
        800: "#6bbfaa",
        900: "#28bf99"
      },
      redAccent: {
        100: "#f8dcdb",
        200: "#f1b9b7",
        300: "#e99592",
        400: "#e2726e",
        500: "#db4f4a",
        600: "#af3f3b",
        700: "#832f2c",
        800: "#58201e",
        900: "#2c100f"
      },
      blueAccent: {
        100: "#e1e2fe",
        200: "#c3c6fd",
        300: "#a4a9fc",
        400: "#868dfb",
        500: "#6870fa",
        600: "#535ac8",
        700: "#3e4396",
        800: "#2a2d64",
        900: "#2c83e9c7"
      },

      orangeAccent: {
        100: "#ffedd7",
        200: "#ffdaaf",
        300: "#ffc886",
        400: "#ffb55e",
        500: "#ffa336",
        600: "#cc822b",
        700: "#996220",
        800: "#664116",
        900: "#33210b"
      },
    }
    : {

      logoColor: {
        100: "#666666;"
      },
      pageBG: {
        100: "#ebf0f4"
      },

      componentBG: {
        100: "#ffffff"
      },
      hoverBG: {
        100: 'rgba(244, 246, 245, 0.8)'
      },
      hoverColor: {
        100: "#3da58a"

      },
      buttonBG: {
        100: "#256970"
      },

      buttonText: {
        100: "#fff"
      },

      buttonBG1: {
        100: "#d3e1e1",
        200: 'rgba(30, 165, 104, 0.2)',
        300: 'rgba(165, 37, 27, 0.2)'
      },

      buttonText1: {
        100: "#256970"
      },

      buttonHover: {
        100: "#30868e",
        200: "#328872"
      },

      sidebarBg: {
        100: "#fefefe",
        200: "#fdfdfe",
        300: "#fdfdfd",
        400: "#fcfcfd",
        500: "#fbfbfc",
        600: "#c9c9ca",
        700: "#979797",
        800: "#646465",
        900: "#323232"
      },
      grey: {
        100: "#141414",
        200: "#292929",
        300: "#3d3d3d",
        400: "#525252",
        500: "#666666",
        600: "#858585",
        700: "#a3a3a3",
        800: "#c2c2c2",
        900: "#e0e0e0"
      },
      primary: {
        100: "#256970",//"#040509",
        200: "#080b12",
        300: "#0c101b",
        400: "#f2f0f0", // manually changed
        500: "#141b2d",
        600: "#1F2A40",
        700: "#727681",
        800: "#a1a4ab",
        900: "#2c3e50"
      },
      greenAccent: {
        100: "#0f2922",
        200: "#1e5245",
        300: "#2e7c67",
        400: "#3da58a",
        500: "#4c70ce",
        // 500: "#4cceac",
        600: "#70d8bd",
        700: "#94e2cd",
        800: "#339d82",
        900: "#28bf99"
      },

      redAccent: {
        100: "#2c100f",
        200: "#58201e",
        300: "#832f2c",
        400: "#af3f3b",
        500: "#db4f4a",
        600: "#e2726e",
        700: "#e99592",
        800: "#f1b9b7",
        900: "#d58784"
      },
      blueAccent: {
        100: "#151632",
        200: "#297ad9",
        300: "#3e4396",
        400: "#535ac8",
        500: "#6870fa",
        600: "#868dfb",
        700: "#a4a9fc",
        800: "#c3c6fd",
        900: "#2c83e9"
      },
      orangeAccent: {
        100: "#ffedd7",
        200: "#ffdaaf",
        300: "#ffc886",
        400: "#ffb55e",
        500: "#ffa336",
        600: "#cc822b",
        700: "#996220",
        800: "#664116",
        900: "#2c83e9c7"
      },
    })
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            main: colors.primary[500]
          },
          secondary: {
            main: colors.greenAccent[500]
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100]
          },
          background: {
            default: colors.primary[500]
            // default: "#04c8d6"

          }
        }
        : {
          // palette values for light mode
          primary: {
            main: colors.primary[100]
          },
          secondary: {
            main: colors.greenAccent[500]
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100]
          },
          background: {
            default: "#fcfcfc"
          }
        })
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14
      }
    }
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { }
});

export const useMode = () => {
  // const [mode, setMode] = useState("dark");
  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prevMode) => (prevMode === "dark" ? "light" : "dark"))
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
