import { React, useState, useEffect } from 'react'
import { Box, Button, TextField, useTheme, AlertTitle, Alert, Collapse, LinearProgress, CircularProgress } from '@mui/material'
import { tokens } from '../../theme';
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery, Typography } from "@mui/material";
import Header from "../../components/Header";
import MyButton from '../../components/CustomButton';
import { CheckCircleOutline } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const EditBranch = () => {
    const location = useLocation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success')
    const [branch, setBranch] = useState(location.state.item);


    useEffect(() => {
        if (alert) {
            setTimeout(() => {
                setAlert(false);
                if (alertSeverity === "success") {
                    window.location.replace('/branches');
                }
            }, 3000)
        }
    }, [alert]);


    const handleFormSubmit = async (values) => {
        try {
            const result = await fetch(window.domain + "/branches/update", {
                method: "POST",
                body: JSON.stringify({
                    id: branch.id,
                    name: values.name,
                    home: values.home,
                    street: values.street,
                    city: values.city,
                    contact1: values.contact1,
                    contact2: values.contact2,
                    address1: values.address1,
                    address2: values.address2,
                    map: "13.361013390249996, 103.86439708179648"
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            result.json().then(body => {
                if (body.status) {
                    setAlertSeverity("success")
                    setAlertContent(body.message)
                    setAlert(true)
                } else {
                    setAlertSeverity("error")
                    setAlertContent(body.message)
                    setAlert(true)
                }

            });
        } catch (error) {
            console.error('Error while creating branch', error);
        }
    };

    const initialValues = {
        name: branch.name,
        city: branch.city,
        home: branch.home,
        street: branch.street,
        address1: branch.address1,
        address2: branch.address2,
        contact1: branch.contact1,
        contact2: branch.contact2,
    };

    const checkoutSchema = yup.object().shape({
        name: yup.string().required("Required"),
        city: yup.string().required("Required"),
        home: yup.string().required("Required"),
        street: yup.string().required("Required"),
        contact1: yup.string().matches(phoneRegExp, "លេខទូរស័ព្ទមិនត្រឹមត្រូវ").required("Required"),
        contact2: yup.string().matches(phoneRegExp, "លេខទូរស័ព្ទមិនត្រឹមត្រូវ"),
        address1: yup.string().required("Required"),
        address2: yup.string(),

    })

    return (
        <Box m="20px">
            <Header title="សាខាក្រុមហ៊ុន" subtitle="កែសម្រួលពត៌មានសាខាក្រុមហ៊ុន" />

            <Collapse in={alert}>
                <Alert severity={alertSeverity} action={alertSeverity === "success" ? <CircularProgress sx={{ p: "4px", color: colors.greenAccent[800] }} /> : undefined} > {alertContent} </Alert>
            </Collapse>

            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="ឈ្មោះសាខា"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.street && !!errors.street}
                                helperText={touched.street && errors.street}
                                sx={{ gridColumn: "span 4" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="ផ្ទះ ឬ អាគារ"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.home}
                                name="home"
                                error={!!touched.home && !!errors.home}
                                helperText={touched.home && errors.home}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="ផ្លូវ"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.street}
                                name="street"
                                error={!!touched.street && !!errors.street}
                                helperText={touched.street && errors.street}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="ខេត ឬ ក្រុង"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city}
                                name="city"
                                error={!!touched.city && !!errors.city}
                                helperText={touched.city && errors.city}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="លេខទំនាក់ទំនងទី ១"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.contact1}
                                name="contact1"
                                error={!!touched.contact1 && !!errors.contact1}
                                helperText={touched.contact1 && errors.contact1}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="លេខទំនាក់ទំនងទី ២"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.contact2}
                                name="contact2"
                                error={!!touched.contact2 && !!errors.contact2}
                                helperText={touched.contact2 && errors.contact2}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="អាសយដ្ឋានទី ១"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address1}
                                name="address1"
                                error={!!touched.address1 && !!errors.address1}
                                helperText={touched.address1 && errors.address1}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="អាសយដ្ឋានទី ២"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address2}
                                name="address2"
                                error={!!touched.address2 && !!errors.address2}
                                helperText={touched.address2 && errors.address2}
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>
                        <Box display="grid" mt="24px" pb="20px"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                            }}>
                            <Button type="submit" variant="contained" fullWidth
                                sx={{
                                    backgroundColor: `${colors.greenAccent[400]}`,
                                    p: "10px 10px 10px 10px",
                                    gridColumn: "span 1"
                                }} >
                                <CheckCircleOutline sx={{ mr: "10px" }} />
                                <Typography variant="h5" fontWeight="bold"> កែសម្រួលសាខាក្រុមហ៊ុន</Typography>
                            </Button>
                        </Box>
                    </form>
                )}

            </Formik>

        </Box >

    );
}

export default EditBranch