import { React, useState, useEffect } from "react";
import { Box, useTheme, Typography, TextField, Divider, IconButton, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faTruckFast, faFlagCheckered, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, Select, Button, MenuItem } from '@mui/material/';
import dayjs from 'dayjs';
import StatBox from "../../components/StatBox";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";


import { DataGrid } from '@mui/x-data-grid';
import EarningSummaryChart from "../../components/EarningSummaryChart";
import ParcelSummaryChart from "../../components/ParcelSummaryChart";
import { formatCurrency } from "../../helper/Helper";


const ReportDelievery = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1330px)");
    const [parcels, setParcels] = useState([]);
    const [isShowReport, setIsShowReport] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().format('YYYY/MM/DD'));
    const [endDate, setEndDate] = useState(dayjs().format('YYYY/MM/DD'));
    const [branchId, setBranchId] = useState('');
    const [statusId, setStatusId] = useState('');
    const [totalCompletedParcel, setCompletedParcel] = useState(0);
    const [totalInTransit, setInTransitParcel] = useState(0);
    const [totalFailedParcel, setFailedParcel] = useState(0);
    const [totalParcel, setTotalParcel] = useState(0);
    const [topTenBranch, setTopTenBranch] = useState([]);
    const [earningSummary, setEarningSummary] = useState([]);
    const [parcelSummary, setParcelSummary] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [showGrid, setShowGrid] = useState(false);
    const [totalEarn, setTotalEarn] = useState(0);


    useEffect(() => {
        if (branchList.length <= 0) getBranchList();
        if (status.length <= 0) getParcelStatusList()
    }, [])


    const getBranchList = async () => {
        try {
            const result = await fetch(window.domain + "/branches", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            result.json().then(body => {
                setBranchList(body);
            });
        } catch (error) {

        };
    }

    const getParcelStatusList = async () => {
        try {
            const result = await fetch(window.domain + "/parcels/status", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            result.json().then(body => {
                setStatusList(body);

            });
        } catch (error) {

        }

    }


    const branches = branchList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );

    const status = statusList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );


    const handleBranchSelected = (e) => {

        setBranchId(e.target.value);
    }

    const handleStatusChange = (e) => {
        setStatusId(e.target.value);
    };

    const handleQueryReport = () => {
        setIsShowReport(true);
        queryReport();
    }

    const queryReport = async () => {

        try {
            const result = await fetch(window.domain + "/report/delievery", {
                method: "POST",
                body: JSON.stringify({
                    start_date: startDate,
                    end_date: endDate,
                    branch_id: branchId,
                    status: statusId
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            result.json().then(body => {
                setParcels(body.detail_transaction);
                const earn = body.earning_summary;
                var chart1 = [];
                var chart2 = [];
                var total_earn_between_query = 0
                setEarningSummary([])
                setParcelSummary([])
                earn.map((e, i) => {
                    const a = { x: e.date_created, y: e.total }
                    const b = { x: e.date_created, y: e.amount }
                    chart1.push(a);
                    chart2.push(b);
                    total_earn_between_query = total_earn_between_query + e.total
                    if (i === earn.length - 1) {
                        const EarningChart = [
                            { id: "ចំណូល", data: chart1 }
                        ];

                        const ParcelChart = [
                            { id: "បញ្ញើ", data: chart2 }
                        ]

                        setEarningSummary(EarningChart);
                        setParcelSummary(ParcelChart);
                        setTotalEarn(total_earn_between_query);
                    }

                });

                const topSummary = body.top_summary
                topSummary.map((item) => {
                    if (item.status === 1) {
                        setTotalParcel(item.amount);
                    } else if (item.status === 2) {
                        setInTransitParcel(item.amount);
                    } else if (item.status === 3) {

                    } else if (item.status === 4) {
                        setCompletedParcel(item.amount);
                    } else if (item.status === 5) {
                        setFailedParcel(item.amount);
                    }
                });
                setShowGrid(true)

            });
        } catch (error) {

        };
    }


    // const currencyFormatter = new Intl.NumberFormat('km-KH', {
    //     style: 'currency',
    //     currency: 'KHR',
    // });

    // const khPrice = {
    //     type: 'number',
    //     width: 130,
    //     valueFormatter: ({ value }) => currencyFormatter.format(value),
    //     cellClassName: 'name-column--cell',
    // };

    const myColumns = [
        { field: "id", headerName: "Id", width: 60, valueFormatter: ({ value }) => value.toLocaleString('en-US', { minimumIntegerDigits: 2 }) },
        { field: "parcel_cateogry", headerName: "ប្រភេទបញ្ញើ", width: 140 },
        { field: "unit", headerName: "ចំនួន", width: 80, type: 'number' },
        { field: "parcel_unit", headerName: "ឯកតា", width: 80 },
        // { field: "total_fee", headerName: "តំលៃសេវា", width: 160, ...khPrice },
        {
            field: "total_fee", headerName: "តំលៃសេវា", width: 100, type: "number", cellClassName: 'name-column--cell',
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        ៛ {formatCurrency(parseFloat(row.total_fee))}
                    </Typography>
                )
            }
        },
        { field: "from_branch_name", headerName: "សាខាផ្ញើ", width: 180 },
        { field: "to_branch_name", headerName: "សាខាទទួល", width: 180 },
        { field: "parcel_status", headerName: "ស្ថានភាពបញ្ញើ", width: 180 },
        {
            field: "date_created", headerName: "កាលបរិច្ឆេទផ្ញើ", width: 180,
            renderCell: ({ row }) => {
                return (
                    <Typography variant="title">
                        {dayjs(row.date_created).format("DD/MM/YYYY HH:mm")}
                    </Typography>
                )
            }
        },
    ];

    const ParcelGrid = () => {
        if (!showGrid) return (<Box />)
        return (
            <DataGrid rows={parcels} columns={myColumns} sx={{ fontSize: "16px" }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
            />
        );
    }

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="របាយការណ៍ដឹកជញ្ជូន" subtitle="ត្រួតពិនិត្យរបាយការណទូទៅ" />
            </Box>
            <Divider />
            <Box m={1} p={3}  >
                <Box
                    display="grid"
                    gap={2}
                    gridTemplateColumns="repeat(7, minmax(0, 1fr))"
                    p={3}
                    border={8}
                    borderRadius={2}
                    borderRight={0}
                    borderBottom={0}
                    borderLeft={0}
                    borderColor={colors.greenAccent[900]}
                    backgroundColor={colors.primary[400]}
                    sx={{
                        boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                        "& .MuiOutlinedInput-root": {
                            height: "36px"
                        },

                        "& .MuiTextField-root": {
                            width: "100%"
                        },
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 7" },
                    }}>


                    <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center">
                        <Typography variant="h5" fontWeight="bold" width="30%" >
                            សាខាក្រុមហ៊ុន
                        </Typography>
                        <FormControl variant="outlined" fullWidth >
                            <Select
                                labelId="status-select-label"
                                name="senderBranch"
                                value={branchId}

                                displayEmpty
                                onChange={handleBranchSelected}
                                sx={{
                                    maxHeight: "36px",
                                }}
                            >
                                <MenuItem key={-1} value="" >
                                    <em>ទាំងអស់</em>
                                </MenuItem>
                                {branches}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center">
                        <Typography variant="h5" fontWeight="bold" width="30%" >
                            ស្ថានភាពបញ្ញើ
                        </Typography>
                        <FormControl variant="outlined" fullWidth >
                            <Select
                                labelId="status-select-label"
                                name="statusId"
                                value={statusId}
                                displayEmpty
                                onChange={handleStatusChange}
                                sx={{
                                    maxHeight: "36px",
                                }}
                            >

                                <MenuItem key={-1} value="" >
                                    <em>ទាំងអស់</em>
                                </MenuItem>
                                {status}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box gridColumn={isNonMobile ? "span 1" : "span 7"} display="flex" gap="10px" alignItems="center">
                    </Box>


                    <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center">
                        <Typography variant="h5" fontWeight="bold" width="30%">
                            ចាប់ពី
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                maxHeight="36px"
                                value={startDate}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    setStartDate(dayjs(newValue).format('YYYY/MM/DD'));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center" >
                        <Typography variant="h5" fontWeight="bold" width="30%">
                            រហូតដល់
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                maxHeight="36px"
                                value={endDate}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    setEndDate(dayjs(newValue).format('YYYY/MM/DD'));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    </Box>
                    <Box gridColumn={isNonMobile ? "span 1" : "span 7"} alignItems="center" display="grid" justify-self="end">
                        <Button variant="contained" onClick={handleQueryReport}
                            sx={{ backgroundColor: colors.greenAccent[800] }}>
                            បង្ហាញរបាយការណ៍
                        </Button>
                    </Box>

                </Box>
                <Box display={isShowReport ? "block" : "none"}  >

                    <Box mt={3} display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gap={3}
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
                        }}>

                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            backgroundColor={colors.primary[400]}
                            borderRadius={2}
                            m="0px 0px 10px 0px"
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <StatBox
                                title={totalParcel}
                                subtitle="បញ្ញើសរុប"
                                progress="0.75"
                                increase=""
                                icon={
                                    <FontAwesomeIcon icon={faBoxes}
                                        style={{ color: colors.greenAccent[600], height: "22px", width: "22px" }}
                                    />
                                }
                            />
                        </Box>



                        <Box
                            m="0px 0px 10px 0px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <StatBox
                                title={totalInTransit}
                                subtitle="បញ្ញើកំពុងដឹក"
                                progress="0.50"
                                increase=""
                                icon={
                                    <FontAwesomeIcon icon={faTruckFast}
                                        style={{ color: colors.greenAccent[600], fontSize: "22px" }}
                                    />
                                }
                            />
                        </Box>

                        <Box
                            m="0px 0px 10px 0px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <StatBox
                                title={totalCompletedParcel}
                                subtitle="បញ្ញើជោគជ័យ"
                                progress="0.30"
                                increase="+5%"
                                icon={
                                    <FontAwesomeIcon icon={faFlagCheckered}
                                        style={{ color: colors.greenAccent[600], fontSize: "22px" }}
                                    />
                                }
                            />
                        </Box>

                        <Box
                            m="0px 0px 10px 0px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <StatBox
                                title={totalFailedParcel}
                                subtitle="បញ្ញើបរាជ័យ"
                                progress="0.80"
                                increase=""
                                icon={
                                    <FontAwesomeIcon icon={faExclamationTriangle}
                                        style={{ color: colors.greenAccent[600], fontSize: "22px" }}
                                    />
                                }
                            />
                        </Box>
                    </Box>

                    {/* Summary Graph */}
                    <Box mt={2} display="grid" gridTemplateColumns="repeat(2, minmax(0, 1fr))" gap={3}
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
                        }}>
                        <Box
                            backgroundColor={colors.primary[400]}
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1", boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)",
                            }}
                        >
                            <Box
                                mt="25px"
                                p="0 30px"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    gridColumn: "span 1",

                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.grey[100]}
                                    >
                                        សង្ខេបក្រាហ្វទំនិញសរុប
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.greenAccent[500]}
                                    >
                                        {totalParcel} បញ្ញើ
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton>
                                        <DownloadOutlinedIcon
                                            sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box height="250px" m="-20px 0 0 0">
                                <ParcelSummaryChart isDashboard={false} chartData={parcelSummary} />
                            </Box>
                        </Box>

                        <Box
                            backgroundColor={colors.primary[400]}
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1", boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)",
                            }}
                        >
                            <Box
                                mt="25px"
                                p="0 30px"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    gridColumn: "span 1",

                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.grey[100]}
                                    >
                                        សង្ខេបក្រាហ្វចំណូលសរុប
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.greenAccent[500]}
                                    >
                                        ៛ {formatCurrency(totalEarn)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton>
                                        <DownloadOutlinedIcon
                                            sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box height="250px" m="-20px 0 0 0">
                                <EarningSummaryChart isDashboard={false} chartData={earningSummary} />
                            </Box>
                        </Box>

                    </Box>
                    <Box
                        p={4}
                        mt={3}
                        height="80vh"
                        borderRadius={2}
                        backgroundColor={colors.primary[400]}
                        sx={{
                            boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)",

                            "& .MuiDataGrid-cell:focus": {
                                outline: "solid #256970 0px;",
                            },

                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "solid #256970 0px;",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.greenAccent[800],
                                color: "white"
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            paddingBottom: "64px !important"
                        }}>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            pb="16px"
                            // backgroundColor="blue"
                            color={colors.grey[100]}
                        >
                            តារាងប្រតិបត្តិការដឹកជញ្ជូនបញ្ញើលំអិត
                        </Typography>

                        < ParcelGrid />
                    </Box>
                </Box>
            </Box>
        </Box >
    );

}

export default ReportDelievery

