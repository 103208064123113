/* eslint-disable no-unused-vars */
// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton, Divider, Hidden } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import { AirplaneTicket, ChevronLeftOutlined, ChevronRightOutlined, ExpandCircleDown, FireTruck, Receipt } from "@mui/icons-material";
import { fontWeight } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faBoxes, faBuilding, faUser, faHome, faChartColumn, faUserGroup, faMagnifyingGlass, faThLarge, faCaretRight, faHomeAlt, faUserAlt, faHomeLgAlt, faSearch } from '@fortawesome/free-solid-svg-icons';


const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      sx={{
        '&:active': {
          boxShadow: 'none',
          backgroundColor: '#0062cc',
          borderColor: '#005cbf',
        },
      }}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography lineHeight={2.0} fontSize="14px">{title}</Typography>
    </MenuItem >
  );
};



const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("ទំព័រមុខ");
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        // pr: broken ? 0 : 10,
        // overflow: "hidden",
        "& .sidebar": {
          border: "none",
          // backgroundColor: "blue"
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          // backgroundColor: "transparent !important",
          backgroundColor: `${colors.sidebarBg[500]} !important`
        },

        "& .menu-label": {
          fontSize: "14px",
        },

        "& .menu-anchor": {
          color: `${colors.grey[500]} !important`
        },

        "& .menu-anchor:hover": {
          color: `${colors.greenAccent[400]} !important`,
          backgroundColor: `${colors.hoverBG[100]} !important`
        },

        "& .menu-anchor:active": {
          color: `${colors.greenAccent[400]} !important`,
          // backgroundColor: `${colors.redAccent[400]} !important`,
        },

        "& .menu-anchor.active": {
          // backgroundColor: `${colors.redAccent[400]} !important`
        },

        // "& .menu-item:hover": {
        //   color: `${colors.blueAccent[500]} !important`,
        //   // background: `${colors.redAccent[500]} !important`,
        //   // backgroundColor: "transparent !important",
        // },

        // "& .sub-menu menu-item:hover": {
        //   color: `${colors.blueAccent[500]} !important`,
        //   // background: `${colors.redAccent[500]} !important`,
        //   // backgroundColor: "transparent !important",
        // },

        // "& .sub-menu-item:hover": {
        //   color: `${colors.greenAccent[400]} !important`,
        //   // background: `${colors.redAccent[500]} !important`,
        //   // backgroundColor: "transparent !important",
        // },

        // "& .pro-inner-item": {
        //   backgroundColor: `${colors.blueAccent[500]} !important`,
        // },

        // "& .pro-inner-item:hover": {
        //   backgroundColor: `${colors.redAccent[500]} !important`,
        // },
        "& .cxRqFw": {
          backgroundColor: `${colors.hoverBG[100]} !important`,
        },

        "& .menu-item.active": {
          // color: `${colors.redAccent[500]} !important`,
          // color: `${colors.greenAccent[500]} !important`,
          // backgroundColor: `${colors.redAccent[500]} !important`,
        },
      }
      }
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.sidebarBg[500]}
        image={sidebarImage}
        style={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}
      >
        <Menu iconshape="square">
          <Box display="flex"
            justifyContent={{ collapsed } ? "center" : "space-between"}
            // justifyContent="space-between"
            alignItems="center"
            height={72}
            ml="4px">
            {!collapsed ? <Box display="flex" justifyContent="space-between" alignItems="center"> <img
              alt="logo"
              height="48px"
              src={theme.palette.mode === "light" ? `../../assets/car-logo.png` : `../../assets/car-logo-green.png`}
              style={{ cursor: "pointer" }}
            />
              <Typography variant="h5" color={colors.logoColor[100]} fontWeight="bold" sx={{ p: "0px 0px 0px 8px" }} fontFamily={["Noto Sans Khmer", "sans-serif"].join(",")}>
                សន្តិភាព  អុិចប្រេស
              </Typography>
            </Box> : undefined

            }
            <IconButton sx={{ ml: collapsed ? "-10px" : "2px" }} onClick={() => collapseSidebar()} > {collapsed ? <MenuOutlinedIcon /> : <ChevronLeftOutlined />}
            </IconButton>


          </Box>

          <Divider />

          <Box paddingLeft={collapsed ? undefined : "0%"}>
            <Item
              title="ទំព័រមុខ"
              to="/"
              icon={<HomeRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {!collapsed ? <Typography
              variant="h6"
              color={colors.grey[600]}

              sx={{ m: "15px 20px 5px 20px" }}
            >
              ប្រតិបត្តិការ
            </Typography> : <Box />
            }
            <SubMenu label="គ្រប់គ្រងសំបុត្រឡាន" icon={<Receipt />} >
              <Item
                title="លក់សំបុត្រឡាន"
                to="/transit/sell-ticket"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="តារាងលក់សំបុត្រឡាន"
                to="/transit/ticket-list"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

            </SubMenu>


            <SubMenu label="គ្រប់គ្រងបញ្ញើ" icon={<FontAwesomeIcon icon={faBoxes} style={{ height: "18px", width: "18px" }} />} >
              <Item
                title="បញ្ចូលបញ្ញើថ្មី"
                to="/parcels/add-parcel"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="បញ្ញើទាំងអស់"
                to="/parcels/parcel-list"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />


              <Item
                title="បញ្ញើបានទទួល"
                to="/parcels/accepted"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="កំពុងដឹក"
                to="/parcels/transit"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="ដល់ទីតាំង"
                to="/parcels/arrived"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="អតិថិជនបានទទួល"
                to="/parcels/delivered"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="បញ្ញើបរាជ័យ"
                to="/parcels/failed"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

            </SubMenu>

            <Item
              title="ស្វែងរកបញ្ញើ"
              to="/search"
              icon={<FontAwesomeIcon icon={faSearch} style={{ height: "16px", width: "16px" }} />}
              selected={selected}
              setSelected={setSelected}
            />

            <SubMenu label="របាយការណ៍" icon={<FontAwesomeIcon icon={faChartColumn} style={{ height: "16px", width: "16px" }} />} >
              <Item
                title="ដឹកជញ្ជូន"
                to="/report/delievery"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="លក់សំបុត្រ"
                to="/report/ticket"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>



            {/* {!collapsed ? <Typography
              variant="h6"
              color={colors.grey[600]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              លក់សំបុត្រឡាន
            </Typography> : <Box />
            }
            <SubMenu label="សាខាក្រុមហ៊ុន" sx={{ fontSize: "16px" }} icon={<FontAwesomeIcon icon={faBuilding} style={{ height: "16px", width: "16px" }} />} >
              <Item
                title="បញ្ចូលសាខាថ្មី"
                to="/branches/add-branch"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="សាខាទាំងអស់"
                to="/branches"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />


            </SubMenu> */}




            {!collapsed ? <Typography
              variant="h6"
              color={colors.grey[600]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              គ្រប់គ្រងប្រព័ន្ធ
            </Typography> : <Box />
            }
            <SubMenu label="សាខាក្រុមហ៊ុន" sx={{ fontSize: "16px" }} icon={<FontAwesomeIcon icon={faBuilding} style={{ height: "16px", width: "16px" }} />} >
              <Item
                title="បញ្ចូលសាខាថ្មី"
                to="/branches/add-branch"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="សាខាទាំងអស់"
                to="/branches"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />


            </SubMenu>

            <SubMenu label="បុគ្គលិក" icon={<FontAwesomeIcon icon={faUser} style={{ height: "16px", width: "16px" }} />} >
              <Item
                title="បញ្ចូលបុគ្គលិកថ្មី"
                to="/staffs/add-staff"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="បុគ្គលិកទាំងអស់"
                to="/staffs"
                icon={<FontAwesomeIcon icon={faCaretRight} style={{ height: "12px", width: "12px" }} />}
                selected={selected}
                setSelected={setSelected}
              />

            </SubMenu>


          </Box>
        </Menu>
      </Sidebar>
    </Box >
  );
};

export default MyProSidebar;
