import { React, useState, useEffect } from "react";
import { Box, useTheme, Typography, TextField, Divider, IconButton, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faTruckFast, faFlagCheckered, faExclamationTriangle, faTicket, faChartLine } from "@fortawesome/free-solid-svg-icons";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, Select, Button, MenuItem } from '@mui/material/';
import dayjs from 'dayjs';
import TicketStatBox from "../../components/TicketStateBox";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";


import { DataGrid } from '@mui/x-data-grid';
import TicketEarningSummaryChart from "../../components/EarningSummaryChart";
import TicketSummaryChart from "../../components/TicketSummaryChart";
import { formatCurrency, formatDate } from "../../helper/Helper";
import { number } from "yup";
import { Receipt } from "@mui/icons-material";
import SvgIcon from '@mui/material/SvgIcon';
import SellIcon from '@mui/icons-material/Sell';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Earning, RielIcon } from '../../asset'


const ReportTicket = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1330px)");
    const [tickets, setTickets] = useState([]);
    const [isShowReport, setIsShowReport] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().format('YYYY/MM/DD'));
    const [endDate, setEndDate] = useState(dayjs().format('YYYY/MM/DD'));
    const [branchId, setBranchId] = useState('');
    const [customerTypeId, setCustomerTypeId] = useState('');

    const [totalTicket, setTotalTicket] = useState(0);
    // const [topTenBranch, setTopTenBranch] = useState([]);
    const [earningSummary, setEarningSummary] = useState([]);
    const [ticketSummary, setTicketSummary] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [customerTypeList, setCustomerTypeList] = useState([]);
    const [showGrid, setShowGrid] = useState(false);
    const [totalAgencyFee, setTotalAgencyFee] = useState(0);
    const [totalProfitRiel, setTotalProfitRiel] = useState(0);
    const [totalProfitDollar, setTotalProfitDollar] = useState(0);


    useEffect(() => {
        if (branchList.length <= 0) getBranchList();
        if (customerTypes.length <= 0) getCustomerType()
    }, [])


    const getBranchList = async () => {
        try {
            const result = await fetch(window.domain + "/branches", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            result.json().then(body => {
                setBranchList(body);
            });
        } catch (error) {

        };
    }

    const getCustomerType = async () => {
        try {
            const result = await fetch(window.domain + "/customer/type", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            result.json().then(body => {
                setCustomerTypeList(body);

            });
        } catch (error) {

        }

    }


    const branches = branchList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );

    const customerTypes = customerTypeList.map((b) =>
        <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
    );


    const handleBranchSelected = (e) => {

        setBranchId(e.target.value);
    }

    const handleStatusChange = (e) => {
        setCustomerTypeId(e.target.value);
    };

    const handleQueryReport = () => {
        setIsShowReport(true);
        queryReport();
    }

    const queryReport = async () => {

        try {
            const result = await fetch(window.domain + "/report/ticket", {
                method: "POST",
                body: JSON.stringify({
                    start_date: startDate,
                    end_date: endDate,
                    branch_id: branchId,
                    customer_type: customerTypeId
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            result.json().then(body => {
                // Detail Transaction Data
                setTickets(body.detail_transaction);
                const earn = body.earning_summary;
                var chart1 = [];
                var chart2 = [];

                setEarningSummary([])
                setTicketSummary([])

                const sortedEarning = []
                for (let i = 0; i < earn.length; i++) {
                    const c = earn[i]
                    if (i > 0) {
                        const d = sortedEarning[sortedEarning.length - 1]
                        if (c.created_date === d.created_date) {
                            var total = 0
                            var total_amount = 0
                            if (c.ticket_currency === "$") {
                                total = c.total * 4000
                            }
                            total = total + d.total
                            total_amount = c.amount + d.amount

                            const v = {
                                "amount": total_amount,
                                "created_date": c.created_date,
                                "ticket_currency": "៛",
                                "total": total
                            }
                            sortedEarning[i - 1] = v
                        } else {
                            let total = c.total
                            if (c.ticket_currency === "$") {
                                total = c.total * 4000
                            }
                            const v = {
                                "amount": c.amount,
                                "created_date": c.created_date,
                                "ticket_currency": "៛",
                                "total": total
                            }
                            sortedEarning.push(v)
                        }
                    } else {
                        sortedEarning.push(c)
                    }

                }




                sortedEarning.map((e, i) => {
                    const a = { x: e.created_date, y: e.total }
                    const b = { x: e.created_date, y: e.amount }
                    chart1.push(a);
                    chart2.push(b);

                    if (i === sortedEarning.length - 1) {
                        const EarningChart = [
                            { id: "ចំណូល", data: chart1 }
                        ];

                        const TicketChart = [
                            { id: "សំបុត្រ", data: chart2 }
                        ]

                        setEarningSummary(EarningChart);
                        setTicketSummary(TicketChart);
                    }

                });

                // const topSummary = body.top_summary
                // var tickets = 0
                // topSummary.map((item) => {
                //     tickets = tickets + item.amount
                // });
                // setTotalTicket(tickets)


                setTotalProfitDollar(0)
                setTotalProfitRiel(0)

                const summary_by_currency = body.summary_by_currency
                setTotalProfitRiel(summary_by_currency.total_riel)
                setTotalProfitDollar(summary_by_currency.total_dollar)
                setTotalTicket(summary_by_currency.total_ticket)
                setTotalAgencyFee(summary_by_currency.total_agency_fee)
                setShowGrid(true)
                console.log(summary_by_currency)
                // const summary_by_currency = body.summary_by_currency
                // var total_earn_dollar = 0
                // var total_earn_riel = 0
                // summary_by_currency.map((item) => {
                //     if (item.ticket_currency === "$") {
                //         total_earn_dollar = item.total
                //     } else {
                //         total_earn_riel = item.total

                //     }
                // });
                // setTotalEarnRiel(total_earn_riel)
                // setTotalEarnDollar(total_earn_dollar)

                // const totalAllEarning = total_earn_riel + (total_earn_dollar * 4000)
                // setTotalEarn(totalAllEarning)


            });
        } catch (error) {

        };
    }

    const formatLargeNumber = (number) => {
        if (number >= 1e9) {
            return (number / 1e9).toFixed(2) + ' ពាន់លាន';
        } else if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + ' លាន';
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + ' ពាន់';
        } else {
            return number.toString();
        }
    };

    const MenuIcon = (props) => (
        <svg fill={props.fill} className={props.class}></svg>
    )






    // const currencyFormatter = new Intl.NumberFormat('km-KH', {
    //     style: 'currency',
    //     currency: 'KHR',
    // });

    // const khPrice = {
    //     type: 'number',
    //     width: 130,
    //     valueFormatter: ({ value }) => currencyFormatter.format(value),
    //     cellClassName: 'name-column--cell',
    // };

    const myColumns = [
        { field: "id", headerName: "Id", width: 60, valueFormatter: ({ value }) => value == null ? "" : value.toLocaleString('en-US', { minimumIntegerDigits: 2 }) },
        {
            field: "customer_type", headerName: "អតិថិជន", width: 100, cellClassName: 'name-column--cell',
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        {row.customer_type === 0 ? "ធម្មតា" : row.customer_type === 1 ? "ភ្នាក់ងារ" : "ផ្សេងៗ"}
                    </Typography>
                )
            }
        },
        {
            field: "departure_date", headerName: "ថ្ងៃចេញដំណើរ", width: 120, type: 'date',
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        {dayjs(row.departure_date).format("DD/MM/YYYY")}
                    </Typography>
                )
            }
        },
        {
            field: "departure_time", headerName: "ម៉ោងចេញដំណើរ", width: 130,
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        {dayjs(row.departure_date).format("HH:MM")}
                    </Typography>
                )
            }

        },
        { field: "from_branch_name", headerName: "ចេញពីសាខា", width: 180 },
        { field: "to_branch_name", headerName: "ទៅកាន់សាខា", width: 180 },
        { field: "seat_no", headerName: "លេខកៅអី", width: 90, valueFormatter: ({ value }) => value == null ? "" : value.toLocaleString('en-US', { minimumIntegerDigits: 2 }) },

        {
            field: "ticket_price", headerName: "តំលៃសំបុត្រ", width: 120, type: "number", cellClassName: 'name-column--cell',
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        {row.ticket_currency}{formatCurrency(parseFloat(row.ticket_price))}
                    </Typography>
                )
            }
        },
        {
            field: "discount", headerName: "បញ្ចុះតំលៃ", width: 120, type: "number", cellClassName: 'name-column--cell',
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        {row.discount_currency}{formatCurrency(parseFloat(row.discount))}
                    </Typography>
                )
            }
        },
        {
            field: "agency_fee", headerName: "សេវាភ្នាក់ងារ", width: 120, type: "number", cellClassName: 'name-column--cell',
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        {row.agency_currency}{formatCurrency(parseFloat(row.agency_fee))}
                    </Typography>
                )
            }
        },
        {
            field: "total", headerName: "តំលៃសរុប", width: 160, type: "number", cellClassName: 'name-column--cell',
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        {row.ticket_currency} {formatCurrency(parseFloat(row.total))}
                    </Typography>
                )
            }
        },
        {
            field: "profit", headerName: "ចំណូល", width: 160, type: "number", cellClassName: 'name-column--cell',
            renderCell: ({ row }) => {
                return (
                    <Typography fontSize="16px">
                        {row.ticket_currency} {formatCurrency(parseFloat(row.profit))}
                    </Typography>
                )
            }
        },


        {
            field: "date_created", headerName: "កាលបរិច្ឆេទផ្ញើ", width: 180,
            renderCell: ({ row }) => {
                return (
                    <Typography variant="title">
                        {dayjs(row.created_date).format("DD/MM/YYYY HH:mm")}
                    </Typography>
                )
            }
        },
        {
            field: "created_by", headerName: "លក់ដោយ", width: 100
        }
    ];

    const ParcelGrid = () => {
        if (!showGrid) return (<Box />)
        return (
            <DataGrid rows={tickets} columns={myColumns} sx={{ fontSize: "16px" }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
            />
        );
    }

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="របាយការណ៍លក់សំបុត្រ" subtitle="ត្រួតពិនិត្យរបាយការណទូទៅ" />
            </Box>
            <Divider />
            <Box m={1} p={3}  >
                <Box
                    display="grid"
                    gap={2}
                    gridTemplateColumns="repeat(7, minmax(0, 1fr))"
                    p={3}
                    border={8}
                    borderRadius={2}
                    borderRight={0}
                    borderBottom={0}
                    borderLeft={0}
                    borderColor={colors.greenAccent[900]}
                    backgroundColor={colors.primary[400]}
                    sx={{
                        boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                        "& .MuiOutlinedInput-root": {
                            height: "36px"
                        },

                        "& .MuiTextField-root": {
                            width: "100%"
                        },
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 7" },
                    }}>


                    <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center">
                        <Typography variant="h5" fontWeight="bold" width="30%" >
                            សាខាក្រុមហ៊ុន
                        </Typography>
                        <FormControl variant="outlined" fullWidth >
                            <Select
                                labelId="status-select-label"
                                name="senderBranch"
                                value={branchId}

                                displayEmpty
                                onChange={handleBranchSelected}
                                sx={{
                                    maxHeight: "36px",
                                }}
                            >
                                <MenuItem key={-1} value="" >
                                    <em>ទាំងអស់</em>
                                </MenuItem>
                                {branches}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center">
                        <Typography variant="h5" fontWeight="bold" width="30%" >
                            ប្រភេទអតិថិជន
                        </Typography>
                        <FormControl variant="outlined" fullWidth >
                            <Select
                                labelId="customer-type-select-label"
                                name="customerType"
                                value={customerTypeId}
                                displayEmpty
                                onChange={handleStatusChange}
                                sx={{
                                    maxHeight: "36px",
                                }}
                            >

                                <MenuItem key={-1} value="" >
                                    <em>ទាំងអស់</em>
                                </MenuItem>
                                {customerTypes}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box gridColumn={isNonMobile ? "span 1" : "span 7"} display="flex" gap="10px" alignItems="center">
                    </Box>


                    <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center">
                        <Typography variant="h5" fontWeight="bold" width="30%">
                            ចាប់ពី
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                maxHeight="36px"
                                value={startDate}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    setStartDate(dayjs(newValue).format('YYYY/MM/DD'));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Box gridColumn={isNonMobile ? "span 3" : "span 7"} display="flex" gap="10px" alignItems="center" >
                        <Typography variant="h5" fontWeight="bold" width="30%">
                            រហូតដល់
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                maxHeight="36px"
                                value={endDate}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    setEndDate(dayjs(newValue).format('YYYY/MM/DD'));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    </Box>
                    <Box gridColumn={isNonMobile ? "span 1" : "span 7"} alignItems="center" display="grid" justify-self="end">
                        <Button variant="contained" onClick={handleQueryReport}
                            sx={{ backgroundColor: colors.greenAccent[800] }}>
                            បង្ហាញរបាយការណ៍
                        </Button>
                    </Box>

                </Box>
                <Box display={isShowReport ? "block" : "none"}  >

                    <Box mt={3} display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gap={3}
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
                        }}>

                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            backgroundColor={colors.primary[400]}
                            borderRadius={2}
                            m="0px 0px 10px 0px"
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <TicketStatBox
                                title={(totalTicket)}
                                subtitle="ចំនួនសំបុត្រសរុប"
                                progress=""
                                increase=""
                                icon={
                                    <Receipt style={{ color: colors.greenAccent[600], height: "22px", width: "22px" }} />
                                }
                            />
                        </Box>



                        {/* <Box
                            m="0px 0px 10px 0px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <TicketStatBox
                                title={formatCurrency(totalEarn)}
                                subtitle="ទឹកប្រាក់សរុបជារៀល"
                                progress=""
                                increase=""
                                icon={<SellIcon style={{ color: colors.greenAccent[600], height: "22px", width: "22px" }} />}

                            />
                        </Box> */}

                        <Box
                            m="0px 0px 10px 0px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <TicketStatBox
                                title={formatCurrency(totalProfitRiel)}
                                subtitle="ប្រាក់រៀលទទួលបាន ៛"
                                progress=""
                                increase=""
                                icon={
                                    // <RielIcon color={colors.greenAccent[600]} />
                                    <img src={RielIcon} style={{ filter: "invert(57.6%) sepia(94.2%) saturate(373.3%) hue-rotate(116.7deg) brightness(165.1%) contrast(67.6%)", height: "20px", width: "20px" }} />
                                    // <SvgIcon component={Earning}
                                    //     style={{ color: colors.greenAccent[600], height: "22px", width: "22px" }}

                                    // />
                                }
                            />
                        </Box>

                        <Box
                            m="0px 0px 10px 0px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <TicketStatBox
                                title={formatCurrency(totalProfitDollar)}
                                subtitle="ប្រាក់ដុល្លាទទួលបាន $"
                                progress=""
                                increase=""
                                icon={
                                    <AttachMoneyIcon
                                        style={{ color: colors.greenAccent[600], fontSize: "24px" }}
                                    />
                                }
                            />
                        </Box>

                        <Box
                            m="0px 0px 10px 0px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1",
                                boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)"
                            }}
                        >
                            <TicketStatBox
                                title={formatCurrency(totalAgencyFee)}
                                subtitle="សរុបសេវាភ្នាក់ងារ ៛"
                                progress=""
                                increase=""
                                icon={<SellIcon style={{ color: colors.greenAccent[600], height: "22px", width: "22px" }} />}

                            />
                        </Box>
                    </Box>

                    {/* Summary Graph */}
                    <Box mt={2} display="grid" gridTemplateColumns="repeat(2, minmax(0, 1fr))" gap={3}
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
                        }}>
                        <Box
                            backgroundColor={colors.primary[400]}
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1", boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)",
                            }}
                        >
                            <Box
                                mt="25px"
                                p="0 30px"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    gridColumn: "span 1",

                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.grey[100]}
                                    >
                                        សង្ខេបក្រាហ្វទំនិញសរុប
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.greenAccent[500]}
                                    >
                                        {totalTicket} សំបុត្រ
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton>
                                        <DownloadOutlinedIcon
                                            sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box height="250px" m="-20px 0 0 0">
                                <TicketSummaryChart isDashboard={false} chartData={ticketSummary} />
                            </Box>
                        </Box>

                        <Box
                            backgroundColor={colors.primary[400]}
                            borderRadius={2}
                            sx={{
                                gridColumn: "span 1", boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)",
                            }}
                        >
                            <Box
                                mt="25px"
                                p="0 30px"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    gridColumn: "span 1",

                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.grey[100]}
                                    >
                                        សង្ខេបក្រាហ្វចំណូលសរុប
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.greenAccent[500]}
                                    >
                                        ៛ {formatCurrency(totalProfitRiel + (totalProfitDollar * 4000))}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton>
                                        <DownloadOutlinedIcon
                                            sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box height="250px" m="-20px 0 0 0">
                                <TicketEarningSummaryChart isDashboard={false} chartData={earningSummary} />
                            </Box>
                        </Box>

                    </Box>
                    <Box
                        p={4}
                        mt={3}
                        height="80vh"
                        borderRadius={2}
                        backgroundColor={colors.primary[400]}
                        sx={{
                            boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)",

                            "& .MuiDataGrid-cell:focus": {
                                outline: "solid #256970 0px;",
                            },

                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "solid #256970 0px;",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.greenAccent[800],
                                color: "white"
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            paddingBottom: "64px !important"
                        }}>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            pb="16px"
                            // backgroundColor="blue"
                            color={colors.grey[100]}
                        >
                            តារាងប្រតិបត្តិការលក់សំបុត្រលំអិត
                        </Typography>

                        < ParcelGrid />
                    </Box>
                </Box>
            </Box>
        </Box >
    );

}

export default ReportTicket

