
export function getAccessLevel(level) {
    if (level === 1) {
        return "Admin";
    } else if (level === 2) {
        return "Manager";
    } else if (level === 3) {
        return "Staff";
    }

    return "Unknown";
}


export function getBranchName(id, list) {

}

export function formatPhoneNumber(phoneNumber) {
    const phoneNumberString = phoneNumber.toString();
    const formattedPhoneNumber =
        phoneNumberString.slice(0, 3) + '-' + phoneNumberString.slice(3, 6) + '-' + phoneNumberString.slice(6);


    return formattedPhoneNumber;
};

export function formatCurrency(value) {
    if (value === "0" || value.length <= 0) return "0.00";
    return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function zeroPad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}



export function colorList() {
    const colorList = [
        "rgb(31, 119, 180)",
        "rgb(255, 127, 14)",
        "rgb(44, 160, 44)",
        "rgb(214, 39, 40)",
        "rgb(148, 103, 189)",
        "rgb(140, 86, 75)",
        "rgb(227, 119, 194)",
        "rgb(127, 127, 127)",
        "rgb(188, 189, 34)",
        "rgb(23, 190, 207)",
        "rgb(232, 193, 160)",
        "rgb(244, 117, 96)",
        "rgb(241, 225, 91)",
        "rgb(232, 168, 56)",
        "rgb(97, 205, 187)",
        "rgb(151, 227, 213)",
    ];
    return colorList;
}

export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
}

export function formatDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const mins = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hour}:${mins}`;
}


