/* eslint-disable no-unused-vars */
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useState, useEffect } from "react";
import { formatCurrency, zeroPad } from "../../helper/Helper";
import EarningCompareChart from "../../components/EarningCompareChart";
import EarningByBranchChart from "../../components/EarningByBranchChart";
import TransactionByBranchChart from "../../components/TransactionByBranchChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faMoneyCheckDollar, faTruckFast, faFlagCheckered, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";




const Dashboard = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const [totalEarningToday, setTotalEarning] = useState(0);
  const [totalParcelTotay, setTotalParcel] = useState(0);
  const [totalPendingToday, setTotalPending] = useState(0);
  const [totalFailedToday, setTotalFailed] = useState(0);
  const [compareReport, setCompareReport] = useState([]);
  const [totalEarningThisMonth, setTotalEarningThisMonth] = useState(0);
  const [totalEarningPreviousMonth, setTotalEarningPreviousMonth] = useState(0);
  const [earningByBranch, setEarningByBranch] = useState([]);
  const [totalEarningByBranch, setTotalEarningByBranch] = useState(0);
  const [transByBranch, setTransByBranch] = useState([]);
  const [recentTrans, setRecentTrans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchNames, setBranchName] = useState([]);


  const getToken = () => {
    return localStorage.getItem('token');
  }

  const setToken = (token) => {
    localStorage.setItem('token', token);
  }

  const getDashboardInfo = async () => {
    setIsLoading(true);
    try {
      const result = await fetch(window.domain + "/dashboard", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        }
      });

      result.json().then(body => {
        const top_summary = body.top_summary;
        const total_pending = body.pending_parcel;
        if (body.status) {
          setTotalEarning(formatCurrency(top_summary.total_earn));
          setTotalParcel(top_summary.total_parcel);
          setTotalPending(total_pending.pending_parcel);
          const earning_this_month = body.earning_this_month;
          const earning_previous_month = body.earning_previous_month;
          const trans_by_branch = body.tran_by_branch;
          const branch_names = body.branch_names;
          var chart1 = [];
          var chart2 = [];
          var chart3 = [];

          // const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          // const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
          const date = new Date()
          var totalEarningThisMonth = 0;
          var totalEarningPreviousMonth = 0;
          for (let i = 0; i < 30; i++) {
            let day = zeroPad(i + 1, 2)
            var a = { x: day, y: 0 }
            var b = { x: day, y: 0 }
            var c = { x: day, y: 0 }

            earning_this_month.map((e, i) => {
              if (e.date_created === day) {
                a = { x: e.date_created, y: e.total_earn }
              }
            });

            earning_previous_month.map((e, i) => {
              if (e.date_created === day) {
                b = { x: e.date_created, y: e.total_earn }
              }
            });

            // trans_by_branch.map((e, i) => {
            //   if (e.date_created === day) {
            //     c = { ...c, e.name: e.total_trans }

            //   }

            // }
            // );

            totalEarningThisMonth = totalEarningThisMonth + a.y;
            totalEarningPreviousMonth = totalEarningPreviousMonth + b.y;
            chart1.push(a);
            chart2.push(b);
            // chart3.push(c);
          }

          const transformedData = trans_by_branch.reduce((result, item) => {
            const { date_created, name, total_trans } = item;
            if (!result[date_created]) {
              result[date_created] = { date_created };
            }
            result[date_created][name] = total_trans;
            return result;
          }, {});

          const finalData = Object.values(transformedData);

          const compareChart = [
            { id: "ខែនេះ", data: chart1, color: colors.greenAccent[900] },
            { id: "ខែមុន", data: chart2, color: colors.greenAccent[500] }
          ];

          var pieChart = [];
          var sumEarningByBranch = 0;
          const earningByBranchChart = body.earning_by_branch;
          earningByBranchChart.map((e, i) => {
            sumEarningByBranch = sumEarningByBranch + e.total_earn;
            pieChart.push({ id: e.name, label: e.name, value: e.total_earn })
          });

          setCompareReport(compareChart);
          setTotalEarningThisMonth(totalEarningThisMonth);
          setTotalEarningPreviousMonth(totalEarningPreviousMonth);
          setEarningByBranch(pieChart);
          setTotalEarningByBranch(sumEarningByBranch);
          setTransByBranch(finalData);
          setBranchName(branch_names);
          setRecentTrans(body.recent_trans);

        } else {
          // console.log("Failed to delete " + body.message);
        }
      });
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
    };
  }

  useEffect(() => {
    getDashboardInfo();
    return () => {

    }
  }, []);



  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor={colors.primary[400]}
            borderRadius={2}
            sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}
          >
            <StatBox
              title={totalEarningToday}
              subtitle="ចំណូលថ្ងៃនេះ"
              progress="0.75"
              increase="+14%"
              icon={
                <FontAwesomeIcon icon={faMoneyCheckDollar}
                  style={{ color: colors.greenAccent[600], height: "22px", width: "22px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={2}
            sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}
          >
            <StatBox
              title={totalParcelTotay}
              subtitle="បញ្ញើថ្ងៃនេះ"
              progress="0.50"
              increase="+21%"
              icon={
                <FontAwesomeIcon icon={faBoxes}
                  style={{ color: colors.greenAccent[600], height: "22px", width: "22px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={2}
            sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}
          >
            <StatBox
              title={totalPendingToday}
              subtitle="បញ្ញើកំពុងដឹក"
              progress="0.30"
              increase="+5%"
              icon={
                <FontAwesomeIcon icon={faTruckFast}
                  style={{ color: colors.greenAccent[600], height: "22px", width: "22px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={2}
            sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}
          >
            <StatBox
              title={totalFailedToday}
              subtitle="បញ្ញើបរាជ័យ"
              progress="0.80"
              increase="+43%"
              icon={
                <FontAwesomeIcon icon={faExclamationTriangle}
                  style={{ color: colors.greenAccent[600], fontSize: "22px" }}
                />
              }
            />
          </Box>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={8}
          lg={8}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]} borderRadius={2} mb={2}
              sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}>
              <Box
                mt="25px"
                p="20px 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    sx={{ textDecoration: "underline" }}
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    សង្ខេបក្រាហ្វចំណូលខែនេះធៀបនឹងខែមុន
                  </Typography>
                  <Typography
                    mt="20px"
                    variant="h6"
                    fontWeight="600"
                    color={colors.greenAccent[900]}
                  >
                    ចំណូលខែនេះ ៛ {formatCurrency(totalEarningThisMonth)}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontWeight="600"
                    color={colors.greenAccent[500]}
                  >
                    ចំណូលខែមុន ៛ {formatCurrency(totalEarningPreviousMonth)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <DownloadOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box height="250px" m="-40px 0 0 0">
                <EarningCompareChart isDashboard={true} chartData={compareReport} />
              </Box>
            </Box>
          </Grid>
          {/* <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]} p="30px">
              <Typography variant="h5" fontWeight="600">
                សង្ខេបចំណូលប្រចាំសាខានីមួយៗ
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              > <Box height="250px" m="-40px 0 0 0">
                  <EarningCompareChart isDashboard={true} chartData={compareReport} />
                </Box>
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                >
                  $48,352 revenue generated
                </Typography>
                <Typography>
                  Includes extra misc expenditures and costs
                </Typography>
              </Box>
            </Box>
          </Grid> */}
          <Grid xs={12} sm={12} md={12}>
            <Box backgroundColor={colors.primary[400]} borderRadius={2} mb={2}
              sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}>
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ padding: "30px 30px 0 30px", textDecoration: "underline" }}
              >
                សង្ខេបចំណូលប្រចាំសាខានីមួយៗក្នុងខែនេះ
              </Typography>
              <Box height="320px" mt="10px">
                <EarningByBranchChart isDashboard={true} chartData={earningByBranch} totalEarning={totalEarningByBranch} />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <Box backgroundColor={colors.primary[400]} borderRadius={2}
              sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}>
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ padding: "30px 30px 0 30px", textDecoration: "underline" }}

              >
                សង្ខេបប្រតិបត្តិការដឹកទំនិញប្រចាំសាខានីមួយៗក្នុងខែនេះ
              </Typography>
              <Box height="320px" mt="-20px">
                <TransactionByBranchChart isDashboard={true} chartData={transByBranch} keys={branchNames} />
              </Box>
            </Box>
          </Grid>
          {/* <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]} padding="30px">
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ marginBottom: "15px" }}
              >
                Geography Based Traffic
              </Typography>
              <Box height="200px">
                <GeographyChart isDashboard={true} />
              </Box>
            </Box>
          </Grid> */}
        </Grid>
        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            backgroundColor={colors.primary[400]}
            maxHeight="740px"
            overflow="auto"
            m="25px 0 0 0"
            borderRadius={2}
            // backgroundColor={colors.primary[400]}
            sx={{ boxShadow: " 2px 6px 14px -5px rgba(0,0,0,0.2)" }}
          >
            <Box
              backgroundColor={colors.primary[400]}
              position="sticky"
              top="0px"
              alignSelf="flex-start"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`2px solid ${colors.primary[500]}`}
              color={colors.grey[100]}

              p="15px"
            >
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                ប្រតិបត្តិការដឹកទំនិញថ្មីៗ
              </Typography>
            </Box>
            <Divider />
            {recentTrans.map((transaction, i) => {
              return (
                <Box
                  key={`${transaction}-${i}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`1px solid #8080801a`}
                  p="15px"
                >
                  <Box>
                    <Typography
                      variant="h6"
                      fontWeight="600"
                      color={colors.greenAccent[100]}
                    >
                      {transaction.from_branch_name}
                    </Typography>
                    <Typography color={colors.grey[100]}>
                      {transaction.to_branch_name}
                    </Typography>
                  </Box>
                  <Box
                    color={colors.greenAccent[500]}
                    p="5px 10px"
                    borderRadius="4px"
                  >
                    <Typography color={colors.grey[100]}>
                      {transaction.category_name} {transaction.amount} {transaction.unit_name}
                    </Typography>
                    ៛{formatCurrency(transaction.total_fee)}
                  </Box>
                  <Box color={colors.grey[100]}>{transaction.date_created}</Box>
                </Box>

              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Dashboard;
