import { Button, Typography, useTheme } from "@mui/material";
import { tokens } from '../theme';
import { styled } from '@mui/material/styles';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";


// const CustomButton = () => {
//     return (<MyButton />);
// };


const MyButton = ({ title, icon, color }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(color),
        backgroundColor: color,
        '&:hover': {
            backgroundColor: colors.buttonHover[200],
        },
        padding: "10px 20px",
        fontSize: "14px",
        boxShadow: "2px 4px 10px 2px rgba(0,0,0,0.1)",
    }));

    return <ColorButton >
        {icon}
        {title}
    </ColorButton>;
}


// export default MyButton;

// const MyButton = ({ bgColor, hoverColor }) => {
//     const theme = useTheme();
//     const colors = tokens(theme.palette.mode);
//     const ColorButton = styled(Button)(({ theme }) => ({
//         color: theme.palette.getContrastText(bgColor),
//         backgroundColor: bgColor
//     },
//         '&:hover': {
//         backgroundColor: hoverColor
//     },
//         padding: "10px 20px",
//         fontSize: "14px",
//     ));
//     return <ColorButton />;
// }

export default MyButton;