import { React, useEffect, useState } from "react";
import { Box, useTheme, Typography, TextField, Divider, IconButton, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { AccessTime } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import InputAdornment from '@mui/material/InputAdornment';
import dayjs from 'dayjs';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import CustomNoRowsOverlay from "../../components/NoRowOverlay";

import { useLocation } from 'react-router-dom';



const SearchParcel = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:820px)");
    const [trackList, setTrackList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [activeStep, setActiveStep] = useState(-1);
    const location = useLocation();
    const [isFirstLoad, setFirstLoad] = useState(true);
    // const [trackListTemplate, setTrackListTemplate] = useState([]);

    const searchParcelByRef = async () => {
        try {
            const result = await fetch(window.domain + "/search", {
                method: "POST",
                body: JSON.stringify({
                    reference_number: location.state !== null ? location.state.item : searchText,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            result.json().then(body => {
                if (body.status) {
                    setTrackList(body.track_list);
                } else {
                    setActiveStep(0);
                }
            });
        } catch (error) {

        };
    }

    const handleSearchClick = (e) => {
        searchParcelByRef()
    }

    const handleChange = (e) => {
        setSearchText(e.target.value);
    }


    useEffect(() => {
        if (trackList.length > 0) setActiveStep(trackList.length);
        if (location.state !== null && isFirstLoad) {
            setSearchText(location.state.item)
            searchParcelByRef()
            location.state = null
            setFirstLoad(false)
        }

    }, [trackList.length, isFirstLoad]);

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="ស្វែងរកបញ្ញើ" subtitle="តាមដានស្ថានភាព និង ទីតាំងបញ្ញើ" />
            </Box>
            <Divider />

            <Box m={3} p="24px 20px 24px 0px"
                border={8}
                borderRadius={2}
                borderRight={0}
                borderBottom={0}
                borderLeft={0}
                borderColor={colors.greenAccent[900]}
                backgroundColor={colors.primary[400]}
                sx={{
                    boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                }}>
                <Box display="grid" pl="5%" pr="5%"
                    gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                    gap={3}
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
                    }}
                >
                    <Box mt="auto" mb="auto"
                        textAlign={isNonMobile ? "end" : "left"}
                        sx={{
                            gridColumn: "span 1",
                        }}
                    >
                        <Typography

                            variant="h4"
                            fontWeight="bold"
                        >
                            បញ្ចូលលេខតាមដានទំនិញ
                        </Typography>
                    </Box>
                    <Box mt="auto" mb="auto"
                        sx={{
                            gridColumn: "span 2",
                        }}
                    >
                        < TextField
                            sx={{
                                "& .MuiOutlinedInput-input": {
                                    fontSize: "26px"
                                }
                            }}
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="បញ្ចូលលេខបញ្ញើនៅទីនេះ"
                            value={searchText}
                            onChange={handleChange}
                            name="senderName"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleSearchClick}>
                                            {<FontAwesomeIcon icon={faSearch} style={{ height: "22px", width: "22px" }} />}
                                        </IconButton>
                                    </InputAdornment>
                                )

                            }}
                        />
                    </Box>
                </Box>
            </Box>



            <Box m={3} p="24px 20px 24px 0px" borderRadius={2}
                sx={{
                    display: activeStep < 0 ? "none" : "block",
                    backgroundColor: colors.primary[400],
                    boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)"
                }}>
                {activeStep > 0 ?
                    <Box display="flex" alignItems="center" justifyContent="center" >
                        <Stepper activeStep={activeStep} orientation="vertical"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                "& .MuiStepLabel-label": {
                                    fontSize: "16px"
                                }
                            }}>
                            {trackList.map((step, index) => (
                                <Step key={step.id}>
                                    <Box width="100%" bgcolor="red" height="20%"> </Box>
                                    <StepLabel
                                        optional={
                                            <Box>
                                                <Typography variant="h6"> {step.status_description}</Typography>
                                                <Typography sx={{ fontSize: "12px", mt: "4px" }}> <AccessTime fontSize="12px" sx={{ mb: "-2px", mr: "4px" }} /> {dayjs(step.date_created).format('DD/MM/YYYY HH:mm')}</Typography>
                                            </Box>
                                        }
                                    >
                                        {step.status_name}
                                    </StepLabel>
                                    <StepContent>
                                        {/* <Typography variant="caption">{step.status_description}</Typography> */}
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    : <Box textAlign="center">  <CustomNoRowsOverlay /></Box>}
            </Box>


        </Box >
    );

}

export default SearchParcel

