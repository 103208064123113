import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import { colorList, formatCurrency } from '../helper/Helper';

const EarningByBranchChart = ({ isDashboard = false, chartData, totalEarning }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    return (
        <ResponsivePie
            theme={{
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100]
                        }
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100]
                        }
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1
                        },
                        text: {
                            fill: colors.grey[100]
                        }
                    }
                },
                legends: {
                    text: {
                        fill: colors.grey[100]
                    }
                },
                // tooltip: {
                //     container: {
                //         background: colors.primary[400],
                //         color: colors.grey[100],
                //     },
                // },
            }}
            // colors={colorList()}
            tooltip={function (e) {
                var t = e.datum;
                return (
                    <div style={{ color: colors.grey[100], background: colors.primary[400], padding: "10px", boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)", }}>
                        <div>  សាខា: {t.id}</div>
                        <div >ចំណូល: ៛ {formatCurrency(t.value)}</div>
                        {/* <div>formattedValue: {t.formattedValue}</div> */}
                    </div>
                );
            }}
            data={chartData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.0}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            // tooltipFormat={value => Number(value).toLocaleString('en-US', { minimumFractionDigits: 0 }) + '$'}
            valueFormat={function (e) {
                return (e * 100 / totalEarning).toFixed(2) + '%'
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={colors.grey[100]}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}

            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: colors.grey[100],
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: colors.greenAccent[400],
                            }
                        }
                    ]
                }
            ]}
        />
    )

}

export default EarningByBranchChart