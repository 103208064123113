
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Divider } from '@mui/material';
import TextField from "@mui/material/TextField";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { EditOutlined, ClearOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { NativeSelect } from "@mui/material";
import { formatCurrency } from "../../helper/Helper";


const ModalDetailParcel = ({ parcel }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:890px)");
    const [showConfirm, setShowConfirm] = useState(false);
    const [senderBranch, setSenderBranch] = useState("");
    const [recipientBranch, setRecipientBranch] = useState("");
    const [statusList, setStatusList] = useState([]);
    const [parcelStatus, setParcelStatus] = useState(parcel.parcel_status);
    const [selectedStatus, setSelectedStatus] = useState(parcel.status);
    var branchList = [];
    var userSelectedStatus = parcel.status;

    // function formatCurrency(value) {
    //     if (value === "0" || value.length <= 0) return "0.00";
    //     return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // }

    const handleClickOpen = () => {
        setShowConfirm(true);
    };

    const handleClose = () => {
        setShowConfirm(false);
    };

    const handleUpdateStatus = async () => {
        try {
            const result = await fetch(window.domain + "/parcels/status", {
                method: "POST",
                body: JSON.stringify({
                    id: parcel.id,
                    status: userSelectedStatus
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            result.json().then(body => {
                if (body.status) {
                    updateParcelStatusName(userSelectedStatus);
                    setSelectedStatus(userSelectedStatus);
                } else {
                    console.log("failed");
                }
                handleClose();

            });
        } catch (error) {

        }
    }

    const handleSelectChanged = (e) => {
        if (e.target.value > 0) {
            userSelectedStatus = e.target.value;
        }
    }

    const statusListMenu = statusList.map((b) => {

        if (parseInt(b.id) <= parseInt(userSelectedStatus)) {
            return <option disabled={true} value={b.id}>{b.name}</option>
        } else {
            return <option value={b.id}>{b.name}</option>
        }
    }
    );

    function updateParcelStatusName(id) {
        statusList.forEach((item) => {
            if (parseInt(item.id) === parseInt(id)) {
                setParcelStatus(item.name);
            }
        })
    }

    useEffect(() => {
        if (branchList.length <= 0) getBranchList();
        if (statusListMenu.length <= 0) getParcelStatusList();
    }, [])



    const StatusButton = () => {
        let color = "grey";
        if (parcelStatus === 1) color = (colors.redAccent[800]);
        else if (parcelStatus === 2) color = (colors.orangeAccent[600]);
        else if (parcelStatus === 3) color = (colors.blueAccent[800]);
        else if (parcelStatus === 4) color = (colors.greenAccent[900]);
        else if (parcelStatus === 5) color = (colors.greenAccent[700]);
        return (<Box display="flex" gap={2} justifyContent="flex-start"><Button disableRipple={true} disableElevation={true} variant="contained" ml={0}
            sx={{
                backgroundColor: color,
                color: "white",
                borderRadius: 4,
                m: "10px 0px 10px 0px",
                "&:hover": { backgroundColor: color }
            }}>
            {parcelStatus} </Button>
            <Button variant="contained" disableElevation={true} ml={0} disableFocusRipple={true} onClick={handleClickOpen}
                sx={{
                    backgroundColor: colors.greenAccent[800],
                    color: "white",
                    borderRadius: 4,
                    m: "10px 0px 10px 0px"
                }}> <EditOutlined sx={{ mr: "4px", pr: "2px" }} /> ធ្វើបច្ចុប្បន្នភាព
            </Button>
            <Box>
                <Dialog
                    open={showConfirm}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        zIndex: 10000
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box display="flex" justifyContent="space-between" >
                            <Typography variant="h5" fontWeight="bold" paddingBottom={2}>{"ស្ថានភាពបញ្ញើ"} </Typography>
                            <Button
                                onClick={handleClose}
                                disableElevation={true}
                                disableRipple={true}
                                disableFocusRipple={true}
                                sx={{ minWidth: "0px", marginTop: "-20px", marginRight: "-15px", ":hover": { backgroundColor: "transparent" } }}
                            > <ClearOutlined /> </Button>
                        </Box>
                        {/* <Typography variant="h5" fontWeight="bold">ស្ថានភាពបញ្ញើ</Typography> */}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ជ្រើសរើសស្ថានភាពបញ្ញើថ្មីខាងក្រោម សំរាប់លេខតាមដានទំនិញ' {parcel.reference_number} '
                        </DialogContentText>
                        <Box mt="20px">
                            <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="select-parcel-label">
                                    ស្ថានភាព
                                </InputLabel>
                                <NativeSelect
                                    onChange={handleSelectChanged}
                                    defaultValue={selectedStatus}
                                    inputProps={{
                                        id: 'select-parcel-status',
                                    }}
                                >
                                    {statusListMenu}

                                </NativeSelect>
                            </FormControl>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>រំសាយ</Button>
                        <Button onClick={handleUpdateStatus} autoFocus>
                            រក្សាទុក
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box >
        );
    }

    const getParcelStatusList = async () => {
        try {
            const result = await fetch(window.domain + "/parcels/status", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            result.json().then(body => {
                setStatusList(body);

            });
        } catch (error) {

        }

    }


    const getBranchList = async () => {
        try {
            const result = await fetch(window.domain + "/branches", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            result.json().then(body => {
                branchList = body;
                branchList.forEach((b) => {
                    if (b.id === parseInt(parcel.from_branch_id)) {
                        let sender_address = b.name + " " + b.home + " " + b.street + " " + b.city + ", " + b.contact1;
                        setSenderBranch(sender_address);
                    }

                    if (b.id === parseInt(parcel.to_branch_id)) {
                        let recipient_address = b.name + " " + b.home + " " + b.street + " " + b.city + ", " + b.contact1;
                        setRecipientBranch(recipient_address);
                    }
                });

            });
        } catch (error) {

        };

    }


    const BranchAddressField = () => {
        return (<Box sx={{ "& .MuiInputLabel-root": { color: colors.greenAccent[800] } }}> <TextField
            sx={{ pt: "10px", pb: "10px", fontSize: "12px", }}
            fullWidth
            variant="standard"
            type="text"
            label="សាខាផ្ញើទំនិញ"
            multiline
            readOnly={true}
            maxRows={2}
            defaultValue="N/A"
            value={senderBranch.length > 0 ? senderBranch : "N/A"}
            InputProps={{
                readOnly: true,
                disableUnderline: true
            }}
        />

            <TextField
                sx={{ pt: "10px", pb: "20px", mt: "10px" }}
                fullWidth
                variant="standard"
                type="text"
                label="សាខាទទួលទំនិញ"
                multiline
                readOnly={true}
                maxRows={2}
                defaultValue="N/A"
                value={recipientBranch.length > 0 ? recipientBranch : "N/A"}
                // value={ }
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            /></Box>);
    }

    const PaymentStatus = () => {
        if (parcel.cash_on_delievery === 1) {
            return <Box display="flex"><Typography
                variant="h2"
                fontWeight="bold"
                textDecoration="underline"
                pl={3}
                pt={1}
                m={0}
                sx={{
                    color: colors.redAccent[900],
                    textDecoration: "underline"
                }}
            > មិនទាន់បង់ប្រាក់:
            </Typography>
                <Typography variant="h2"
                    fontWeight="bold"
                    textDecoration="underline"
                    pl={3}
                    pt={1}
                    m={0}
                    sx={{
                        color: colors.redAccent[900],
                        // textDecoration: "underline"
                    }}>
                    ៛ {formatCurrency(parcel.total_fee)}
                </Typography>
            </Box>

        } else {
            return <Box display="flex"><Typography
                variant="h2"
                fontWeight="bold"
                textDecoration="underline"
                pl={3}
                pt={1}
                m={0}
                sx={{
                    color: colors.greenAccent[400],
                    textDecoration: "underline"
                }}
            > បង់ប្រាក់រួចរាល់:
            </Typography>
                <Typography variant="h2"
                    fontWeight="bold"
                    textDecoration="underline"
                    pl={3}
                    pt={1}
                    m={0}
                    sx={{
                        color: colors.greenAccent[400],
                        // textDecoration: "underline"
                    }}>
                    ៛ {formatCurrency(parcel.total_fee)}
                </Typography>
            </Box>
        }

    }

    return (
        <Box sx={{
            "& .MuiInputLabel-root": {
                fontSize: "20px !important",
                fontWeight: "bold",
            },

            "& .MuiInput-root": {
                fontSize: "18px !important",
                fontWeight: "bold",
                height: "40px"
            }
        }}>
            <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                <Typography
                    variant="h2"
                    fontWeight="bold"
                    fontFamily={["Noto Sans Khmer", "sans-serif"].join(",")}
                    sx={{ color: colors.grey[100] }}
                >
                    ពត៌មានបញ្ញើលំអិត
                </Typography>
            </Box>
            <Divider />
            <Box display="grid" gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                border={8}
                borderRadius={2}
                borderRight={0}
                borderBottom={0}
                borderTop={0}
                borderColor={colors.blueAccent[900]}
                backgroundColor={colors.primary[400]}
                mt={2}
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                }}>
                <Box m="20px 0px 0px 0px" p="20px 0px 20px 0px"
                    sx={{
                        gridColumn: "span 1",
                    }}>
                    <Box display="block" >
                        <Box>
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                pl={3}
                                color={colors.blueAccent[200]}
                            >
                                លេខតាមដានទំនិញ:
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h3"
                                fontWeight="bold"
                                pl={3}
                                pt={1}
                                m={0}
                                sx={{
                                    color: colors.grey[100],
                                }}
                            >
                                {parcel.reference_number}
                            </Typography>

                        </Box>

                    </Box>
                </Box>

                <Box textAlign="end" alignSelf="center">
                    <PaymentStatus />
                </Box>
            </Box>

            <Box display="grid"
                gap="20px"
                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                sx={{
                    mt: 1,
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
            >
                <Box display="grid"
                    mt={2}
                    gap="20px"
                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
                    }}
                >
                    {/* Sender Info  */}
                    <Box
                        borderRadius={2}
                        backgroundColor={colors.primary[400]}
                        border={8}
                        borderTop={0}
                        borderRight={0}
                        borderBottom={0}
                        borderColor={colors.greenAccent[600]}
                        sx={{
                            boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                            p: "0px 0px 20px 0px",
                            gridColumn: "span 2",

                        }} >
                        <Typography variant='h4' fontWeight="bold"
                            pl={2}
                            sx={{
                                m: "20px 20px 10px 20px",
                                pl: 2,
                                color: colors.greenAccent[900],
                                textDecoration: `underline solid ${colors.greenAccent[900]}`
                            }}
                        >
                            ពត៌មានអ្នកផ្ញើ
                        </Typography>

                        <Box
                            pl={2}
                            sx={{ m: "10px 0px 0px 20px " }}>
                            <TextField
                                fullWidth
                                type="text"
                                label="ឈ្មោះ"
                                value={parcel.sender_name.length > 0 ? parcel.sender_name : "N/A"}
                                defaultValue="N/A"
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                sx={{
                                    m: "16px 0px 0px 0px",
                                }}

                            />

                            <TextField
                                fullWidth
                                type="text"
                                label="អាសយដ្ឋាន"
                                defaultValue="N/A"
                                value={parcel.sender_address.length > 0 ? parcel.sender_address : "N/A"}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                sx={{
                                    m: "16px 0px 0px 0px",
                                }}
                            />

                            <TextField
                                fullWidth
                                type="text"
                                label="លេខទំនាក់ទំនង"
                                variant="standard"
                                value={parcel.sender_contact}
                                defaultValue="N/A"
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                sx={{
                                    m: "16px 0px 0px 0px",
                                }}
                            />
                        </Box>
                    </Box>

                    {/* Receiver Info */}
                    <Box
                        backgroundColor={colors.primary[400]}
                        border={8}
                        borderTop={0}
                        borderRight={0}
                        borderBottom={0}
                        borderColor={colors.redAccent[700]}
                        borderRadius={2}
                        sx={{
                            gridColumn: "span 2", boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",

                        }}>
                        <Typography
                            pl={2}
                            color={colors.grey[100]}
                            variant='h4'
                            fontWeight="bold" sx={{
                                m: "20px 20px 10px 20px",
                                color: colors.redAccent[900],
                                textDecoration: `underline solid ${colors.redAccent[900]}`
                            }}
                        >
                            ពត៌មានអ្នកទទួល
                        </Typography>
                        <Box
                            pl={2}
                            sx={{ m: "10px 20px 0px 20px " }}>
                            <TextField
                                fullWidth
                                type="text"
                                label="ឈ្មោះ"
                                value={parcel.recipient_name.length > 0 ? parcel.recipient_name : "N/A"}
                                defaultValue="N/A"
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                sx={{
                                    m: "16px 0px 0px 0px",
                                }}
                            />

                            <TextField
                                fullWidth
                                type="text"
                                label="អាសយដ្ឋាន"
                                variant="standard"
                                value={parcel.recipient_address.length > 0 ? parcel.recipient_address : "N/A"}
                                defaultValue="N/A"
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                sx={{
                                    m: "16px 0px 0px 0px",
                                }}
                            />

                            <TextField
                                fullWidth
                                type="text"
                                label="លេខទំនាក់ទំនង"
                                variant="standard"
                                value={parcel.recipient_contact}
                                defaultValue="N/A"
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                sx={{
                                    m: "16px 0px 0px 0px",
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Delivery Info */}
                <Box display="grid"
                    gap="20px"
                    gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                    borderRadius={2}
                    backgroundColor={colors.primary[400]}
                    border={8}
                    borderTop={0}
                    borderRight={0}
                    borderBottom={0}
                    borderColor={colors.blueAccent[700]}
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
                        mt: 2,
                        p: "0px 20px 30px 20px",
                        boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                    }} >

                    <Typography variant='h4' fontWeight="bold"
                        pl={2}
                        sx={{
                            m: "20px 20px 0px 0px",
                            gridColumn: "span 2",
                            color: colors.blueAccent[400],
                            textDecoration: `underline solid ${colors.blueAccent[400]}`

                        }}
                        color={colors.grey[100]}>
                        ពត៌មានកញ្ចប់បញ្ញើ
                    </Typography>

                    <Box
                        pl={2}
                        display="grid"
                        gap="10px"
                        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                        sx={{
                            m: "10px 0px 0px 0px",
                            gridColumn: "span 4",
                        }}>

                        <TextField
                            fullWidth
                            type="text"
                            label="ទំងន់ (kg)"
                            defaultValue={parcel.weight}
                            variant="standard"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="បណ្ដោយ (cm)"
                            defaultValue={parcel.length}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                        />

                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="ទទឹង (cm)"
                            defaultValue={parcel.width}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}

                        />


                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="កំពស់ (cm)"
                            defaultValue={parcel.height}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                        />

                        <Box sx={{ gridColumn: "span 2", pb: "10px" }} >
                            <Divider />

                        </Box>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="តំលៃដឹកជញ្ជូនទៅផ្ទះ (៛)"
                            defaultValue="N/A"
                            value={formatCurrency(parcel.home_delievery_fee)}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                disableRipple: true,
                                onFocus: false,
                            }}
                        />

                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="តំលៃបន្ថែមកេស (៛)"
                            defaultValue="N/A"
                            value={formatCurrency(parcel.box_fee)}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                        />

                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="តំលៃធានារ៉ាប់រង (៛)"
                            defaultValue="N/A"
                            value={formatCurrency(parcel.insurance_fee)}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                        />

                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="តំលៃបញ្ញើ (៛)"
                            value={formatCurrency(parcel.delievery_fee)}
                            defaultValue="N/A"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                        />

                    </Box>
                    <Box sx={{ gridColumn: "span 4" }} >
                        {/* <Divider></Divider> */}
                        <Divider sx={{ pl: "20px" }}> <Chip label="ទីតាំងបញ្ញើ" sx={{ fontSize: "14px" }} />   </Divider>
                    </Box>

                    <Box m="10px 10px 0px 0px" sx={{ gridColumn: "span 4", pl: 2 }} >
                        <BranchAddressField />
                        <StatusButton />
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}

export default ModalDetailParcel;