import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

window.domain = "https://system.sontepheabexpress.com";
// // window.domain = "https://sys2.sontepheabexpress.com";
// window.domain = "http://localhost:8080";
// window.domain = "http://192.168.2.39:8080";
// window.domain = "http://192.168.2.29:8080";
// window.domain = "http://192.168.2.136:8080";
window.debug = false;

// window.domain = "http:/192.168.1.228:8080";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
// 
root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
