import { ResponsiveBar } from '@nivo/bar';
import { colorList } from '../helper/Helper';


const TransactionByBranchChart = ({ chartData, keys }) => {

    return (
        <ResponsiveBar
            data={chartData}
            keys={keys}
            // keys={['vzTL0PqMogyOWhF', 'KyIab3mYBgAX71t', 'dIbUK5mEh96f0Zc', 'adfasfsdfsdf', 'adsfaadfasdf', 'test', 'asdfasdfasd', 'asfasdfsdaf']}
            indexBy="date_created"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            colors={colorList()} //{{ scheme: 'category10' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 50,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}

            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[{
                dataFrom: 'keys', anchor: 'bottom-right', direction: 'column', justify: false, translateX: 120, translateY: 0, itemsSpacing: 2, itemWidth: 100, itemHeight: 10, itemDirection: 'left-to-right', itemOpacity: 0.85, symbolSize: 10, effects: [{ on: 'hover', style: { itemOpacity: 1 } }]
            }
            ]}
        />
    );
};




// const TransactionByBranchChart = ({ isDashboard = false, chartData }) => {
//     return (
//         <ResponsiveBar
//             data={chartData}
//             // keys={"dIbUK5mEh96f0Zc", "KyIab3mYBgAX71t", "adfasfsdfsdf"}
//             indexBy="date_created"
//             margin={{ top: 50, right: 130, bottom: 50, left: 60 }
//             }
//             axisBottom={{
//                 tickSize: 5,
//                 tickPadding: 5,
//                 tickRotation: 0,
//                 legend: 'Branch',
//                 legendPosition: 'middle',
//                 legendOffset: 32,
//             }}
//             axisLeft={{
//                 tickSize: 5,
//                 tickPadding: 5,
//                 tickRotation: 0,
//                 legend: 'Total Transactions',
//                 legendPosition: 'middle',
//                 legendOffset: -40,
//             }}
//         />
//     );
// }

export default TransactionByBranchChart
